import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { images } from "./../../constants";
import { SetPasswordSchema } from "../../utils/ValidationSchemas/SetPasswordSchema";
import authService from "./../../services/auth.service";
import Spinner from "../ui-components/loader-components/Spinner";
import AuthAlertComponent from "./AuthAlertComponent";
import InputField from "../ui-components/form-component/InputFieldComponent";
import { useSelector } from "react-redux";

const SetPassword = ({ isClientPortal, handleSetPassword }) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { inputFieldStyle,  } = useOutletContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState('');
  const [alertmessageStatus, setalertmessageStatus] = useState("")

  const { access_token } = useSelector(state => state.user.userInfo);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
    getFieldState,
    getValues,
  } = useForm({
    resolver: yupResolver(SetPasswordSchema),
    mode: "onChange",
    defaultValues: { isOldPassword: isClientPortal },
  });

  // let state = getFieldState('password');
  let values = getValues();


  const minMaxLength = () => {
    return /^.{6,20}$/.test(values.password) && values.password ? true : false;
    // return errors?.password?.message === 'must be at least 6 characters' ? true : false;
  };

  const oneUppercase = () => {
    return /[A-Z]/.test(values.password) ? true : false;
  };

  const oneLowercase = () => {
    return /[a-z]/.test(values.password) ? true : false;
  };

  const oneNumber = () => {
    // return errors?.password?.message !== 'must contain one number' ? false : true;
    return /(\d)/.test(values.password) ? true : false;
  };

  const oneSpecialCharacter = () => {
    return /(\W)/.test(values.password) ? true : false;
  };

  const [searchParams] = useSearchParams();
  let params = Object.fromEntries([...searchParams]);

  const validationMessages = (icon, message, isValid) => {
    return (
      <div className="flex items-center justify-start h-5 mb-2">
        <img src={icon} alt="pass_length" />
        <p
          className={`${
            isValid ? "text-[#B0E577] text-opacity-90" : "text-grey-100"
          } text-xs ml-2`}
        >
          {message}
        </p>
      </div>
    );
  };

  const submitForm = (data) => {
   
    if (isClientPortal) {
      authService.changeClientPassword({ data }, access_token).then((response) => {
          if (response.data.statusCodeValue === 200) {
              setLoading(false);
              reset();
              setAlertMessage(
                response?.data?.payload?.message ??  'Password Updated Successfully! Use on next login!'
              );
              setalertmessageStatus('success')
              // console.log("Password Updated Successfully! Use on next login!");
          }
      }).catch((err) => {
       
          setLoading(false);
          
          setAlertMessage(err?.response?.data?.message ?? "something went wrong");
      })
  } else {
      params = { ...params, password: data.password };
      // console.log({ params });
      setLoading(true);
      authService.setClientPassword({ params }).then((response) => {
          if (response.data.statusCodeValue === 200) {
              setLoading(false);
              navigate("/login");
              // console.log("Password Set Successfully");
          }
      }).catch((err) => {
          setLoading(false);
          // console.log(err);
          setAlertMessage(err?.response?.data?.message ?? "something went wrong");
      })

  }
  };

  return (
    <div className={`md:w-6/12 ${isClientPortal ? "lg:w-5/12" : "lg:w-4/12"}`}>
      {!isClientPortal && (
        <>
          <h1 className="mb-2 text-2xl font-bold text-white">
            Set Password
          </h1>
          <p className="mb-10 text-md text-grey-100">
          Enter new password to initiate the KYB process
          </p>
        </>
      )}
      <form
        className="w-full text-white lg:w-10/12"
        onSubmit={handleSubmit(submitForm)}
      >
        <AuthAlertComponent alertmessageStatus={alertmessageStatus} alert={alertMessage} setAlert={setAlertMessage} />
        {/*------------- Hidden checkbox for old password ------------------*/}
        <div className="hidden form-group form-check">
          <input
            type="checkbox"
            className="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-transparent bg-center bg-no-repeat bg-contain border-2 border-gray-300 rounded-[4px] appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:shadow-none focus:outline-none"
            id="isOldPassword"
            {...register("isOldPassword")}
          />
          <label
            className="inline-block text-zinc-400 form-check-label"
            htmlFor="exampleCheck2"
          >
            Old Password
          </label>
        </div>
        {/* Old Password input  */}
        {isClientPortal && (
          <div className="relative z-0 mt-10">
            <input
              type={!showOldPassword ? "password" : "text"}
              name="oldPassword"
              className={`${
                errors?.oldPassword?.message && "focus:border-b-red-600 border-b-red-600"
              } peer outline-none border-t-0 border-r-0 border-l-0 border-b border-[#A4B1A7]rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-[#A4B1A7] block w-full p-2.5 bg-transparent`}
              placeholder=" "
              {...register("oldPassword")}
            />
             <label
            className={`text-xs text-grey-100  z-[-1]  absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
          >
            Old Password
          </label>
            <p className="mt-1 text-xs text-red-600">
              {errors?.oldPassword?.message}
            </p>
            <img
              onClick={() => setShowOldPassword(!showOldPassword)}
              className="absolute right-0 cursor-pointer top-3"
              src={
                !showOldPassword ? images?.eye_on_icon : images?.eye_off_icon
              }
              alt="eye_off"
            />
          </div>
        )}
        {/* Password input  */}
        <div className="">
        <div className={`relative z-10 mt-8`}>
          <input
            type={!showPassword ? "password" : "text"}
            className={`${
                errors?.password?.message && "focus:border-b-red-600 border-b-red-600"
              } peer outline-none border-t-0 border-r-0 border-l-0 border-b border-[#A4B1A7]rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-[#A4B1A7] block w-full p-3 bg-transparent`}
              placeholder=" "
            {...register("password")}
          />
           <label
            className={`text-xs text-grey-100  z-[-1]  absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
          >
            Password
          </label>
          <p className="mt-1 text-xs text-red-600">
            {errors?.password?.message}
          </p>
          <img
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-0 cursor-pointer top-3"
            src={!showPassword ? images?.eye_on_icon : images?.eye_off_icon}
            alt="eye_off"
          />
          </div>
        </div>
        {/* Confirm Password input  */}
        <div className="relative z-10 mt-8 mb-3">
          <input
            type={!showConfirmPassword ? "password" : "text"}
            name="confirmPassword"
            id="confirmPassword"
            className={`${
                errors?.confirmPassword?.message && "focus:border-b-red-600 border-b-red-600"
              } peer outline-none border-t-0 border-r-0 border-l-0 border-b border-[#A4B1A7]rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-[#A4B1A7] block w-full p-2.5 bg-transparent`}
            
            placeholder=" "
            {...register("confirmPassword")}
          />
          <label
            className={`text-xs text-grey-100 z-[-1]   absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
          >
            Confirm Password
          </label>
          <p className="mt-1 text-xs text-red-600">
            {errors?.confirmPassword && "Password mismatch"}
          </p>
          <img
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className="absolute right-0 cursor-pointer top-3"
            src={
              !showConfirmPassword ? images.eye_on_icon : images.eye_off_icon
            }
            alt="eye_off"
          />
        </div>

        <div className="flex flex-col mb-8">
          <p className="mb-2 text-xs font-bold text-grey-100">In order to protect your account, make sure your password: </p>
          {validationMessages(
            minMaxLength()
              ? images.passwordLengthSuccess
              : images.passwordLength,
            "is between 6 to 20 characters",
            minMaxLength()
          )}
          {validationMessages(
            oneNumber() ? images.passwordNumberSuccess : images.passwordNumber,
            "has at least one numeric digit",
            oneNumber()
          )}
          {validationMessages(
            oneUppercase() && oneLowercase()
              ? images.passwordUpperLowerSuccess
              : images.passwordUpperLower,
            "consists of one uppercase and one lowercase letter",
            oneUppercase() && oneLowercase()
          )}
          {validationMessages(
            oneSpecialCharacter()
              ? images.passwordSpecialCharSuccess
              : images.passwordSpecialChar,
            "has one special character / non alphanumeric character",
            oneSpecialCharacter()
          )}
        </div>

        {/* Set Password  */}
        <div className="flex items-center">
          {isClientPortal && (
            <button
              onClick={() => reset()}
              type="button"
              className={`btn-secondary mr-2 ${isClientPortal && "w-6/12"}`}
            >
              Cancel
            </button>
          )}
          <button
            // onClick={() => handleSetPassword()}
            type="submit"
            className={`btn-primary ${
              isClientPortal && "w-6/12"
            } disabled:cursor-not-allowed`}
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            disabled={loading || !isDirty || !isValid}
          >
            {loading && <Spinner />}
            {isClientPortal ? "Save Changes" : "Set Password"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SetPassword;
