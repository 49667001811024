import blockchainScriptService from "../../services/blockchain.script";

const BlockchainScriptHook = () => {
  const getBlockChainScriptFromDB =async (dataModal) => {
    const dbScript =await blockchainScriptService.getDdScript(dataModal);
    if (dbScript?.data?.success && dbScript?.data?.payload != null) {
      return dbScript?.data?.payload?.script
    }
  };
  return {
    getBlockChainScriptFromDB,
  };
};

export default BlockchainScriptHook;
