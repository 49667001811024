export const INITIAL_STATE = {
	menuItems: [
		{
			id: 1,
			step: 1,
			label: "Connect Wallet",
			link: "/connect-wallet",
			isActive: false,
		},
		{
			id: 2,
			step: 2,
			label: "Brand Details",
			link: "/brand-details",
			isActive: false,
		},
		{
			id: 3,
			step: 3,
			label: "Brand Categories",
			link: "/brand-categories",
			isActive: false,
		},
		{
			id: 4,
			step: 4,
			label: "Brand Theme",
			link: "/brand-theme",
			isActive: false,
		},
		{
			id: 5,
			step: 5,
			label: "Social Links",
			link: "/social-links",
			isActive: false,
		},
	],
};

export const menuItemsReducer = (state, action) => {
	switch (action.type) {
		case "UPDATE_MENU_ITEM_STATE":
			// console.log("updating", action.payload.id);
			return {
				...state,
				menuItems: state.menuItems.map((item) => {
					
					item.id === action.payload.id
						? (item.isActive = true)
						: (item.isActive = false);
				}),
			};
		// case "UPDATE_BRAND_DETAILS":
		// 	return {
		// 		...state,
		// 	};
		// case "UPDATE_BRAND_CATEGORIES":
		// 	return {
		// 		...state,
		// 	};
		// case "UPDATE_BRAND_THEME":
		// 	return {
		// 		...state,
		// 	};
		// case "UPDATE_BRAND_SOCIALS":
		// 	return {
		// 		...state,
		// 	};
		default:
			return state;
	}
};
