import React from "react";
import { images } from "../../../../constants";
import PreviewCountDownTimer from "./PreviewCountDownTimer";

const PreviewUpcoming = () => {
  return (
    <>
      <h1 className="text-5xl font-bold leading-tight text-center text-white mt-14 max-md:mt-0 max-lg:text-4xl max-md:text-3xl tracking-little mb-9">
        Upcoming <span className="">Drops</span>
      </h1>
      <div className="p-8 rounded-lg max-sm:p-4 lg:p-14">
        <div className="grid items-center grid-cols-1 gap-6 md:grid-cols-2">
          <div className="">
            <div className="order-2 max-w-lg lg:order-1">
              <div
                className={` bg-white px-2 py-1 leading-4 text-black font-semibold capitalize rounded-3xl mb-2 max-w-fit`}
              >
                Edition: 1/1
              </div>
              <h1 className="mb-2 text-4xl font-bold text-white max-lg:text-xl max-xl:text-2xl">
                Digital Art Of Palace
              </h1>
              <p className="mb-4 text-grey-100">
                Lorem ipsum dolor sit amet consectetur. Faucibus tellus quam
                luctus congue porta netus. Purus lobortis volutpat ullamcorper
                eget eleifend massa pellentesque mi.
              </p>
              <ul className="mb-4">
                <li className="inline-block py-1 pr-4 align-middle">
                  <h1 className="text-4xl font-bold text-white max-xl:text-2xl max-lg:text-xl">
                    $1289
                  </h1>
                </li>
                <li className="inline-block py-1 pl-4 align-middle border-l border-solid border-white/20">
                  <div className={`text-grey-100flex items-center ml-auto`}>
                    <img
                      src={images.FlowFcIcon}
                      className="mr-1"
                      alt="flow-icon"
                    />
                    23.232324
                  </div>
                </li>
              </ul>
              <div className="mb-6">
              <PreviewCountDownTimer />
              </div>
             
              {/* <div className="bg-[#A5F33C] rounded-3xl  p-[1.5px] inline-block  mb-10 ">
              <div className="inline-block bg-[#000000d1] px-4 py-2 text-2xl font-normal text-white rounded-3xl">
              16D : 16H : 28M : 30S
              </div>
              </div> */}
              <div>
                <button className="py-3 text-xl font-medium leading-snug text-[#12221A] capitalize transition duration-150 ease-in-out bg-[#A5F33C] rounded-full shadow-md cursor-pointer max-md:text-sm max-xl:text-lg max-lg:text-base px-7 hover:bg-[#A5F33C] hover:shadow-lg focus:bg-[#A5F33C] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#A5F33C] active:shadow-lg">
                  View Drop Details
                </button>
              </div>
            </div>
          </div>

          <div className="relative">
            <div className="absolute top-0 -left-[24px] w-[1px] h-full bg-white/30"></div>

            <img
              src={images.PreviewUpcomingDropIcon}
              alt="img"
              className="max-h-[370px] w-full bg-center bg-no-repeat bg-cover rounded-lg"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewUpcoming;
