import React from "react";
import { Link } from 'react-router-dom';

const Tab = (props) => {
  const { openTab, setOpenTab, value, children } = props;
  return (
    <>
      
        <div
         
          onClick={() => setOpenTab(value)}
          className={` mr-4 transition-all hover:border-[#A5F33C] hover:bg-[#A5F33C] hover:text-[#12221A] capitalize text-center px-8 py-2 inline-block  border border-b-0 rounded-t-lg   cursor-pointer   text-base font-bold text-white  border-solid   ${
            openTab === value ? "bg-[#A5F33C]/10 border-[#A5F33C] text-white " : "border-[#A4B1A7]/25 "
          } `}
        >
          {children}
        </div>
     
    </>
  );
};

export default Tab;
