import React, { useState, useEffect } from 'react'
import { images } from '../../constants'
import KybThankyouModal from './../Modals/KybThankyouModal';
import Modal from './../../common/ModalLayout';
import Spinner from './../ui-components/loader-components/Spinner';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { BrandSocialSchema } from './../../utils/ValidationSchemas/BrandSocialSchema';
import clientService from './../../services/client.service';
import { update_menu_items_state, reset_menuItems } from '../../redux/features/menu/menuItemsSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {  clientDetailAction } from '../../redux/features/user/userSlice';

const KybBrandSocials = () => {

    const [loading, setLoading] = useState(false);
    const [showThankyouModal, setShowThankyouModal] = useState(false);
    const [stepsCompleted, setStepsCompleted] = useState(false);
    const [isClientApproved, setIsClientApproved] = useState(false);

    const handleCloseThankyouModal = () => {
        setShowThankyouModal(false);
    };

    const user = useSelector(state => state.user);

    useEffect(() => {
        if (user.clientDetail.stepsCompleted === true && !user.clientDetail.isClientApproved) {
            setShowThankyouModal(true);
            setStepsCompleted(true);
        }
    }, [])

    useEffect(() => {
        // console.log(user.clientDetail);
        let clientDetail = user.clientDetail;
        // console.log({ clientDetail });
        if (clientDetail.instagramUrl || clientDetail.linkedInUrl || clientDetail.twitterUrl || clientDetail.discordUrl || clientDetail.facebookUrl) {
            setValue('instagram', clientDetail.instagramUrl);
            setValue('linkedin', clientDetail.linkedInUrl);
            setValue('discord', clientDetail.discordUrl);
            setValue('twitter', clientDetail.twitterUrl);
            setValue('youtube', clientDetail.youtubeUrl);
            setValue('tiktok', clientDetail.facebookUrl);
        }
    }, [])


    const dispatch = useDispatch();

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(BrandSocialSchema),
        mode: "onChange",
    });

    const socialMedias = [
        { name: 'instagram', icon: images.instagram_icon },
        { name: 'linkedin', icon: images.linkedin_icon },
        { name: 'discord', icon: images.discord_icon },
        { name: 'twitter', icon: images.twitter_icon }
    ]



    const submitForm = (data) => {
        setLoading(true);
        let formData = new FormData();
        formData.append('ClientUuid', user.clientUuid);
        formData.append('InstagramUrl', data.instagram || '');
        formData.append('LinkedInUrl', data.linkedin || '');
        formData.append('DiscordUrl', data.discord || '');
        formData.append('TwitterUrl', data.twitter || '');
        formData.append('YoutubeUrl', data.youtube || '');
        formData.append('FacebookUrl', data.tiktok || '');
         
        formData.append('stepsEnum', 5);

        // console.log([...formData.entries()]);
        clientService.updateClient(formData).then((response) => {
            if (response.data.statusCodeValue === 200) {
                // console.log("Social links updated successfully!");
                if (response.data.payload.isClientApproved) {
                    setIsClientApproved(true);
                    setStepsCompleted(false);
                    setShowThankyouModal(true);
                    dispatch(clientDetailAction(response.data.payload));
                } else {
                    setStepsCompleted(true);
                    setLoading(false);
                    setShowThankyouModal(true);
                    dispatch(reset_menuItems(6));
                }
            }
        }).catch((err) => {
            // console.log(err);
            setLoading(false);
        })
    }

    return (
        <>
            <h1 className="text-2xl font-bold">Social Media Links</h1>
            <p className="mt-1 mb-6 text-xs text-grey-100 max-w-[448px]">You can add your social media links now or later in the theme section. These links will be represented on your storefront footer section.  </p>

            <form className="w-6/12 text-white" onSubmit={handleSubmit(submitForm)}>

                {/* Instagram */}
                <div className='mb-8'>
                    {/* {socialMedias.map(({ name, icon }, index) => (
                        <div key={index} className="flex items-center max-w-md px-4 py-3 mb-5 border rounded-full bg-none border-[#A4B1A7]/50">
                            <img className="mr-2" src={icon} alt={name} />
                            <input
                                className="w-full p-0 bg-transparent border-none focus:ring-transparent"
                                placeholder="https://"
                                type="text"
                                {...register(name)}
                            />
                            <p className='mt-1 text-xs text-red-700'>{errors?.name?.message}</p>
                        </div>
                    ))} */}

                    <div className="flex items-center max-w-md px-4 py-3 mb-1 border rounded-full bg-none border-[#A4B1A7]">
                        <img className="mr-2" src={images.instagram_icon} alt='instagram' />
                        <input
                            className='w-full p-0 bg-transparent border-none placeholder:text-white focus:ring-transparent'
                            type="text"
                            name="instagram"
                            placeholder="https://"
                            {...register('instagram')}
                        />
                    </div>
                    <p className='mt-1 text-xs text-red-700'>{errors?.instagram?.message}</p>
                </div>

                {/* LinkedIn */}
                <div className='mb-8'>
                    <div className="flex items-center max-w-md px-4 py-3 mb-1 border rounded-full bg-none border-[#A4B1A7]">
                        <img className="mr-2" src={images.linkedin_icon} alt='instagram' />
                        <input
                            className='w-full p-0 bg-transparent border-none placeholder:text-white focus:ring-transparent'
                            type="text"
                            name="linkedin"
                            placeholder="https://"
                            {...register('linkedin')}
                        />
                    </div>
                    <p className='mt-1 text-xs text-red-700'>{errors?.linkedin?.message}</p>
                </div>

                {/* Discord */}
                <div className='mb-8'>
                    <div className="flex items-center max-w-md px-4 py-3 mb-1 border rounded-full bg-none border-[#A4B1A7]">
                        <img className="mr-2" src={images.discord_icon} alt='instagram' />
                        <input
                            className='w-full p-0 bg-transparent border-none placeholder:text-white focus:ring-transparent'
                            type="text"
                            name="discord"
                            placeholder="https://"
                            {...register('discord')}
                        />
                    </div>
                    <p className='mt-1 text-xs text-red-700'>{errors?.discord?.message}</p>
                </div>

                {/* Twitter */}
                <div className='mb-8'>
                    <div className="flex items-center max-w-md px-4 py-3 mb-1 border rounded-full bg-none border-[#A4B1A7]">
                        <img className="mr-2" src={images.twitter_icon} alt='instagram' />
                        <input
                            className='w-full p-0 bg-transparent border-none placeholder:text-white focus:ring-transparent'
                            type="text"
                            name="twitter"
                            placeholder="https://"
                            {...register('twitter')}
                        />
                    </div>
                    <p className='mt-1 text-xs text-red-700'>{errors?.twitter?.message}</p>
                </div>
                {/* Youtube */}
                <div className='mb-8'>
                    <div className="flex items-center max-w-md px-4 py-3 mb-1 border rounded-full bg-none border-[#A4B1A7]">
                        <img className="mr-2" src={images.SocialMediaYouTubeIcon} alt='instagram' />
                        <input
                            className='w-full p-0 bg-transparent border-none placeholder:text-white focus:ring-transparent'
                            type="text"
                            name="youtube"
                            placeholder="https://"
                            {...register('youtube')}
                        />
                    </div>
                    <p className='mt-1 text-xs text-red-700'>{errors?.youtube?.message}</p>
                </div>
                {/* Tiktok */}
                <div className='mb-10'>
                    <div className="flex items-center max-w-md px-4 py-3 mb-1 border rounded-full bg-none border-[#A4B1A7]">
                        <img className="mr-2" src={images.SocialMediaTiktokIcon} alt='instagram' />
                        <input
                            className='w-full p-0 bg-transparent border-none placeholder:text-white focus:ring-transparent'
                            type="text"
                            name="tiktok"
                            placeholder="https://"
                            {...register('tiktok')}
                        />
                    </div>
                    <p className='mt-1 text-xs text-red-700'>{errors?.tiktok?.message}</p>
                </div>


                {/* Submit BUTTON */}
                <button type='submit' disabled={loading}
                    className='max-w-md mb-16 text-base btn-primary disabled:opacity-40 disabled:pointer-events-none'>
                    {loading && <Spinner />} Submit
                </button>
            </form>

            <Modal
                modalVisible={showThankyouModal}
                closeModal={handleCloseThankyouModal}
                children={<KybThankyouModal closeModal={handleCloseThankyouModal} stepsCompleted={stepsCompleted} isClientApproved={isClientApproved} />}
                modal_Id="Thankyou_Modal"
                isClose={false}
            />
        </>
    )
}

export default KybBrandSocials