import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setSubMenuSelected } from "../../../redux/features/sidebar-menu/sidebarMenuSlice";

const SubMenuItem = (props) => {
  const dispatch = useDispatch()
  const { submenuSelected } = useSelector(state => state.sidebar);
  const { submenuItems  } = props;

  return (
    <>
      {submenuItems.map((submenuItem, index) => (
         
            <li onClick={() => {
              dispatch(setSubMenuSelected(submenuItem.value))
           
          }} className={`  ${submenuSelected === submenuItem.value ? "bg-[#A5F33C]/10 menu-selected-before menu-selected-after" : ""}  p-[1.5px] text-left text-sm mt-2 w-5/5  rounded-r-lg   font-bold hover:bg-[#A5F33C]/10   relative flex flex-row items-center h-12  text-white border-transparent cursor-pointer  hover:before:top-0 hover:before:left-0 hover:before:absolute hover:before:h-full hover:before:w-1 hover:before:bg-[#A5F33C] hover:before:rounded-r   	 `}>
              <Link to={submenuItem?.url}  className="flex items-center flex-grow py-3 pl-5 pr-3 cursor-pointer ">
              <span className="z-10 ">
                <img src={ submenuSelected === submenuItem.value ? submenuItem.icon :submenuItem.inactiveIcon} alt="bloomly" />
              </span>
              <span className={`z-10 ml-4 text-sm tracking-wide truncate ${submenuSelected === submenuItem.value?  'text-white font-bold' :'text-[#8D9D91] font-normal'}`}>
                {submenuItem.title}
              </span>
              </Link>
            </li>
         
      ))}
    </>
  );
};

export default SubMenuItem;
