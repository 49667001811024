export const customStyles = {
    container: (provided ,state) => ({
      ...provided,
      borderBottom: state?.isFocused ? "1px solid white" :  "1px solid #A4B1A7",
     
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
      paddingTop:'6px',
      paddingBottom:'6px',
      paddingRight:'6px',
      paddingLeft:'0px'
     
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#060C07",
      color: "white",
      WebkitScrollbar: { width: "5px", backgroundColor: "red" },
      WebkitScrollbarTrack: {
        borderRadius: "3px",
      },
      WebkitScrollbarThumb: {
        background: "grey",
        borderRadius: "3px",
      },
      
    }),
    menuList: (base) => ({
      ...base,
      paddingBottom:"24px",
      
      
      "::-webkit-scrollbar": {
        width: "24px",
        height: "0px",
        
        
      },
      "::-webkit-scrollbar-track": {
        background: "#222222",
       
      },
      "::-webkit-scrollbar-thumb": {
        background: "black",
        minHeight:"60px",
        borderRight :"8px solid #222222",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "black",
      },
    }),
    option: (provided,state ) => ({
      ...provided,
      backgroundColor:state?.isFocused ? "#12221a4d" : "#060C07",
      color: "white",
      
      ":hover": {
        backgroundColor: "#12221a4d",
        color: "white",
        cursor:"pointer",
      },
      
    }),
    input: (provided, state) => ({
      ...provided,
      color: "white",
      boxShadow:'none !important',
      boxShadow:state?.isFocused ? 'none' :'none',
      '& input':{
        boxShadow:'none !important',
      }
    }),

    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "white",
      
    }),
    indicatorSeparator:(provided) => ({
        ...provided,
        backgroundColor: "transparent",
        
        
      }),
      valueContainer:(provided) => ({
        ...provided,
        paddingTop:'2px',
      paddingBottom:'2px',
      paddingRight:'8px',
      paddingLeft:'0px'
        
        
      }),
   
  };