import { getRequest, postRequest } from "../utils/api/axiosMethods";

const createClientStripeAccount=(modal)=>{
    return postRequest(`ClientStripeInfo/create_client_stripe_account`,modal).then((response) => {
		return response;
	});
}

const getStripeInfo=(clientUuid)=>{
    return getRequest(`ClientStripeInfo/get_stripe_info?clientUuid=${clientUuid}`).then((response) => {
		return response;
	});
}

const updateClientStripeAccount=(ClientId,Accountid)=>{
    const modal= {
        ClientId: ClientId,
        Accountid:Accountid,
      }
    return postRequest(`ClientStripeInfo/update_client_stripe_account`,modal).then((response) => {
		return response;
	});
}

const getClientStripeAccountInfo=(clientUuid)=>{
    return getRequest(`ClientStripeInfo/get_client_stripe_account?clientUuid=${clientUuid}`).then((response) => {
		return response;
	});
}

const getClientStripeAccountUrl=(clientUuid)=>{
   
    return getRequest(`ClientStripeInfo/get_client_stripe_account_url?clientUuid=${clientUuid}`).then((response) => {
		return response;
	});
}


const clientStripeService = {
    createClientStripeAccount,
    getStripeInfo,
    updateClientStripeAccount,
    getClientStripeAccountInfo,
    getClientStripeAccountUrl
  }

  export default clientStripeService;