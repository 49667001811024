import React from "react";
import { images } from "../../constants";

const AlertModalContent = (props) => {
  const { message ,btnHandler } = props;
  return (
    <>
    <div className="flex items-center">
    <img
        className="mr-2"
        src={
          message?.title === "Success"
            ? images.AlertSuccessIcon
            : images.AlertErrorIcon
        }
        alt="icon"
      />
      <h3
        className={` text-2xl font-bold ${
          message?.title === "Success" ? "text-[#A5F33C]" : "text-red"
        }`}
      >
        {message.mainTitle ?? message?.title }
        {}
      </h3>


    </div>
     
      <p className={`w-full py-2 pl-3 mb-10 text-white bg-transparent `}rows="5">
        {message?.description}
      </p>
      {message?.sublabel && (
        <p className="w-full py-2 pl-3 mb-8 text-sm font-bold text-grey-100 -mt-9">
          {message?.sublabel}
        </p>
      )}
      {message?.alertHander && (
        <button className="btn-primary" onClick={() =>btnHandler()} >{message?.btnTitle}</button>
      )}
    </>
  );
};

export default AlertModalContent;
