import moment from "moment";
import * as yup from "yup";
let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
export const addDropSchema = yup.object().shape({
  dropName: yup
    .string()
    .required("Drop name is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(100, "Max  length 100 characters"),

  dropType: yup.object().shape({
    label: yup.string().required("Drop Type is required "),
    value: yup.string().required("Drop Type is required"),
  }),

  dropDescription: yup
    .string()
    .required("Drop description is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(500, "Max  length 500 characters"),

  dropPrice: yup.string().when("dropType.label", {
    is: "Air Drop",
    then: yup.string().notRequired(), // change validation schema for fieldB
    otherwise: yup
      .string()
      .max(8, "Drop price can not be greater than $99 million")
      .required(" Drop price is required ")
      .matches(/^\d+(\.\d{0,2})?$/, "Must be a number with two decimal places")

      .test(
        "Drop price must be greater than 0",
        "Drop price must be greater than 0",
        (value, context) => {
          return (
            context.originalValue && !context.originalValue.startsWith("0")
          );
        }
      ), // default validation for fieldB
  }),
  // dropPrice: yup
  // .string()
  // .required(" Drop price is required ")
  // .matches(/^\d+(\.\d{0,2})?$/, "Must be a number with two decimal places")

  // .test(
  //   'Drop price must be greater than 0',
  //   'Drop price must be greater than 0',
  //   (value, context) => {
  //     return context.originalValue && !context.originalValue.startsWith('0');
  //   }),

  PublicSaleStartDate: yup
    .date()
    .transform((value) => {
      return value ? moment(value).toDate() : value;
    })
    .min(
      new Date(),
      "Public sale start date/time should be greater than current date/time"
    )
    .required("Public sale start date required")
    .nullable(),

  PublicSaleEndDate: yup
    .date()
    .min(
      yup.ref("PublicSaleStartDate"),
      "Public sale end date can't be before start date"
    )
    .required("Public sale end date is required")
    .nullable()
    .when("PublicSaleStartDate", (PublicSaleStartDate, addDropSchema) => {
      if (PublicSaleStartDate) {
        const dayAfter = new Date(PublicSaleStartDate.getTime() + 1);

        return addDropSchema.min(
          dayAfter,
          "public sale end date has to be after public sale start date"
        );
      }

      return addDropSchema;
    }),

  addAirDropFile: yup.boolean(),
  whitelistName: yup.string().when("addAirDropFile", {
    is: true,
    then: yup
      .string()
      .required("Whitelist name is required")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
    otherwise: yup.string(),
  }),

  airdropFile: yup.mixed().when("addAirDropFile", {
    is: true,
    then: yup
      .mixed()
      .test("required", "File is required", (file) => {
        if (file[0]) return true;
        return false;
      })
      .test("fileSize", "File size is too large", (value) => {
        if (!value) return true;
        return value[0]?.size <= 5242880;
      }),

    otherwise: yup.mixed(),
  }),
});
