import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KeyValueView from "../../../../components/ui-components/views-component/KeyValueView";
import useUSDToFlow from "../../../../helpers/custom-hooks/useUSDToFlow";
import DateFormat from "../../../../helpers/DateFormat";
import ClientHeaderComponent from "../../components/ClientHeaderComponent";
import { images } from "./../../../../constants/images";

const ClientTransactionDetail = () => {
  const navigate = useNavigate();
  const flowPrice = useUSDToFlow();
  const { state } = useLocation();
  const { data } = state || {};

  return (
    <>
      <ClientHeaderComponent title={"Transaction Detail"} />
      <div
        className="flex items-center mb-6 cursor-pointer group "
        onClick={() => navigate("/tx-reporting")}
      >
        <img
          className="inline w-4 h-4 mr-1 transition-all ease-in-out delay-150 group-hover:-translate-x-1"
          src={images.BackIcon}
          alt="icon"
        />
        <p className="text-xs font-semibold text-white transition-all ease-in-out delay-150 group-hover:translate-x-1">
          Back
        </p>
      </div>
      {data === undefined || data === null ? (
        <>
          <h4 className="mt-10 text-2xl text-center">Record not found</h4>
        </>
      ) : (
        <div className="container mx-auto relative z-20 grid grid-cols-2 min-h-[calc(100vh-200px)]  ">
          <div className="columns-md ">
            <div className=" w-full max-w-[500px] p-3 max-h-[500px] bg-[#060C07]  rounded-2xl border border-[#A4B1A7]/25 ">
              {data?.assetVariationObject?.arweaveAssetType === "Image" && (
                <img
                  className="w-full rounded max-h-[475px] object-contain"
                  onLoad={(e) => {
                    e.currentTarget.attributes.src.value =
                      data?.assetVariationObject?.originalImage;
                  }}
                  src={images.DefaultImageIcon}
                  alt="icon"
                />
              )}
              {data?.assetVariationObject?.arweaveAssetType === "Video" && (
                <video
                  controls
                  className="w-full rounded xl:min-h-[475px] max-h-[475px] object-contain"
                  src={data?.assetVariationObject?.videoUri}
                  alt="icon"
                />
              )}
              {data?.assetVariationObject?.arweaveAssetType === "Audio" && (
                <video
                  controls
                  poster={images.AudioIcon}
                  className="rounded w-full max-h-[475px] object-contain"
                  src={data?.assetVariationObject?.audioUri}
                  alt="icon"
                />
              )}
            </div>
          </div>
          <div className="columns-md ">
            <div className="flex mb-8">
              <KeyValueView
                title={"Asset Name"}
                value={data?.assetName ?? "-"}
              />
              <KeyValueView
                title={"Purchaser Name"}
                value={data?.purchaserName ?? "-"}
              />
            </div>
            {/* <div className="mb-8">
                <KeyValueView
                  title={"Order Number"}
                  value={data?.orderId ?? "-"}
                />
              </div> */}
            <div className="mb-6">
              <KeyValueView
                title={"Description"}
                value={data?.description ?? "-"}
              />
            </div>

            {/* <div className="mb-6 flex justify-between items-center text-xs mt-8 flex-grow py-3 px-4 rounded-xl bg-transparent border-2 border-zinc-700 min-w-[80px]">
    <div className="flex items-center">
      <img
        className="object-contain mr-2 w-7 h-7"
        src={images.ReceiptIcon}
        alt="logo"
      />
      <span className="w-full p-0 font-bold bg-transparent border-none focus:ring-transparent">
      Transaction Receipt
      </span>
    </div>
    <span className="text-zinc-400">
      {(222222 / 1024).toFixed(2) + "kb"}
    </span>
    <img
      className="cursor-pointer hover:opacity-80"
      src={images.cross_icon}
      alt="X"
      
    />
  </div> */}

            <div className="flex mb-8">
              <KeyValueView
                title={"Total Price"}
                value={`$ ${data?.totalPriceUsd}`}
              />
              <KeyValueView
                title={"Price In Flow"}
                value={`${
                  flowPrice
                    ? `${(flowPrice * data?.totalPriceUsd)?.toFixed(4)} Flow`
                    : "-"
                } `}
              />
            </div>

            <div className="flex mb-8">
              <KeyValueView
                title={"Payment Source"}
                value={data?.paymentProcessor ?? "-"}
              />
              <KeyValueView
                title={"Transaction Date"}
                value={moment(data?.orderDate).format("MMM D YYYY ") ?? "-"}
              />
            </div>
            <div className="flex mb-8">
              <KeyValueView
                title={"Platform Fee"}
                value={data?.platformFee ? `$${data?.platformFee}` : "-"}
              />
              {/* <KeyValueView
                title={"Transaction Fee"}
                value={data?.TransactionFee ?? "-"}
              /> */}
              <div className="flex mb-8">
                <div className={`min-w-[170px]`}>
                  <p className="text-xs text-grey-100">Transaction Status</p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      process.env.REACT_APP_ACCESS_NODE?.includes("testnet")
                        ? `${process.env.REACT_APP_FLOW_TESTNET_STORAGE_TRANSACTION_LINK}${data?.transactionHash}`
                        : `${process.env.REACT_APP_FLOW_MAINNET_STORAGE_TRANSACTION_LINK}${data?.transactionHash}`
                    }
                    className=""
                  >
                    <img
                      className="w-6 h-6 mt-1 cursor-pointer"
                      src={images.FlowChainIcon}
                      alt="icon"
                    />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClientTransactionDetail;
