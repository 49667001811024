import React from "react";
import PackagesCommonListing from "./PackagesCommonListing";

const Professional = ({ClientPackagesData,sm}) => {
  return (
    <>
        <div className={` ${sm ? 'p-3' : 'p-8'}  bg-[#12221A]/30 border border-[#A4B1A7]/25  rounded-lg  max-w-[320px] `}>
          <h4 className={` ${sm ? 'mb-2 text-base' : 'mb-4  text-2xl'} mb-4  font-bold text-white`}>Professional </h4>
          <PackagesCommonListing ClientPackagesData={ClientPackagesData} sm={sm}/>
        </div>
    </>
  );
};

export default Professional;
