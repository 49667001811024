import React, { useEffect, useState } from "react";
import Modal from "../../../../../common/ModalLayout";
import AlertModalContent from "../../../../../components/Modals/AlertModalContent";
import { images } from "../../../../../constants";

const AddTextFieldComponent = (props) => {
  const {
    inputFields,
    setInputFields,
    setcheckFieldDuplicate,
    checkFieldkeyDuplicate,
    setFieldIndex,
    fieldIndex,
    mutableFilledData,
    OpenAlertModal,
    setOpenAlertModal,
    setAlertContent,
  
  } = props;

  useEffect(() => {
    if (mutableFilledData) {
   
  
      let mutableDataJson = Object.entries(mutableFilledData).map((data) => {
        return {
          ["fieldKey"]: data[0]?.trim(),
          ["fieldValue"]: data[1]?.trim(),
        };
      });
      setFieldIndex(fieldIndex + (mutableDataJson?.length - 1));
      setInputFields(mutableDataJson);

      console.log(fieldIndex);
    }
    //eslint-disable-next-line
  }, []);

  /** Add input Field */
  const addInputField = () => {
    if (
      inputFields[fieldIndex]?.fieldKey?.trim() === "" ||
      inputFields[fieldIndex]?.fieldValue?.trim() === ""
    ) {
      // setcheckFieldDuplicate(true)
      setOpenAlertModal(true);
      setAlertContent({
        title: "Error",
        description: "Please add Key Value Pair ",
      });
    } else {
      setInputFields([...inputFields, { fieldKey: "", fieldValue: "" }]);
      setFieldIndex(fieldIndex + 1);
    }
  };
  /** Remove Input Field */
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setFieldIndex(fieldIndex - 1);
    setInputFields(rows);
    setcheckFieldDuplicate(false);
  };
  // console.log("first", inputFields);

  /** Handle Change to get field value */
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;

    if (name === "fieldKey") {
      setcheckFieldDuplicate(false);
    }
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields([...inputFields]);
  };

  const CheckKeyDuplication = (arr, evnt, index) => {
    const { value } = evnt.target;
    if (arr.length !== 1) {
      const rows = [...inputFields];
      rows.splice(index, 1);
      const found = rows.some((el) => el.fieldKey === value);
      if (found) {
        setcheckFieldDuplicate(true);
      }
    }
  };
  return (
    <>
      {/** dynamic field data  */}

      <div className="">
        {inputFields.map((element, index) => {
          return (
            <div className="relative mt-8" key={index}>
              <div className="grid grid-cols-2 gap-8 border border-[#A4B1A7]/25 px-4 py-4 pt-8 rounded-lg form-group bg-[#12221A]/30">
                <div className="relative z-10">
                  <input
                    onBlur={(e) => CheckKeyDuplication(inputFields, e, index)}
                    className="  peer outline-none border-t-0 border-r-0 border-l-0 border-b-1 border-[#A4B1A7] rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-b-[#A4B1A7] block w-full p-2.5 bg-transparent"
                    type="text"
                    name="fieldKey"
                    placeholder=" "
                    maxLength={"200"}
                    value={element.fieldKey || ""}
                    onChange={(e) => handleChange(index, e)}
                  />
                  <label
                    className={`text-xs text-grey-100 z-[-1]   absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-2  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs`}
                  >
                    Property
                  </label>
                  {checkFieldkeyDuplicate && index === fieldIndex && (
                    <p className="mt-2 text-xs text-red-600">
                      {OpenAlertModal ? <>''</> : "Field key must be unique"}
                    </p>
                  )}
                </div>
                <div className="relative z-10">
                  <input
                    className="  peer outline-none border-t-0 border-r-0 border-l-0 border-b-1 border-[#A4B1A7] rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-b-[#A4B1A7] block w-full p-2.5 bg-transparent"
                    type="text"
                    name="fieldValue"
                    placeholder=" "
                    maxLength={"200"}
                    value={element.fieldValue || ""}
                    onChange={(e) => handleChange(index, e)}
                  />
                  <label
                    className={`text-xs text-grey-100  z-[-1]  absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs`}
                  >
                    Trait
                  </label>
                </div>
              </div>

              <div className="absolute top-[0px] w-[20px] h-[20px] right-[-4px] ">
                {inputFields.length !== 1 ? (
                  <img
                    src={images.RemoveFieldIcon}
                    className=""
                    alt="icon"
                    onClick={() => removeInputFields(index)}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
        <span
          disabled={checkFieldkeyDuplicate}
          className={`${
            checkFieldkeyDuplicate
              ? "disabled:text-opacity-60 disabled:cursor-not-allowed"
              : "cursor-pointer"
          }  flex items-center mt-1 mb-10 text-xs `}
          onClick={() => {
            if (checkFieldkeyDuplicate) {
              return;
            }
            addInputField();
          }}
        >
          <img className="mr-1" src={images.AddFieldIcon} alt="add-icon" />
          Add New Key
        </span>
      </div>
      {/* {openAlertModal &&
       <Modal 
       modalVisible={openAlertModal}
        closeModal={setOpenAlertModal}
        children={
          <AlertModalContent
            message={{title:"Error",description:"Please add Key Value Pair "}}
          />
        }
        modal_Id="Delete_Modal"
        isClose={true}
      />} */}
    </>
  );
};

export default AddTextFieldComponent;
