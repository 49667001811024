import DateFormat from "../../helpers/DateFormat";

export const COLUMNS = [
  {
    Header: "CategoryId",
    Footer: "CategoryId",
    accessor: "id",
  },
    {
      Header: "Category",
      Footer: "Category",
      accessor: "categoryName",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor:"isActive",
      Cell: ({ row }) => (
        <>
        <p>{row?.values?.isActive ? 'Active' : 'Inactive'}  </p>
        </>
      )
    },
    {
      Header: "Created Date",
      Footer: "Created Date",
      accessor:"createdDate",
      Cell: ({ row }) => (
        <>
        <p>{DateFormat(row?.values?.createdDate)  }  </p>
        </>
      )
    },
  ];