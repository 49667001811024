import React from 'react';
import { images } from '../constants';


const SearchFilter = ({ filter, setFilter }) => {
    return (
        <div className="flex items-center flex-grow py-3 px-4 rounded-full bg-[#060C07] border border-[#A4B1A7] min-w-[80px] ">
            <img className="mr-2" src={images.searchIcon} alt="search" />
            <input
                className="w-full p-0 bg-transparent border-none placeholder:text-white focus:ring-transparent"
                placeholder="Search"
                type="text"
                value={filter || ''}
                onChange={(e) => setFilter(e.target.value)}
            />
        </div>
    )
}

export default SearchFilter