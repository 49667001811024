import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { images } from "../../../constants";
import ArweaveBalanceHook from "../../../helpers/custom-hooks/FetchArweaveBalance";
import GraphQL from "../../../helpers/GraphQL";
import { setLoader } from "../../../redux/features/loading/loaderSlice";
import { useDispatch } from "react-redux";

import ArweaveListing from "./ArweaveListing";
import { Tooltip } from "@material-tailwind/react";

const ArweaveReporting = () => {
  const dispatch = useDispatch();
  const [arweaveBalanceValue, setArweaveBalance] = useState({
    ARTokens: "0.00",
    ARUSD: "0.00",
  });
  const { getArweaveBalance, getArweaveUSDBalance } = ArweaveBalanceHook();
  const [arweaveData, setArweaveData] = useState([]);
  const { loading, error, data, fetchMore } = useQuery(
    GraphQL.getAllArweaveTransactions(),
    {
      variables: {
        first: 1000000000,
      },
      fetchPolicy: "cache-and-network", // Used for first execution
    }
  );
  useEffect(() => {
    fetchArweaveBalance();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    populateArweaveData(data);
    //eslint-disable-next-line
  }, [data]);
  const fetchArweaveBalance = async () => {
    const ARTokenBalance = await getArweaveBalance();
    let ARUSDBalance = await getArweaveUSDBalance();
    if (ARUSDBalance > 0) {
      ARUSDBalance = ARUSDBalance * parseFloat(ARTokenBalance);
    }

    setArweaveBalance({
      ...arweaveBalanceValue,
      ARTokens: ARTokenBalance,
      ARUSD: ARUSDBalance ?? "0.00",
    });
  };
  const populateArweaveData = (arweaveGraphQLData) => {
    dispatch(setLoader(true));
    let tagsArray = [];
    if (arweaveGraphQLData) {
      arweaveGraphQLData?.transactions?.edges.map((result, i) => {
        let imgObjData = {
          name: "imageType",
          value: result.node.data.type.split(":")[1],
        };
        let sizeObjData = {
          name: "imageSize",
          value: result.node.data.size,
        };
        let imageValObj = {
          name: "ImageValue",
          value: result.node.id,
        };
        let tags = result.node.tags
          .slice(3)
          .concat(imgObjData)
          .concat(sizeObjData)
          .concat(imageValObj);
        let combinedArray = {};
        tags.map((tag) => {
          combinedArray[tag.name] = tag.value;
        });

        tagsArray = [...tagsArray, combinedArray];
      });
      setArweaveData(tagsArray);
      dispatch(setLoader(false));
    }
  };
  return (
    <>
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-2xl font-bold text-white grow">
          Arweave Reporting
        </h1>
        <Tooltip
          className="text-[white] text-xs bg-[#12221A]   border border-[#12221A] rounded"
          offset={10}
          placement={"top-center"}
          content={`  ${
            arweaveBalanceValue?.ARTokens ? arweaveBalanceValue?.ARTokens : "0"
          }  `}
        >
          <div className="px-6 py-3 mr-6 rounded-full bg-[#12221A]/30 border border-[#A4B1A7]/25">
            Total Balance in AR Tokens:{" "}
            <span className="font-bold">
              {parseFloat(arweaveBalanceValue?.ARTokens)?.toFixed(4)}
            </span>
          </div>
        </Tooltip>
        <Tooltip
          className="text-[white] text-xs bg-[#12221A]   border border-[#12221A] rounded"
          offset={10}
          placement={"top-center"}
          content={`  ${
            arweaveBalanceValue?.ARUSD
              ? `$${parseFloat(arweaveBalanceValue?.ARUSD)}`
              : "0"
          } `}
        >
          <div className="px-6 py-3 rounded-full bg-[#12221A]/30 border border-[#A4B1A7]/25">
            Total Balance in USD:{" "}
            <span className="font-bold">
              {" "}
              {`${
                arweaveBalanceValue?.ARUSD
                  ? `$${parseFloat(arweaveBalanceValue?.ARUSD)?.toFixed("2")}`
                  : "0"
              }   `}
            </span>
          </div>
        </Tooltip>
      </div>

      {arweaveData?.length > 0 && <ArweaveListing arweaveData={arweaveData} />}
    </>
  );
};

export default ArweaveReporting;
