import React, { memo } from "react";
import { images } from "../../../constants";

const SelectedImageComponent = memo(
  (props) => {
    const { image, handleRemoveImage } = props;
 
    return (
      <>
        {image && (
          <>
            <div className="flex justify-between items-center text-xs mt-8 flex-grow py-3 px-4 rounded-xl bg-transparent border-2 border-zinc-700 min-w-[80px]">
              <div className="flex items-center">
                <img
                  className="object-contain mr-2 w-7 h-7"
                  src={URL.createObjectURL(image)}
                  alt="logo"
                />
                <span className="w-full p-0 font-bold bg-transparent border-none focus:ring-transparent">
                  Brand Logo
                </span>
              </div>
              <span className="text-zinc-400">
                {(image?.size / 1024).toFixed(2) + "kb"}
              </span>
              <img
                className="cursor-pointer hover:opacity-80"
                src={images.cross_icon}
                alt="X"
                onClick={() => handleRemoveImage()}
              />
            </div>
          </>
        )}
      </>
    );
  }
);

export default SelectedImageComponent;
