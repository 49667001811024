import React, { useEffect, useState } from "react";
import AddRoyalty from "../../../../components/ClientConfiguration/ClientRoyalty/AddRoyalty";
import ClientHeaderComponent from "../../components/ClientHeaderComponent";
import { useDispatch, useSelector } from "react-redux";
import { configurationService } from "../../../../services/client.configuration.service";
import { setLoader } from "../../../../redux/features/loading/loaderSlice";
import { useLayoutEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RoyaltySchema } from "../../../../utils/ValidationSchemas/AddRoyalitySchema";
import GetClientRoyaltyData from "../../../../helpers/custom-hooks/FetchRoyaltyData";

const RoyaltyContent = () => {
  const dispatch = useDispatch();
  const [openRoyalty, setOpenRoyalty] = useState(true);
  const { clientUuid, id } = useSelector((state) => state?.user?.clientDetail);
  const [inputFields, setInputFields] = useState([
    {
      name: "",
      receiverAddress: "",
      royaltyPercentage: "",
      description: "",
      royaltyActive: false,
    },
  ]);
  const { getRoyaltyData } = GetClientRoyaltyData();

  useEffect(() => {
    if (clientUuid) {
      getClientRoyalty();
    }
    //eslint-disable-next-line
  }, []);

  const getClientRoyalty = async () => {
    dispatch(setLoader(true));

    const repsonse = await getRoyaltyData(clientUuid);
    if (repsonse?.length > 0) {
      reset({ royalty: repsonse });
      setInputFields(repsonse);
      setOpenRoyalty(true)
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    unregister,
    clearErrors,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(RoyaltySchema),
    mode: "all",
    defaultValues: {
      royalty: inputFields,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "royalty",
  });

  // useEffect(() => {
  //   if(Object.entries(inputFields)?.length > 0){
  //     reset({ royalty: inputFields });

  //   }

  //   //eslint-disable-next-line
  // }, [inputFields]);

  return (
    <>
      <ClientHeaderComponent title={"Configuration"} />

      <AddRoyalty
        clientId={id}
        setInputFields={setInputFields}
        inputFields={inputFields}
        register={register}
        handleSubmit={handleSubmit}
        reset={reset}
        setValue={setValue}
        unregister={unregister}
        clearErrors={clearErrors}
        errors={errors}
        remove={remove}
        setOpenRoyalty={setOpenRoyalty}
        openRoyalty={openRoyalty}
        getClientRoyalty={getClientRoyalty}
      />
    </>
  );
};

export default RoyaltyContent;
