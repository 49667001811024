import React, { useState } from "react";
import AddTextFieldComponent from "../AddTextFieldComponent";
import { useNavigate } from "react-router-dom";
import SwitchComponent from "../../../../../../components/ui-components/switch-component/SwitchComponent";
import MutableToggle from "./MutableToggle";

const MutableComponent = (props) => {
  const navigate = useNavigate();
  const {
    steps,
    setSteps,
    setAlertContent,
    setOpenAlertModal,
    fieldIndex,
    setFieldIndex,
    activeStep,
    setActiveStep,
    setMutableInputFields,
    mutableInputFields,
    mutableFilledData,
    updateAssetHandler,
    setShowMutableKeyValue,
    showMutableKeyValue,
  } = props;
  /** Add dynamic set values */

  const [checkFieldkeyDuplicate, setcheckFieldDuplicate] = useState(false);

  const handleCreateAssetMutableStep = () => { 
    if (mutableInputFields?.length > 0) {
      if (
        mutableInputFields?.length === 1 &&
        mutableInputFields?.[0]?.fieldKey === "" &&
        mutableInputFields?.[0]?.fieldValue === ""
      ) {
        setActiveStep(3);
        setSteps(
          steps.map((step, index) =>
            step.value === 2 ? { ...step, IsCompleted: true } : step
          )
        );
      } else {
        let bEmptyPair = mutableInputFields?.filter((data) => {
          return data?.fieldKey === "" || data?.fieldValue === "";
        });
        if (bEmptyPair?.length > 0) {
          setAlertContent({
            title: "Error",
            description: "Please enter key value pair ",
          });
          setOpenAlertModal(true);
          setActiveStep(2);
        } else {
          setActiveStep(3);
          setSteps(
            steps.map((step, index) =>
              step.value === 2 ? { ...step, IsCompleted: true } : step
            )
          );
        }
      }
    }
  };
  const handleMutableKeyValue = () => {
    setShowMutableKeyValue(!showMutableKeyValue);
  };
 
  // scroll to specifix div in tailwind in react js 
  return (
    <>
      <div className=" h-[calc(100vh-272px)] relative">
        <div className="container relative z-20 grid h-full grid-cols-2 overflow-y-scroll">
          <div className="mt-8 columns-md max-w-[494px]">
            <div className="flex justify-between mb-3">
              <h2 className="text-2xl font-semibold ">Mutable</h2>
              <MutableToggle
                onChange={handleMutableKeyValue}
                value={showMutableKeyValue}
                id={"mutable"}
              />
            </div>
            <p className="mt-1 text-xs text-grey-100">
              Properties assigned to the asset under mutable section can be
              altered or changed later.
              <br />
              Property (Example: reward amount) Trait (Example: 20 points)
              <br />
              (Max  length 200 characters)
            </p>
            {showMutableKeyValue && (
              <AddTextFieldComponent
                inputFields={mutableInputFields}
                setInputFields={setMutableInputFields}
                setcheckFieldDuplicate={setcheckFieldDuplicate}
                checkFieldkeyDuplicate={checkFieldkeyDuplicate}
                setFieldIndex={setFieldIndex}
                mutableFilledData={mutableFilledData} //this data is for update case fetched from asset listing
                fieldIndex={fieldIndex}
                setOpenAlertModal={setOpenAlertModal}
                setAlertContent={setAlertContent}
           
              />
            )}
          </div>
          <div></div>
        </div>
        <div className="absolute -bottom-5 bg-[#060C07] flex justify-between items-center w-full  z-50">
          <div className={` max-w-[494px] flex justify-between w-full `}>
            <button
              className={`btn-secondary capitalize  max-w-[227px] text-center ${
                mutableFilledData !== undefined ? "hidden" : ""
              }`}
              onClick={() => setActiveStep(1)}
            >
              Back
            </button>
            <button
              disabled={checkFieldkeyDuplicate}
              className="btn-primary max-w-[227px] text-center disabled:cursor-not-allowed"
              onClick={
                mutableFilledData !== undefined
                  ? updateAssetHandler
                  : handleCreateAssetMutableStep
              }
            >
              {mutableFilledData !== undefined ? "Submit" : "Next"}
            </button>
          </div>
          <div className={`flex justify-end items-end `}>
            <span
              className="px-10 py-3 font-bold text-base cursor-pointer hover:bg-[#A5F33C] hover:text-[#12221A] hover:rounded-full mr-10"
              onClick={() => navigate("/asset-list")}
            >
              Cancel
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default MutableComponent;
