import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from './../ui-components/loader-components/Spinner';
import EditorQuill from './EditorQuill';
import ClientService from '../../services/client.service';
import { useDispatch, useSelector } from 'react-redux';
import AlertModalContent from '../Modals/AlertModalContent';
import Modal from '../../common/ModalLayout';
import { setLoader } from "../../redux/features/loading/loaderSlice";

const PrivacyPolicy = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [value, setValue] = useState("");
    const [OpenAlertModal, setOpenAlertModal] = useState(false);
    const [alertContent, setAlertContent] = useState({
        title: "",
        description: "",
        path: "",
        sublabel: ""
    })
    const [clientThemeDetail, setClientThemeDetail] = useState();

    const dispatch = useDispatch();

    const user = useSelector(state => state.user);

    const handleChange = value => {
        // console.log("terms value", value);
        setValue(value);
        setButtonClicked(false);
    };

    const getClientThemeDetails = () => {
        // dispatch(setLoader(true));
        try {
            ClientService.getClientThemeDetails(user?.clientDetail?.id, user?.userInfo?.access_token)
                .then((res) => {
                    dispatch(setLoader(false));
                    if (res) {
                        setClientThemeDetail(res?.data?.payload);
                        setValue(res?.data?.payload?.privacypolicyHtmlString);
                    }
                })
                .catch((err) => {
                    dispatch(setLoader(false));
                });

        }
        catch (err) {
            dispatch(setLoader(false));
            // console.log('err', err)
        }
    }

    useEffect(() => {
        
        getClientThemeDetails();
    }, [])

    const submitForm = () => {

        if (value === '') {
            setButtonClicked(true);
            return;
        }

        let formData = new FormData();
        formData.append('clientThemeSteps', 4);
        formData.append('ClientThemeUuid', clientThemeDetail?.clientThemeUuid ? clientThemeDetail?.clientThemeUuid : "");
        formData.append('ClientId', user?.clientDetail?.id);
        formData.append('BrandName', user?.clientDetail?.clientName);
        formData.append('PrivacypolicyHtmlString', value);


        setLoading(true);

        ClientService.createUpdateClientTheme(formData)
            .then((res) => {
                setLoading(false);
                // console.log('res', res);
                if (res?.status === 200) {
                    if (res?.data?.success === true) {
                        setOpenAlertModal(true);
                        setTimeout(() => {
                            setOpenAlertModal(false);
                        }, 3000);
                        setAlertContent({ title: "Success", description: "Changes have been saved successfully.", sublabel: "Auto closing in 3 seconds..." });
                        setClientThemeDetail(res?.data?.payload);
                    }
                    else {
                        setOpenAlertModal(true);
                        setTimeout(() => {
                            setOpenAlertModal(false);
                        }, 3000);
                        setAlertContent({ title: "Error", description: "Failed to save changes. Please try again.", sublabel: "Auto closing in 3 seconds..." });
                    }
                }
            })
            .catch((err) => {
                setLoading(false);

            })
    }

    const gotoWebsite = () => {
        navigate("/storefront-demo1")
    }

    return (
        <>
            <div className="text-white">
                <h1 className="text-xl font-bold ">Privacy Policy</h1>
                <div className='mt-6'>

                    <EditorQuill toolbarId={'t3'} value={value} onChange={handleChange} />

                    {(value === '' && buttonClicked === true) && <p className='mt-1 text-xs text-red-600'>Please write something to save.</p>}

                    <div className='flex mt-10 mb-[30px]'>
                        <button
                            type="button"
                            className={`btn-secondary disabled:cursor-not-allowed mr-4 capitalize w-fit px-12`}
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="light"
                            disabled={true}
                            onClick={gotoWebsite}>
                            Live Preview
                        </button>
                        <button
                            onClick={submitForm}
                            type="submit"
                            className={`btn-primary disabled:cursor-not-allowed w-fit px-12`}
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="light"
                            disabled={loading}>
                            {loading && <Spinner />}
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>

            {OpenAlertModal && <Modal modalVisible={OpenAlertModal}
                closeModal={setOpenAlertModal}
                children={<AlertModalContent message={alertContent} />}
                modal_Id="Add_Modal"
                isClose={true}

            />}
        </>
    )
}

export default PrivacyPolicy;