import { images } from '../constants';

const mediaExtractor = (value) => {
	if (value?.arweaveAssetType === 'Image') {
		return (
			<img
				width="24"
				height="24"
				className="object-cover rounded-[4px]"
				src={value?.reducedImage}
				alt={value?.reducedImage}
			/>
		);
	} else if (value?.arweaveAssetType === 'Video') {
		return (
			<img
				width="24"
				height="24"
				className="object-cover rounded-[4px]"
				src={images.videoPlaceHolder}
				alt="video"
			/>
		);
	} else {
		return (
			<img
				width="24"
				height="24"
				className="object-cover rounded-[4px]"
				src={images.AudioIcon}
				alt="audio"
			/>
		);
	}
};

export default mediaExtractor;
