import { images } from "../images";

const menuItems = [
  // ...
  {
    title: "Dashboard",
    url: "/client-dashboard",
    icon: images.DashboardIcon,
    inactiveIcon: images.DashboardInactive,
    value: 4,
  },
  {
    title: "Minting",
    url: "/asset-list",
    icon: images.MintingIcon,
    inactiveIcon: images.MintingInactive,
    value: 5,
    submenu: [
      {
        title: "Assets",
        url: "asset-list",
        icon: images.AssetIcon,
        inactiveIcon: images.AssetInactive,
        parentMenu: "Minting",
        value: 1,
      },
      {
        title: "Drops",
        url: "drop-list",
        icon: images.DropIcon,
        inactiveIcon: images.DropInactive,
        parentMenu: "Minting",
        value: 2,
      },
    ],
  },

  {
    title: "Theme",
    url: "/client-theme",
    icon: images.MonitorIcon,
    inactiveIcon: images.ThemeInactive,
    value: 6,
  },
  {
    title: "Reporting",
    url: "/tx-reporting",
    icon: images.ReportingIcon,
    inactiveIcon: images.ReportingInactive,
    value: 7,
    submenu: [
      {
        title: "Transaction History",
        url: "/tx-reporting",
        icon: images.TransactionReportingIcon,
        inactiveIcon: images.TransactionInactive,
        parentMenu: "Reporting",
        value: 1,
      },
      {
        title: "Air Drop",
        url: "/whitelist",
        icon: images.WhitelistIcon,
        inactiveIcon: images.WhitelistInactive,
        parentMenu: "Reporting",
        value: 2,
      },
    ],
  },
  // {
  //   title: "Whitelist",
  //   url: "/whitelist",
  //   icon: images.WhitelistIcon,
  //   inactiveIcon: images.WhitelistInactive,
  //   value: 8,
  // },

  // {
  // 	title: "Configuration",
  // 	url: "/add-royalty",
  // 	icon: images.ConfigurationMenuIcon,
  // 	inactiveIcon: images.ContributorMenuInactive ,
  // 	value: 8,
  // 	submenu: [

  // 		{
  // 			title: "Royalty",
  // 			url: "/add-royalty",
  // 			icon: images.RoyalityMenuIcon,
  // 			inactiveIcon: images.RoyalityMenuInactive,
  // 			parentMenu: "Configuration",
  // 			value:1,
  // 		},
  // 		// {
  // 		//  	title: "Contributor",
  // 		//  	url: "/add-contributor",
  // 		//  	icon: images.ContributorMenuIcon,
  // 		//  	inactiveIcon:images.ContributorMenuInactive,
  // 		//  	parentMenu: "Configuration",
  // 		// 	value:2,
  // 		//  },
  // 	],
  // },

  {
    title: "Settings",
    url: "/client-change-password",
    icon: images.SettingIcon,
    inactiveIcon: images.SettingInactive,
    value: 9,
    submenu: [
      {
        title: "Change Password",
        url: "/client-change-password",
        icon: images.ChangePasswordIcon,
        inactiveIcon: images.ChangePasswordInactive,
        parentMenu: "Settings",
        value: 1,
      },
      {
        title: "Edit Profile",
        url: "/onboarding",
        icon: images.EditProfileIcon,
        inactiveIcon: images.EditProfileInactive,
        parentMenu: "Settings",
        value: 2,
      },
    ],
  },
  // {
  // 	title: "Logout",
  // 	url: "/login",
  // 	icon: images.LogoutIcon,
  // },

  // ...
];
const menuItemsAdmin = [
  {
    title: "Client Management",
    url: "/dashboard",
    icon: images.ClientSettingIcon,
    inactiveIcon: images.ClientDashboardInactive,
    value: 0,
  },
  {
    title: "Category",
    url: "/category",
    icon: images.CategoryIcon,
    inactiveIcon: images.CategoryInactive,
    value: 1,
  },
  {
    title: "Arweave Reporting",
    url: "/arweave-reporting",
    icon: images.ArweaveReportingIcon,
    inactiveIcon: images.ArweaveReportingInactive,
    value: 2,
  },
  {
    title: "Packages",
    url: "/choose-package",
    icon: images.MenuIconPackages,
    inactiveIcon: images.PackageMenuInactive,
    value: 3,
  },
];

export { menuItems, menuItemsAdmin };
