import React, { useState, useReducer } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import OnboardingSidebar from "../components/ClientOnboarding/Sidebar/OnboardingSidebar";
// import login_image from "./../../src/assets/images/KYB_Background.png";
import Modal from "./../common/ModalLayout";
import UserAvatar from "./ClientPortal/components/UserAvatar";
import { useSelector } from "react-redux";
import {
	INITIAL_STATE,
	menuItemsReducer,
} from "../components/ClientOnboarding/Utils/menuItemsReducer";

const Onboarding = () => {
	const navigate = useNavigate();
	const [state] = useReducer(menuItemsReducer, INITIAL_STATE);
	// const [menuItems, setMenuItems] = useState([
	// 	{
	// 		id: 1,
	// 		step: 1,
	// 		label: "Connect Wallet",
	// 		link: "/connect-wallet",
	// 		isActive: true,
	// 	},
	// 	{
	// 		id: 2,
	// 		step: 2,
	// 		label: "Brand Details",
	// 		link: "/brand-details",
	// 		isActive: false,
	// 	},
	// 	{
	// 		id: 3,
	// 		step: 3,
	// 		label: "Brand Categories",
	// 		link: "/brand-categories",
	// 		isActive: false,
	// 	},
	// 	{
	// 		id: 4,
	// 		step: 4,
	// 		label: "Brand Theme",
	// 		link: "/brand-theme",
	// 		isActive: false,
	// 	},
	// 	{
	// 		id: 5,
	// 		step: 5,
	// 		label: "Social Links",
	// 		link: "/social-links",
	// 		isActive: false,
	// 	},
	// ]);

	const { contactFirstName, contactLastName ,isClientApproved } = useSelector(
		(state) => state?.user.clientDetail
	);


	const inputFieldStyle = `outline-none border-t-0 border-r-0 border-l-0 border-b-2 border-zinc-600 rounded-none pl-0 pr-7 text-white text-md
	focus:ring-0 focus:border-b-blue-500 block w-full p-2.5 bg-transparent`;

	return (
		<>
			<main className="relative h-screen bg-[#060C07] font-manrope">
				<div className="relative flex w-full h-full px-6 py-8 overflow-auto bg-[#060C07] bg-[url('/src/assets/images/main-dashboard-bg.png')] bg-no-repeat bg-[length:100%_100%]  ">
					<OnboardingSidebar menuItems={state.menuItems} />

				
					 
					<div className="absolute text-white right-16 top-4">
					
						<UserAvatar
							firstname={contactFirstName}
							lastname={contactLastName}
							isClientApproved={isClientApproved}
						/>
						
					</div>
					<div className="flex flex-col flex-auto w-fit ml-[44%] p-20 pl-0 text-white">
						<Outlet context={inputFieldStyle} />
					</div>
				</div>
				{/* <div className="flex w-full"></div> */}
			</main>
		</>
	);
};

export default Onboarding;
