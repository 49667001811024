import React from 'react'
import { images } from '../../../constants'

const PreviewCard = () => {
  return (
   <>
     <div
        className={`  max-w-[282px] rounded-3xl hover:background-border-rad background-border-rad  p-0.5 w-full md:w-auto transition-all hover:translate-y-1  cursor-pointer text-left mb-7`}
        
      >
        <div className="relative p-4 bg-black rounded-3xl">

          <div
            className={`rounded-3xl max-w-fit bg-white px-3 py-1 leading-4 text-black text-xs capitalize font-semibold mb-2`}
          >
            Mint: 1/1
          </div>

         
            <div className="relative">
              {/* <img className="absolute top-14 right-6" src={images.video_icon} alt="media_icon" /> */}
              <img
                src={images.PreviewDropIcon}
                className="object-cover w-full h-[268px] max-w-full mb-2 rounded-2xl"
                alt="pic"
              />
            </div>
        
     

          {/*-------- CURRENT DROPS AND ADDITIONAL DROPS ---------- */}
    
              <p
               
                className={`text-white text-base max-w-[250px] mb-2 font-bold truncate`}
              >
                Game of Legends
              </p>
           
            <div className="flex items-end">
              {/* {!isCollection && ( */}
              <div>
                <p className={`text-white font-bold`}>
                $14.81
                </p>
                <span
                  className={`text-grey-100text-xxs leading-none flex items-center`}
                >
                  <img src={images.FlowFcIcon} className="mr-1" alt="flow-icon" />
                  1,123.82
                </span>
              </div>
              {/* )}*/}
            
            </div>

        

       
         
        </div>
      </div>
   </>
  )
}

export default PreviewCard