import React from "react";
import { Link } from "react-router-dom";
import stringTruncate from "../../helpers/StringTruncate";

const BrandSocialMediaLink = (props) => {
  const { icon, name } = props;
  return (
    <>
      <div className="flex items-center max-w-sm px-4 py-3 mb-5 border-2 rounded-full bg-none border-zinc-500">
        <img className="mr-2" src={icon} alt={"social-icon"} />
        {name ? (<a href={name} className='overflow-hidden whitespace-nowrap text-ellipsis'  rel="noreferrer" target={"_blank"} ><p className="text-xs">{stringTruncate(name ,50)}</p></a>) : (<p className="text-xs"> -</p>)}
      
      </div>
    </>
  );
};

export default BrandSocialMediaLink;
