import React from "react";

// import brand_img from "./../../assets/images/adidas_logo.png";

const BrandDetail = (props) => {
  const { clientData } = props;
  return (
    <div className="text-white">
      <h1 className="mb-1 text-3xl font-bold">Brand Detail</h1>
      <p className="mb-6 text-xs text-grey-100">
        Brand details provided by the client.
      </p>
      <div className="flex flex-col mb-4">
        <p className="text-base text-grey-100 ">Brand Wallet Address</p>
        <p className="text-base ">{clientData?.clientWalletAddress ?? "-"}</p>
      </div>
      <div className="flex items-center justify-start mb-4">
        <div className="flex flex-col mr-10">
          <span className="text-base text-grey-100">Brand Name</span>
          <span className="text-base">{clientData?.clientName ?? "-"}</span>
        </div>
        <div className="flex flex-col mr-10">
          <span className="text-base text-grey-100">Registration No</span>
          <span className="text-base">
            {clientData?.registerationNo ?? "-"}
          </span>
        </div>
      </div>

      <div className="mb-4">
        <div className="flex flex-col">
          <span className="text-base text-grey-100">Brand Domain</span>
          <a
            className="cursor-pointer"
            href={`https://${clientData?.clientDomainName}`}
            target="_blank"
          >
            {clientData?.clientDomainName ?? "-"}{" "}
          </a>
        </div>
      </div>
      <div className="mb-4 overflow-hidden">
        <div className="flex flex-col">
          <span className="mb-2 text-base text-grey-100">Brand Logo</span>
          {clientData?.logo ? (
            <div className="w-24 h-24">
              <img
                src={clientData?.logo}
                className="object-cover mb-4"
                alt="brand"
              />
            </div>
          ) : (
            "-"
          )}
        </div>
      </div>

      <div>
        <p className="text-base text-grey-100">Brand Description</p>
        <p className="text-base ">{clientData?.clientDescription ?? "-"}</p>
      </div>
    </div>
  );
};

export default BrandDetail;
