import axios from "axios";
const API_URL = process.env.REACT_APP_ARWEAVEAPIURL
const getArweaveBalance = () => {
    return axios.get(API_URL + "getArweaveBalance", {}).then((response) => {
      return response?.data ?? response;
    });
  };
  const uploadToArweave = (file, config) => {
    return axios
      .post(API_URL + "uploadFileToArweave", file, config)
      .then((response) => {
        return response?.data ?? response;
      });
  };
  const checkArweavePriceForFile = (fileSize) => {
    return axios.get("https://arweave.net/price/" + fileSize).then((response) => {
      return response?.data ?? response;
    });
  };
  const ArweaveService = {
    getArweaveBalance,
    uploadToArweave,
    checkArweavePriceForFile
  };
  export default ArweaveService;