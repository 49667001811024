export const COLUMNS = [
    {
      Header: "Asset Name",
      Footer: "Asset Name",
      accessor: "Asset_Title",
    },
    {
      Header: "Asset Type",
      Footer: "Asset Type",
      accessor:"ArweaveAssetType"
    },
  
  
    {
      Header: "Max Supply",
      Footer: "Max Supply",
      accessor: "maxSupply",
      Cell: ({ row }) => (
        <>
         <p>{row?.values?.maxSupply === null ? (<p>Unlimited</p>) : row?.values?.maxSupply }</p>
        </>
      ),
    },
    {
        Header: "Created",
        Footer: "Created",
        accessor: "Date_Created",
      },

  ];
  