import React from 'react'

const ConnectWalletLeftContent = () => {
  return (
    <>
     <div className="relative max-w-[518px]">
      <div className='absolute top-[50%] left-[50%] w-[100%] -translate-x-[50%] -translate-y-[50%] text-center'>
        <h2 className="text-4xl font-bold text-white ">
        Congratulations, your KYB has been approved 
        
        </h2>
        <p className='mx-10 mt-3 mb-8 text-xs text-grey-100'>You can start building your storefront now. Please connect the same wallet that you used during the KYB process. </p>
        </div>
      </div>
    </>
  )
}

export default ConnectWalletLeftContent