import clientPackagesService from "../../services/clientpackages.service";

const useClientPackages = () => {
    const fetchClientAllPackages=()=>{
       return clientPackagesService.getAllPackages().then((result) => {
            const packagesReslt = result?.data?.payload
            if (packagesReslt) {
             return packagesReslt;
            }
            else
             return null;
          }).catch((error) => {
           return null;
          })
    }
  return {
    fetchClientAllPackages
  }
}

export default useClientPackages