import React, { useState, useEffect } from "react";
import BasicInfo from "../components/ClientDetails/BasicInfo";
import BrandDetail from "../components/ClientDetails/BrandDetail";
import BrandCategory from "../components/ClientDetails/BrandCategory";
import BrandSocialMedia from "../components/ClientDetails/BrandSocialMedia";
import BrandTheme from "../components/ClientDetails/BrandTheme";

import { Link, useLocation, useParams } from "react-router-dom";
import {
  Tab,
  TabBody,
  TabComponent,
  TabHeader,
  TabPanel,
} from "../components/ui-components/tabs-component/tabs";
import clientService from "./../services/client.service";
import Modal from "../common/ModalLayout";
import DeclineModalContent from "../components/Modals/DeclineModalContent";
import { images } from "../constants/images";
import { Menu, MenuHandler, MenuList } from "@material-tailwind/react";
import { useDispatch } from "react-redux";
import { setLoader, setLoaderMessage } from "../redux/features/loading/loaderSlice";


const ClientDetails = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const { status } = state || {};
  const [openTab, setOpenTab] = useState(1);
  const [clientData, setclientData] = useState({});
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [statusResponse, setStatusResponse] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line
  }, [id]);
  const getClientData = () => {
    clientService.getClientById(id).then((response) => {
      if (response?.data?.statusCodeValue === 200) {
        setclientData(response?.data?.payload);
      }
    });
  };

  const handleApprovedOrDeclined = (status, reason) => {
    dispatch(setLoader(true))
    dispatch(setLoaderMessage("Please wait. Your brand is being created on the blockchain."))
    clientService
      .ApproveOrDeclinedClient(id, status, reason)
      .then((response) => {
        if (response?.data?.statusCodeValue === 200) {
          dispatch(setLoader(false))
          dispatch(setLoaderMessage(""))
          if (response?.data?.payload?.isClientApproved && response?.data?.payload?.blockChainBrandId > 0 ) {
            setStatusResponse("approved");
            setShowDeclineModal(false);
          } else {
            setStatusResponse("declined");
            setShowDeclineModal(false);
          }
        }
      })
      .catch((error) => {
        dispatch(setLoader(false))
        dispatch(setLoaderMessage(""))
        setShowDeclineModal(false);
      });
  };
  const handleClientDeactivate = (clientId) => {
    dispatch(setLoader(true))
    clientService
      .declinedClient(clientId)
      .then((response) => {
        if (response?.data?.statusCodeValue === 200) {
          dispatch(setLoader(false))
          
          setStatusResponse("Deactive");
        }
      })
      .catch((error) => {
        dispatch(setLoader(false))
        alert("something went wrong");
      });
  };
  const handleCloseDeclineModal = () => {
    setShowDeclineModal(true);
  };
 

  return (
    <>
      
        {/* Header */}
        <h1 className="mb-10 text-2xl font-bold text-white">
          Client Management
        </h1>

        {/* Sub Header & breadcrumbs*/}
        <div className="flex justify-between mb-10">
          <div>
            <h2 className="mb-2 text-[20px] font-bold text-white ">
              Client Details
            </h2>
            <div className="relative items-center justify-start text-xs font-semibold z-20flex">
              <span className="mr-2 text-grey-100">
                <Link to="/dashboard">Dashboard</Link>
              </span>
              <span className="text-white"> ➡ &nbsp; Client Details</span>
            </div>
          </div>
          { statusResponse === "Deactive" &&
              <p
              className={`ml-2 text-base font-semibold text-red `}
            >
              Deactivate Successfully
            </p>
            }
          {status === "Active" && (
            <>

              <Menu>
              <MenuHandler>
                
                <img
                  className="self-center mr-1 text-center cursor-pointer hover:opacity-90"
                  src={images.MoreButtonIconView}
                  alt="icon"
                />
              </MenuHandler>
              <MenuList className="flex flex-col min-w-[100px] px-4 pt-4 pb-0 z-20">
                {/* <Link
                  to={'#'}
                  
                  className="inline-block px-0 pt-0 pb-3 text-xs text-black dark:focus-visible:bg-transparent focus-visible:bg-transparent font-poppins active:bg-transparent active:text-blue-500 hover:bg-transparent hover:text-blue-500"
                >
                  View
                </Link> */}
                <Link
                  
                  onClick={()=>handleClientDeactivate(id)}
                  className="inline-block px-0 pt-0 pb-3 text-xs text-black focus-visible:outline-0 dark:focus-visible:bg-transparent focus-visible:bg-transparent font-poppins active:bg-transparent active:text-blue-500 hover:bg-transparent hover:text-blue-500"
                >
                  Deactivate
                </Link>
              </MenuList>
            </Menu>
            </>
          )}
          {status === "Pending" && (
            <>
              {statusResponse === "" ? (
                <div className="flex ">
                  <button
                    className={`btn-secondary border border-solid  mr-6 px-16  disabled:cursor-not-allowed`}
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    onClick={handleCloseDeclineModal}
                  >
                    Decline
                  </button>
                  <button
                    className={`btn-primary px-16  disabled:cursor-not-allowed`}
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    onClick={() => handleApprovedOrDeclined(true, "")}
                  >
                    Approve
                  </button>
                </div>
              ) : (
                <>
                  <div className="flex items-center">
                    <img
                      src={
                        statusResponse === "approved"
                          ? images.ApprovedIcon
                          : images.DeclinedClientIcon
                      }
                      alt="icon"
                    />
                    <p
                      className={`ml-2 text-base font-semibold  ${
                        statusResponse === "approved"
                          ? "text-[#A5F33C]"
                          : "text-red"
                      } `}
                    >
                      {statusResponse === "approved" ? "Approved" : "Declined"}
                    </p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <TabComponent>
          <TabHeader>
            <Tab openTab={openTab} setOpenTab={setOpenTab} value={1}>
              Basic Info
            </Tab>
            <Tab openTab={openTab} setOpenTab={setOpenTab} value={2}>
              Brand Detail
            </Tab>
            <Tab openTab={openTab} setOpenTab={setOpenTab} value={3}>
              Brand Category
            </Tab>
            <Tab openTab={openTab} setOpenTab={setOpenTab} value={4}>
              Brand theme
            </Tab>
            <Tab openTab={openTab} setOpenTab={setOpenTab} value={5}>
              Social Media Links
            </Tab>
          </TabHeader>
          <TabBody>
            <TabPanel openTab={openTab} value={1}>
              <BasicInfo clientData={clientData} />
            </TabPanel>
            <TabPanel openTab={openTab} value={2}>
              <BrandDetail clientData={clientData} />
            </TabPanel>
            <TabPanel openTab={openTab} value={3}>
              <BrandCategory clientData={clientData} />
            </TabPanel>
            <TabPanel openTab={openTab} value={4}>
              <BrandTheme clientData={clientData} />
            </TabPanel>
            <TabPanel openTab={openTab} value={5}>
              <BrandSocialMedia clientData={clientData} />
            </TabPanel>
          </TabBody>
        </TabComponent>

        {/* Tabbing */}

        {/* Tabs End */}
     

      <Modal
        modalVisible={showDeclineModal}
        closeModal={setShowDeclineModal}
        children={
          <DeclineModalContent
            handleApprovedOrDeclined={handleApprovedOrDeclined}
          />
        }
        modal_Id="Delete_Modal"
        isClose={true}
      />
    </>
  );
};

export default ClientDetails;
