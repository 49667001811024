import { getRequest, postRequest } from "../utils/api/axiosMethods";

const getPackageById = (UuId) => {
    return getRequest(`ClientPackages/get_client_packages_byid?domainName=${UuId}`).then((response) => {
      return response;
    });
  };
  const getAllPackages = () => {
    return getRequest(`ClientPackages/get_client_packages`).then((response) => {
      return response;
    });
  };
  const updatePackage = (payload) => {
    return postRequest(`ClientPackages/update_client_package`,payload).then((response) => {
      return response;
    });
  };
  const createPackage = (payload) => {
    return postRequest(`ClientPackages/create_client_package`,payload).then((response) => {
      return response;
    });
  };
const clientPackagesService = {
createPackage,
updatePackage,
getAllPackages,
getPackageById
}
export default clientPackagesService;