import React from "react";
import { images } from "../../constants";

const PackageOption = (props) => {
  const { option ,sm} = props;
  return (
    <>
      <div className={`flex items-center ${sm ? "mb-2" : "mb-4"} `}>
        <span className="mr-3">
          {" "}
          <img src={images.PackageCheckIcon} alt="icon" />
        </span>
        <h4 className={`${sm ? 'text-[10px]' : 'text-xs'}  text-white`}>{option}</h4>
      </div>
    </>
  );
};

export default PackageOption;
