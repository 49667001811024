import React from "react";
import instagram_icon from "./../../assets/images/icons/Instagram.png";
import linkedin_icon from "./../../assets/images/icons/LinkedIn.png";
import discord_icon from "./../../assets/images/icons/Discord.png";
import twitter_icon from "./../../assets/images/icons/Twitter.png";
import BrandSocialMediaLink from "./BrandSocialMediaLink";
import { images } from "../../constants";

const BrandSocialMedia = (props) => {
  const { clientData } = props;
  return (
    <div className="text-white">
      <h1 className="mb-1 text-3xl font-bold">Social Media Links</h1>
      <p className="mb-6 text-xs text-grey-100">
      Social media platform links.{" "}
      </p>
      <div className="flex flex-col">
        <BrandSocialMediaLink
          name={clientData?.instagramUrl}
          icon={instagram_icon}
        />
        <BrandSocialMediaLink name={clientData?.linkedInUrl} icon={linkedin_icon} />
        <BrandSocialMediaLink name={clientData?.discordUrl} icon={discord_icon} />
        <BrandSocialMediaLink name={clientData?.twitterUrl} icon={twitter_icon} />
        <BrandSocialMediaLink name={clientData?.youtubeUrl} icon={images.SocialMediaYouTubeIcon} />
        <BrandSocialMediaLink name={clientData?.facebookUrl} icon={images.SocialMediaTiktokIcon} />
      </div>
    </div>
  );
};

export default BrandSocialMedia;
