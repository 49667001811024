import React, { useState, useCallback, Fragment } from "react";
import getCroppedImg from "../../helpers/cropImage";
import Cropper from "react-easy-crop";
import { v4 as uuidv4 } from "uuid";
import dataUrlToFile from "../../helpers/dataUrlToFile";

const ImageCropper = (props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        props.imgSrc,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);
  const SaveCroppedImageHandler = () => {
    let fileName = uuidv4() + ".png";
    let file = dataUrlToFile(croppedImage, fileName);
    props.getCroppedImgFile(file);
    props.setIsMediaSelect({...props.isMediaSelect,status:true,message:""})
  
  };
  // const dataURLtoFile = (base64, fileName) => {
  //   var arr = base64.split(","),
  //     mime = arr[0].match(/:(.*?);/)[1],
  //     bstr = atob(arr[1]),
  //     n = bstr.length,
  //     u8arr = new Uint8Array(n);

  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }

  //   return new File([u8arr], fileName, { type: mime });
  // };
  return (
    <Fragment>
      <h3 className="mb-4 text-2xl font-bold text-white">Crop Image</h3>
      <div className="relative row">
      
        <div className="col-md-12">
        
          <div>
            
            <div className={`${props?.style ? props?.style : 'h-[200px]'} `} >
              <Cropper
                image={props.imgSrc}
                crop={crop}
                zoom={zoom}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                aspect={props?.aspectRatio}
                // objectFit="horizontal-cover"
                //cropSize={{ width: 535, height: 300 }}
                
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="mt-3">
          {croppedImage && (
            <Fragment>
              <img
                className="mt-4 img-fluid"
                src={croppedImage}
                onClick={onClose}
                alt="croppedImg"
                width="400"
                height="400"
              />
            </Fragment>
          )}
          <div className="mt-10 col-12">
            <button type="button"
              className="btn-primary"
              onClick={showCroppedImage}
              style={{ display: croppedImage ? "none" : "" }}
            >
              Crop Image
            </button>
            <button type="button"
            className="btn-primary"
              onClick={SaveCroppedImageHandler}
              style={{ display: croppedImage ? "" : "none" }}
            >
              Save Image
            </button>
            {croppedImage && (
              <button className="mt-2 btn-secondary "  type="button"
                onClick={onClose}
              >
                Clear
              </button>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ImageCropper;
