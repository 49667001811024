import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../utils/api/axiosMethods";
import { postRequestIdentity } from "../utils/api/axiosMethodsIdentityServer";

// This is for registering client (client uuid is created after calling this api)
const getClientId = (data) => {
  const payload = {
    ClientFirstName: data.firstName,
    ClientLastName: data.lastName,
    ClientEmail: data.email,
    ClientNotes: data.Notes?.trim(),
    ClientPackageId:data?.package,
    CustomePlatformFee:data?.platformFee?.toFixed(2) ?? "0.00"
  };
  return postRequest("Client/register_client", payload).then((response) => {
    return response;
  });
};

const registerUser = (data) => {
  const payload = {
    FirstName: data.firstName,
    LastName: data.lastName,
    UserEmail: data.email,
    ClientId: data.clientId,

  };
  return postRequestIdentity("Auth/register_client", payload).then(
    (response) => {
      return response;
    }
  );
};

const getAllClients = (data) => { 
  return getRequest("Client/get_all_clients").then((response) => {
    return response;
  });
};
const getClientById = (clientId) => {
  return getRequest(`Client/get_client_by_id?clientUuid=${clientId}`).then(
    (response) => {
      return response;
    }
  );
};

const ApproveOrDeclinedClient = (clientId, status, reason) => {
  const payload = {
    id: 0,
    clientUuid: clientId,
    approved: status,
    declineReason: reason,
  };

  return postRequest("Client/approve_decline_client", payload).then(
    (response) => {
      return response;
    }
  );
};
const declinedClient = (clientId) => {
  return deleteRequest(`Client/delete_client?clientUuid=${clientId}`).then(
    (response) => {
      return response;
    }
  );
};

const getClientDetailsById = (clientUuid) => {
  return getRequest(`client/get_client_by_id?clientUuid=${clientUuid}`).then(
    (response) => {
      return response;
    }
  );
};

const getBrandCategories = () => {
  return getRequest("Category/categories").then((response) => {
    return response;
  });
};

const brandAvailability = (payload) => {
  return postRequest("Client/brand_availability", payload).then((response) => {
    return response;
  });
};

const updateClient = (payload) => {
  return postRequest("Client/update_client", payload).then((response) => {
    return response;
  });
};

const updateClientCapability = (clientUuid, capabilityTxId) => {
  const payload = {
    clientUuid: clientUuid,
    bcTransactionId: capabilityTxId,
  };
  return postRequest("client/update_client_capability", payload).then(
    (response) => {
      return response;
    }
  );
};

const createUpdateClientTheme = (data) => {

  return postRequest("clienttheme/create_update_client_theme", data).then(
    (response) => {
      return response;
    }
  );
};

const getClientThemeDetails = async(ClientId, token) => {
  const headers = {
    'Authorization': `Bearer ${token}`
  }
  return await postRequest(`clienttheme/get_client_theme?ClientId=${ClientId}`, { headers: headers }).then((response) => {
    return response;
  });
};

const checkBrandWelUrlAvailibility = (domainName) => {
  return getRequest(`Client/domain_availability?domainName=${domainName}`).then((response) => {
    return response;
  });
};
const updateClientPriceConfigurations = (payload) => {
  return postRequest(`Client/update_client_price_configurations`,payload).then((response) => {
    return response;
  });
};
const clientService = {
  getClientId,
  registerUser,
  getAllClients,
  getClientById,
  ApproveOrDeclinedClient,
  declinedClient,
  getClientDetailsById,
  getBrandCategories,
  brandAvailability,
  updateClient,
  updateClientCapability,
  createUpdateClientTheme,
  getClientThemeDetails,
  checkBrandWelUrlAvailibility,
  updateClientPriceConfigurations
};

export default clientService;
