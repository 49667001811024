import React, { useEffect, useState } from "react";
import * as fcl from "@onflow/fcl";

import MenuItem from "./ui-components/nav-ui-components/MenuItem";
import { menuItems, menuItemsAdmin } from "./../constants/nav-sidebar-menu-constants/nav-sidbar-menu-constants";
import { images } from "../constants/images";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../redux/features/user/userSlice";
import { reset_menuItems } from "../redux/features/menu/menuItemsSlice";
import { setMenuSelected, setOpenSubMenu, setSubMenuSelected } from "../redux/features/sidebar-menu/sidebarMenuSlice";
import { useLocation } from 'react-router';
import Footer from "./Footer";

const Sidebar = () => {
    const dispatch = useDispatch();
    const location = useLocation()
    const {pathname} = location || {}
  const role = useSelector((state) => state.user.userRole);

  useEffect(() => {
   
    if (pathname === "/client-dashboard") {
     
        dispatch(setMenuSelected(4))
        dispatch(setSubMenuSelected(0))
        handleSubMenu("")
    }
    //eslint-disable-next-line
  }, []);

  
  const handleSubMenu = (submenu) => {
    dispatch(setOpenSubMenu(submenu))
    
  };

  

  const handleLogout = async () => {
    dispatch(logout());
    dispatch(setMenuSelected(0))
    dispatch(setSubMenuSelected(0))
    handleSubMenu("")
    dispatch(reset_menuItems(6));
     fcl.unauthenticate();
  };

  return (
    <div className="flex flex-col flex-shrink-0 min-h-screen antialiased text-gray-800 bg-[#1E1E1E]">
      <div className="rounded-r-2xl  fixed top-0 left-0 flex flex-col w-[245px] h-full bg-[#12221A] shadow-[6px_0px_5px_0px_#0000005C] bg-[url('/src/assets/images/icons/sidebar-bg.svg')] bg-bottom bg-no-repeat bg-contain">
        <div className="flex items-center justify-start mb-4 ml-6 h-14">
          <img
            className="mt-[14px] "
            src={images.BloomlyLogo}
            alt="logo"
          />
        </div>

        <nav className="relative flex-grow overflow-x-hidden overflow-y-auto">
          <ul className="relative flex flex-col pb-4 mr-8 space-y-1 ">
            {role === "Super Admin" && (
              <>
              {menuItemsAdmin.map((menuItem, index) => (
                 <MenuItem
                 menuItem={menuItem}
                 key={index}

               />
              ))}
              </>
            )}
            {role === "Client" && (
              <>
                {menuItems.map((menuItem, index) => (
                  <MenuItem
                    menuItem={menuItem}
                    key={index}
                    handleSubMenu={handleSubMenu}
                   // openSubMenu={openSubMenu}
                  />
                ))}
              </>
            )}

           
          </ul>
           {/* LOGOUT */}
          
              <Link
                to="/login"
                onClick={handleLogout}
                className="absolute bottom-4  flex flex-grow items-center py-3 px-6 w-4/5 	hover:bg-[#A5F33C]/10 cursor-pointer"
              >
                <span className="z-10 ">
                  <img src={images.LogoutIcon} alt="logout" />
                </span>
                <span className="z-10 ml-4 text-sm tracking-wide text-white truncate">
                  Logout
                </span>
              </Link>
           
        </nav>
       
        
        
      </div>
      
     
    </div>
  );
};

export default Sidebar;
