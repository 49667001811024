import './config';
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-tailwind/react";

import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { SetupInterceptors } from './utils/api/apiClient';
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { SetupTransactionInterceptors } from './services/transaction.service';
import { SetupInterceptorsIdentity } from './utils/api/apiClientIdentityServer';

const apolloClient = new ApolloClient({
  uri: "https://arweave.net/graphql",
  cache: new InMemoryCache()
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <ApolloProvider client={apolloClient}>
            <App />
          </ApolloProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
SetupInterceptors(store);
SetupTransactionInterceptors(store);
SetupInterceptorsIdentity(store)
