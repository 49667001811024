import DateFormat from "../../helpers/DateFormat";
import mediaExtractor from "../../helpers/MediaDataExtracterInTable";
import walletAddressShorten from "../../helpers/WalletAddressTruncate";
import { Tooltip } from "@material-tailwind/react";

export const COLUMNS = [
  {
    Header: "Media",
    Footer: "Media",
    accessor: "assetVariationObject",
    Cell: ({ row }) => <>{mediaExtractor(row?.values?.assetVariationObject)}</>,
  },
  {
    Header: "Asset Name",
    Footer: "Asset Name",
    accessor: "assetName",
    Cell: ({ row }) => (
      console.log(row.values.purchaserEmail),
      (
        <p>
          {row?.values?.assetName?.length > 12 ? (
            <Tooltip
              className="text-[white] text-xs bg-[#12221A]   border border-[#12221A] rounded"
              offset={10}
              placement={"top-start"}
              content={row?.values?.assetName}
            >
              <p className="">{row?.values?.assetName?.substring(0, 17)} ...</p>
            </Tooltip>
          ) : (
            "is there"
          )}
        </p>
      )
    ),
  },
  {
    Header: "Drop Name",
    Footer: "Drop Name",
    accessor: "dropName",
    Cell: ({ row }) => (
      <p>
        {row?.values?.dropName?.length > 12 ? (
          <Tooltip
            className="text-[white] text-xs bg-[#12221A]   border border-[#12221A] rounded"
            offset={10}
            placement={"top-start"}
            content={row?.values?.dropName}
          >
            <p className="">{row?.values?.dropName?.substring(0, 17)} ...</p>
          </Tooltip>
        ) : (
          "is there"
        )}
      </p>
    ),
  },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "purchaserEmail",
    Cell: ({ row }) => (
      <p>
        {row?.values?.purchaserEmail?.length > 12 ? (
          <Tooltip
            className="text-[white] text-xs bg-[#12221A]   border border-[#12221A] rounded"
            offset={10}
            placement={"top-start"}
            content={row?.values?.purchaserEmail}
          >
            <p className="">
              {row?.values?.purchaserEmail?.substring(0, 17)} ...
            </p>
          </Tooltip>
        ) : (
          "is there"
        )}
      </p>
    ),
  },
  {
    Header: "Wallet Address",
    Footer: "Wallet Address",
    accessor: "walletAddress",
    Cell: ({ row }) => (
      <p>{walletAddressShorten(row?.values?.walletAddress)}</p>
    ),
  },

  {
    Header: "Items Claimed",
    Footer: "Items Claimed",
    accessor: "itemsClaimedPerTranx",
  },
  {
    Header: "Claim Date",
    Footer: "Claim Date",
    accessor: "claimDate",
    Cell: ({ row }) => (
      <>
        <DateFormat date={row?.values?.claimDate} />
      </>
    ),
  },
  {
    Header: "Transaction",
    Footer: "Transaction",
    accessor: "transactionHash",
    Cell: ({ row }) => (
      <a
        className="underline"
        href={
          process.env.REACT_APP_ACCESS_NODE?.includes("testnet")
            ? `${process.env.REACT_APP_FLOW_TESTNET_STORAGE_TRANSACTION_LINK}${row?.values?.transactionHash}`
            : `${process.env.REACT_APP_FLOW_MAINNET_STORAGE_TRANSACTION_LINK}${row?.values?.transactionHash}`
        }
        
        target="_blank"
        rel="noreferrer"
      >
        {" "}
        View Trx
      </a>
    ),
  },
];
