import React from "react";

const KeyValueView = (props) => {
  const { title, value, success, path, style, media, mainStyle, minWidth } =
    props;

  return (
    <>
      <div className={`${minWidth ? minWidth : "min-w-[170px]"}  ${mainStyle}`}>
        <p className="text-xs text-grey-100">
          {title}{" "}
          {media && (
            <span>
              <img className="inline ml-2" src={media} alt="icon" />
            </span>
          )}{" "}
        </p>
        {path ? (
          <>
            <a
              className={`${style ?? ""} text-base`}
              href={`https://${value}`}
              target="_blank"
            >
              {value}
            </a>{" "}
          </>
        ) : (
          <>
            <p
              className={`text-base ${
                success && "text-[#B0E577] font-semibold "
              }`}
            >
              {value}
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default KeyValueView;
