const walletAddressShorten = (wallet_addr) => {
	if (wallet_addr?.length > 5) {
		return (
			wallet_addr?.substr(0, 6) +
			'...' +
			wallet_addr?.substr(wallet_addr?.length - 4, wallet_addr?.length)
		);
	} else {
		return wallet_addr;
	}
};

export default walletAddressShorten;
