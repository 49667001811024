import ArweaveService from "../../services/arweave.service";
import externalService from "../../services/external.service";

const ArweaveBalanceHook = () => {
    const getArweaveBalance = async () => {
        const feeResult = await ArweaveService.getArweaveBalance().then((result) => {
            if (result?.success) {
               return result.balance;
            }
            else
            return "";
        }).catch((error) => {
            return "";

        });
        return feeResult;
    };
    const getArweaveUSDBalance = async () => {
        const feeResult = await externalService.getCurrentArweaveUSDPrice().then((result) => {
            if (result?.USD) {
               return result.USD;
            }
            else
            return "";
        }).catch((error) => {
            return "";

        });
        return feeResult;
    };

    const getFlowUSDBalance = async () => {
        const feeResult = await externalService.getCurrentFlowUSDPrice().then((result) => {
            if (result?.USD) {
               return result.USD;
            }
            else
            return "";
        }).catch((error) => {
            return "";

        });
        return feeResult;
    };
    return {
        getArweaveBalance,
        getArweaveUSDBalance,
        getFlowUSDBalance
    };
};

export default ArweaveBalanceHook;
