import React from "react";
import { images } from "../../../../constants";
import PreviewCard from "../../PreviewCommonComponent/PreviewCard";

const PreviewDrop = () => {
  return (
    <>
      <div className="mt-28 max-md:mt-14 relative before:absolute before:w-full before:h-[calc(100%+80px)] before:-top-[80px] before:bg-center before:bg-[length:100%_100%] before:-z-[1]">
        <h1 className="text-5xl font-bold leading-tight text-center text-white max-lg:text-4xl tracking-little mb-9 max-md:text-3xl">
          Current <span className="">Drops</span>
        </h1>
        <div className="mb-11">
          <div className="relative max-w-[600px] m-auto w-full px-4">
            <img
              src={images.searchIcon}
              className="absolute top-3 left-8"
              alt="search_icon"
            />
            <input
              className="w-full py-3 pl-12 pr-4 text-white bg-transparent border border-solid rounded-full placeholder:text-gray-400"
              placeholder="Search here"
            />
          </div>
        </div>

        <div>
          {" "}
          {/* max-h-[500px] */}
          {/* SKELTON WHILE SEARCHING & LOADING */}
          <div className="grid grid-cols-4 gap-4 justify-items-center max-xl:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 "></div>
          {/* ACTUAL DATA FROM API */}
          <div className="grid grid-cols-4 gap-4 justify-items-center max-xl:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 ">
            {[1, 2, 3, 4]?.map((drop) => (
              <PreviewCard key={1} />
            ))}
          </div>
          {/* <div className="mt-4 mb-4 text-center">
            <button className="btn-secondary max-w-[200px]">Load More</button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default PreviewDrop;
