import axios from "axios";
import { setLoader } from "../../redux/features/loading/loaderSlice";
import useRefreshToken from "../../helpers/custom-hooks/useRefreshToken";

let configureStoreClientIdentityServer;
const SetupInterceptorsIdentity = (store) => {
  configureStoreClientIdentityServer = store;
}; 
const axiosClientIdentityServer = axios.create({
  baseURL: process.env.REACT_APP_API_URL_IDENTITY,
});

axiosClientIdentityServer.interceptors.request.use(
  function (config) {
    const token = sessionStorage?.getItem("persist:root") || [];
    const jwt = JSON.parse(token || null);
    const user = JSON.parse(jwt?.user || null) || [];

    config.headers.Authorization = user?.userInfo
      ? `Bearer ${user?.userInfo?.access_token}`
      : "";
    return config;
  },
  (error) => {
    return error;
  }
);

axiosClientIdentityServer.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { dispatch } = configureStoreClientIdentityServer;
    dispatch(setLoader(false));
    const originalConfig = error.config;
    if (error?.response?.status === 401 && !originalConfig?._retry) {
      originalConfig._retry = true;
      const { refreshToken } = useRefreshToken();
      await refreshToken(dispatch);
      return axiosClientIdentityServer(originalConfig);
    }
    return Promise.reject(error);
  }
);

export { axiosClientIdentityServer, SetupInterceptorsIdentity };
