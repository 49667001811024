// const API_URL = "http://localhost:8080/api/auth/";
import { postRequest } from "../utils/api/axiosMethods";
import {
	getRequestIdentity,
	postRequestIdentity,
	putRequestIdentity,
} from "../utils/api/axiosMethodsIdentityServer";

// const register = (username, email, password) => {
// 	return axios.post(API_URL + "signup", {
// 		username,
// 		email,
// 		password,
// 	});
// };

const registerIdentityUser = (firstname, lastname, email, clientId) => {
	let body = {
		FirstName: firstname,
		LastName: lastname,
		UserEmail: email,
		ClientId: clientId,
		UserRole: 2,
	};
	return postRequest("Auth/register-user", body).then((response) => {
		return response.data;
	});
};

const loginUser = (payload) => {
	let body = {
		grantType: "Password",
		email: payload.email,
		password: payload.password,
		clientId: 1234567,
		rememberMe: false,
	};
	return postRequestIdentity("Auth/login", body).then((response) => {
		// if (response.data.token?.accessToken) {
		// 	localStorage.setItem("user", JSON.stringify(response.data));
		// }
		return response;
	});
};

const changeClientPassword = (payload, headers) => {
	let options = {
		headers: { Authorization: `Bearer ${headers}` },
	};
	let body = {
		oldPassword: payload.data.oldPassword,
		password: payload.data.password,
		confirmPassword: payload.data.confirmPassword,
	};
	return putRequestIdentity("Auth/change_password", body, options).then(
		(response) => {
			return response;
		}
	);
};

const setClientPassword = (payload) => {
	
	let body = {
		userId: payload.params.userId,
		code: payload.params.code,
		email: payload.params.email,
		password: payload.params.password,
	};
	return putRequestIdentity("Auth/reset_password", body).then((response) => {
		return response;
	});
};

const forgotClientPassword = (payload) => {
	let body = {
		email: payload.email,
	};
	return postRequestIdentity("Auth/forgot_password", body).then((response) => {
		return response;
	});
};

const refreshAccessToken = (refresh_token, userId) => {
	let body = {
		GrantType: "RefreshToken",
		RefreshToken: refresh_token,
		userUuId: userId,
	};
	return postRequestIdentity("Auth/get_new_access_token", body).then(
		(response) => {
			return response;
		}
	);
};

// const login = (email, password) => {
// 	return axiosClientIdentityServer
// 		.post("Auth/login", {
// 			email,
// 			password,
// 		})
// 		.then((response) => {
// 			if (response.data.accessToken) {
// 				localStorage.setItem("user", JSON.stringify(response.data));
// 			}

// 			return response.data;
// 		});
// };


const checkClientAlreadyExist = (email) => {
	let userEmail={
		email:email
	}

	return postRequestIdentity(`Auth/find_user_by_email`,userEmail).then((response) => {
		// if (response.data.token?.accessToken) {
		// 	localStorage.setItem("user", JSON.stringify(response.data));
		// }
		return response;
	});
};

const authService = {
	registerIdentityUser,
	setClientPassword,
	forgotClientPassword,
	loginUser,
	changeClientPassword,
	refreshAccessToken,
	checkClientAlreadyExist
};

export default authService;
