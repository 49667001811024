import { Radio } from "@material-tailwind/react";
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import SwitchComponent from "../../../../../../components/ui-components/switch-component/SwitchComponent";
import { images } from "../../../../../../constants";
import AddRoyaltyTextFieldComponent from "./AddRoyaltyTextFieldComponent";
import { RoyaltySchema } from "../../../../../../utils/ValidationSchemas/AddRoyalitySchema";
import DisableIconView from "../../../../../../components/ui-components/views-component/DisableIconView";
import ImageWithDisableIcon from "../../../../../../components/ui-components/views-component/ImageWithDisableIcon";
import KeyValueRoyaltyView from "../../../../../../components/ui-components/views-component/KeyValueRoyaltyView";
import SwitchFieldComponent from "../../../../../../components/ui-components/switch-component/SwitchFieldComponent";
import KeyValueView from "../../../../../../components/ui-components/views-component/KeyValueView";
import { RoyaltyAssetSchema } from "../../../../../../utils/ValidationSchemas/AddRoyaltyAssetSchema";
import { Tooltip } from "flowbite-react";
const EditionComponent = (props) => {
  const {
    EditionInputChangeHandler,
    editionValueMinusHandler,
    editionValue,
    seteditionValue,
    EditionMaxRef,
    editionValuePlusHandler,
    checkMaxSupplyFlag,
    setCheckMaxSupplyFlag,
    AssetCreationHandler,
    setActiveStep,
    setSteps,
    steps,
    addRoyalty,
    setAddRoyalty,
    transferable,
    settransferable,
    addMaxSupply,
    setAddMaxSupply,
    setAddedRoyaltyData,
    addedRoyaltyData,
  } = props;

  const navigate = useNavigate();
  console.log("Aa", addedRoyaltyData);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    unregister,
    clearErrors,
    getValues,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm({ resolver: yupResolver(RoyaltyAssetSchema), mode: "onChange" });

  const handleChange = () => {
    setCheckMaxSupplyFlag(!checkMaxSupplyFlag);
  };
  console.log("aa", addedRoyaltyData);

  const handleRoyaltyData = (values, e) => {
    e.preventDefault();
    // setRoyaltyData(values)

    if (addRoyalty) {
      const filterAddedRoyalty = addedRoyaltyData?.filter(
        (item) => item.royaltyActive === true
      );
      const newAddedRoyalty = values?.royalty.filter(
        (item) => item.royaltyActive === true
      );
      const royalityArray = [...filterAddedRoyalty, ...newAddedRoyalty];
      const royalDataObject = {};

      royalityArray.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (!royalDataObject[key]) {
            royalDataObject[key] = [];
          }
          royalDataObject[key].push(item[key]);
        });
      });
      AssetCreationHandler(royalDataObject);
    } else {
      AssetCreationHandler();
    }
  };
  const handleChangeRoyality = (e) => {
    setAddRoyalty(!addRoyalty);
  };

  const handleSetMaxSupply = () => {
    seteditionValue(0);
    setAddMaxSupply(!addMaxSupply);
  };
  const handleTransferable = () => {
    settransferable(!transferable);
  };
  const handleShowAlreadyAddedRoyalty = (e, fieldIndex) => {
    setAddedRoyaltyData(
      addedRoyaltyData.map((item, index) =>
        index === fieldIndex
          ? { ...item, royaltyActive: e.target.checked }
          : item
      )
    );
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(handleRoyaltyData)}
        className=" h-[calc(100vh-272px)] relative"
      >
        <div className="container relative z-20 grid h-full grid-cols-2 overflow-y-scroll ">
          <div className=" columns-md max-w-[494px]">
            <h2 className="mb-1 text-2xl font-semibold">Edition</h2>
            <p className="mb-6 text-xs text-grey-100">
              You can set a max supply for your asset or set unlimited supply
            </p>
            <div className="flex items-center mb-8">
              <div className="flex items-center now-counter">
                <span className="mr-8"> Max Supply</span>

                <img
                  src={images.MinusIcon}
                  onClick={editionValueMinusHandler}
                  className={`cursor-pointer inline-block p-[2px]  ${
                    addMaxSupply
                      ? "bg-[#557C1E]  pointer-events-none"
                      : "bg-[#A5F33C]"
                  }   rounded-[4px] `}
                  alt="icon"
                />

                <div className="input-wrap">
                  <input
                    className={`disabled:text-[#BDBDBD] text-center form-control no-icon bg-black border-none max-w-[80px] px-[12px] py-0 focus:bg-transparent focus:ring-0`}
                    type="text"
                    disabled={addMaxSupply}
                    ref={EditionMaxRef}
                    id="EditionsMaxValue"
                    name="EditionsMaxValue"
                    value={editionValue}
                    pattern="[0-9]*"
                    onFocus={() => {
                      seteditionValue(
                        EditionMaxRef.current === (0 || "") ? 1 : editionValue
                      );
                    }}
                    onBlur={() => {
                      seteditionValue(
                        EditionMaxRef.current === (0 || "") ? 1 : editionValue
                      );
                    }}
                    onInput={EditionInputChangeHandler}
                  />
                </div>

                <img
                  onClick={editionValuePlusHandler}
                  className={`inline-block p-[2px] ${
                    addMaxSupply
                      ? "bg-[#557C1E] pointer-events-none "
                      : "bg-[#A5F33C]"
                  } rounded-[4px] cursor-pointer`}
                  src={images.PlusIcon}
                  alt="icon"
                />
              </div>
              <span className="mx-6">|</span>
              <div className="grow">
                <span className="text-base ">Unlimited </span>
              </div>
              <div className="pr-2">
                <Tooltip
                  className="text-[white] text-xs bg-[#12221A]  border border-[#12221A]  relative z-30 rounded max-w-[200px]"
                  offset={10}
                  placement={"top-center"}
                  content={
                    "Your asset will never run out of mints as long as it’s on the storefront"
                  }
                >
                  <img
                    className="inline ml-2 align-top"
                    src={images.InfoIcon}
                    alt="icon"
                  />{" "}
                </Tooltip>
              </div>

              <SwitchComponent
                onChange={handleSetMaxSupply}
                value={addMaxSupply}
                id={"maxSupply"}
              />
            </div>
            {/** transferable and non transferable field */}
            {/* <div className="flex justify-between mb-1">
							<h4 className="text-xl font-bold text-white">Non-transferable</h4>

							<SwitchComponent
								onChange={handleTransferable}
								value={transferable}
								id={'transferable'}
							/>
						</div> */}
            {/* <p className="mb-8 text-xs text-grey-100">
              Lorem ipsum dolor sit amet consectetur. Lectus leo sed. Lorem
              ipsum dolor sit amet consectet{" "}
            </p> */}
            {/* <div>
              {!transferable && (
                <>
                  <div className="flex justify-between mb-1">
                    <h4 className="text-2xl font-bold text-white">
                      Set Royalty
                    </h4>

                    <SwitchComponent
                      onChange={handleChangeRoyality}
                      value={addRoyalty}
                      id={"openroyalty"}
                    />
                  </div>

                  <div className="mb-6">
                    <p className="text-xs text-grey-100">
                      Lorem ipsum dolor sit amet consectetur. Lectus leo sed.
                      Lorem ipsum dolor sit amet consectet{" "}
                    </p>
                  </div>
                </>
              )}
              {!transferable && addRoyalty && (
                <>
                  {addedRoyaltyData?.length > 0 && (
                    <>
                      {addedRoyaltyData?.map((item, index) => (
                        <>
                          <div className="relative px-4 py-4 pt-4 bg-[#12221A]/30 border-[#A4B1A7]/25 form-group border  rounded-lg mb-8">
                            <div className="absolute z-20 right-4 top-4">
                              <SwitchComponent
                                onChange={handleShowAlreadyAddedRoyalty}
                                value={item?.royaltyActive}
                                id={`showAddedRoyalty${index}`}
                                fieldIndex={index}
                              />
                            </div>

                            {item?.royaltyActive ? (
                              <>
                                <div className="relative mb-6">
                                  <KeyValueView
                                    title={"Name"}
                                    value={item?.name}
                                  />
                                </div>
                                <div className="flex items-center mb-6">
                                  <KeyValueView
                                    mainStyle={"mr-6"}
                                    title={"Receiver Address"}
                                    value={item?.receiverAddress}
                                  />
                                  <KeyValueView
                                    title={"Royalty(%)"}
                                    value={item?.royaltyPercentage}
                                  />
                                </div>
                                <KeyValueView
                                  title={"Description "}
                                  value={item?.description}
                                />
                              </>
                            ) : (
                              <>
                                <div className="flex items-center justify-between mr-20">
                                  <KeyValueRoyaltyView
                                    title={"Name"}
                                    value={item?.name}
                                  />
                                  <KeyValueRoyaltyView
                                    title={"Receiver Address"}
                                    value={item?.receiverAddress}
                                  />
                                  <KeyValueRoyaltyView
                                    title={"Royalty(%)"}
                                    value={item?.royaltyPercentage}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ))}
                    </>
                  )}
                </>
              )}

              {!transferable && addRoyalty && (
                <>
                  <div>
                    <AddRoyaltyTextFieldComponent
                      setActiveStep={setActiveStep}
                      AssetCreationHandler={AssetCreationHandler}
                      register={register}
                      handleSubmit={handleSubmit}
                      reset={reset}
                      setValue={setValue}
                      unregister={unregister}
                      clearErrors={clearErrors}
                      errors={errors}
                      getValues={getValues}
                      watch={watch}
                    />
                  </div>
                </>
              )}
            </div> */}
          </div>
        </div>

        {/* {!addRoyalty && (
          <div className="absolute -bottom-5 bg-[#060C07] flex justify-between items-center w-full  z-50">
            <div className="flex justify-between w-full  max-w-[494px]">
              <button
                className="btn-secondary max-w-[227px] text-center"
                onClick={() => setActiveStep(2)}
              >
                Back
              </button>
              <button
                disabled={editionValue === ""}
                className="btn-primary max-w-[227px] text-center disabled:bg-blue-800 disabled:text-opacity-60 disabled:cursor-not-allowed "
                onClick={() => AssetCreationHandler()}
              >
                Submit
              </button>
            </div>

            <div className={`flex justify-end items-end`}>
              <span
                className="text-base cursor-pointer"
                onClick={() => navigate("/asset-list")}
              >
                Cancel
              </span>
            </div>
          </div>
        )} */}

        <>
          <div className="absolute -bottom-5 bg-[#060C07] flex justify-between items-center w-full  z-50">
            <div className="flex justify-between w-full max-w-[494px]">
              <button
                className="btn-secondary max-w-[227px] text-center mr-10"
                onClick={() => setActiveStep(2)}
              >
                Back
              </button>
              <button className="btn-primary" type="submit">
                {" "}
                Submit
              </button>
            </div>
            <div className={`flex justify-end items-end`}>
              <span
                className="text-base px-10 py-3 font-bold cursor-pointer hover:bg-[#A5F33C] hover:text-[#12221A] hover:rounded-full mr-10"
                onClick={() => navigate("/asset-list")}
              >
                Cancel
              </span>
            </div>
          </div>
        </>
      </form>
    </>
  );
};

export default EditionComponent;
