import React from "react";
import { Link } from "react-router-dom";

const BrandThemeCheckbox = (props) => {
  const { value, media, isChecked, onChange, style, disabled, themeName } =
    props;

  return (
    <>
      <div className={`relative d-inherit group ${style}`}>
        <label
          className={`  relative p-4 bg-[#060C07] ${
            disabled &&
            "hover:cursor-not-allowed opacity-[0.7] pointer-events-none "
          } border cursor-pointer rounded-xl custom-checkbox ${
            isChecked === value && value != 2
              ? "border-[#A5F33C]"
              : "border-[#A4B1A7]/25 "
          }  `}
        >
          <h3
            className={`text-white text-sm mb-2 text-center ${
              disabled &&
              "hover:cursor-not-allowed opacity-[0.5] pointer-events-none "
            }`}
          >
            {themeName}
          </h3>
          <input
            checked={isChecked === value && value != 2}
            onChange={onChange}
            type={"checkbox"}
            className={`${
              disabled && "cursor-not-allowed"
            } absolute -left-2 -top-3  bg-[#060C07] bg-no-repeat bg-center bg-[url('/src/assets/images/icons/radio-uncheck-icon.svg')]  ring-offset-0 cursor-pointer  focus:ring-offset-0 ring-transparent focus:ring-transparent  checked:border-blue checked:bg-[url('/src/assets/images/icons/radio-checked-icon.svg')] checked:bg-auto checked:bg-[#A5F33C]  rounded-full w-6 h-6 border-0 border-[#94A3B8] mb-2`}
          />

          <div
            className={`flex justify-center ${
              disabled && "cursor-not-allowed"
            }  `}
          >
            <img
              className=" max-h-[350px]  object-contain "
              src={media}
              alt="icon"
            />
          </div>
        </label>
        {value === 2 ? (
          <>
            <span className="group-hover:visible  bg-[#A5F33C] text-[#12221A]   invisible z-20 left-[50%] text-xs font-bold text-center whitespace-nowrap  -translate-x-[50%]  max-w-[130px] px-6 py-1 rounded-full  absolute -bottom-3">
              Coming soon
            </span>
          </>
        ) : (
          <>
            <Link
              to="/storefront-demo1"
              target={"_blank"}
              className={`group-hover:visible  bg-[#A5F33C] text-[#12221A] invisible z-20 left-[50%] text-xs font-bold text-center whitespace-nowrap  -translate-x-[50%]  max-w-[130px] px-6 py-1 rounded-full  absolute -bottom-3`}
            >
              View Demo
            </Link>
          </>
        )}
      </div>
    </>
  );
};

export default BrandThemeCheckbox;
