const getPostFixUrl = (url) => {
  if (window?.location?.host?.includes("dev")) {
    return ".troontech.com"
  }
  else {
    if (url.split(/(?:^[^\.]+)\./)[1]) {
      return `.${url.split(/(?:^[^\.]+)\./)[1]}`;
    } else {
      return "";
    }
  }
};

export { getPostFixUrl };
