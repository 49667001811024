import * as yup from "yup";

export const addClientSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(20, "Max  length 20 characters"),
  lastName: yup
    .string()
    .required("Last name is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(20, "Max  length 20 characters"),
  email: yup
    .string()
    .email("Invalid email")
    .required("Email is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(100, "Max  length 100 characters"),
  Notes: yup.string().max(500, "Max  length 500 characters"),
  package: yup.number().required("Please select package"),

  addPercent: yup.boolean(),
  platformFee: yup.number().when("addPercent", {
    is: true,
    then: yup
      .number()
      //.integer("Enter a number without decimal value")
      .min(0, "Number must be greater or equal to 0")
      .max(100, "Number must not be greater than 100")
      .typeError("Please enter platformFee Fee in number")
      // .positive("Number must be positive and greater than zero")
      .required(" Please enter platformFee Fee in number ")
      .default(0),
    otherwise: yup.number(),
  }),
});

// yup validation schema for number not more than 100
