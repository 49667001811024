import React from "react";
import avatar from "./../../assets/images/avatar.png";

const BasicInfo = (props) => {
  const { clientData } = props;

  return (
    <div className="text-white">
      <h1 className="mb-1 text-3xl font-bold">Basic Info</h1>
      <p className="mb-6 text-xs text-grey-100">
      Basic information provided by the client.
      </p>

      {/* <img
        src={avatar}
        className="object-cover w-24 h-24 mb-4 bg-gray-500 border-4 border-white rounded-full"
        alt="user_img"
      /> */}
      <div className="flex items-center justify-start mb-4">
        <div className="flex flex-col mr-10">
          <span className="text-base text-grey-100">First Name</span>
          <span className="text-base">
            {clientData?.contactFirstName ?? "-"}
          </span>
        </div>
        <div className="flex flex-col mr-10">
          <span className="text-base text-grey-100">Last Name</span>
          <span className="text-base">
            {clientData?.contactLastName ?? "-"}
          </span>
        </div>
      </div>
      <div>
        <div className="flex flex-col mb-4">
          <span className="text-base text-grey-100">Email</span>
          <span className="text-base">{clientData?.contactEmail ?? "-"}</span>
        </div>
      </div>  
      {clientData?.clientNotes && (
        <div>
          <div className="flex flex-col">
            <span className="text-base text-grey-100">Notes</span>
            <span className="text-base">{clientData?.clientNotes ?? "-"}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default BasicInfo;
