import React, { useMemo, useState } from "react";
import SearchFilter from "../../../../components/SearchFilter";
import ClientHeaderComponent from "./../../components/ClientHeaderComponent";
import BasicTable from "../../../../components/BasicTable";
import { COLUMNS } from "./assetColumn";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { setLoader } from "../../../../redux/features/loading/loaderSlice";
import BlockchainScriptHook from "../../../../helpers/custom-hooks/BlockchainScriptHook";
import Transactions from "../../../../Blockchain-transactions/BlockchainTransactions";
import AssetService from "../../../../services/asset.service";
import { images } from "../../../../constants";
import { useDispatch } from "react-redux";
import Modal from "../../../../common/ModalLayout";
import AlertModalContent from "../../../../components/Modals/AlertModalContent";
import { Menu, MenuHandler, MenuList, Tooltip } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import MatchApproveAndConnectWalletHook from "../../../../helpers/custom-hooks/MatchApproveAndConnectWalletHook";
import BlockChainErrorsHandler from "../../../../helpers/BlockChainErrorsHandler";
import InvalidWalletContent from "../../../../components/Modals/InvalidWalletContent";
import DropService from "../../../../services/drop.services";

const AssetContent = (props) => {
  const {
    createAssetHandler,
    setUpdateAssetList,
    assetListData,
    clientWalletAddress,
    clientPackageResponse,
  } = props;
  const dispatch = useDispatch();
  const [OpenAlertModal, setOpenAlertModal] = useState(false);
  const [alertContent, setAlertContent] = useState({
    title: "",
    description: "",
    path: "",
  });
  const [openInvalidWalletModal, setOpenInvalidWalletModal] = useState(false);
  const { matchConnectedWallet, disconnetWallet } =
    MatchApproveAndConnectWalletHook();

  const tableHooks = (hooks) => {
    const { getBlockChainScriptFromDB } = BlockchainScriptHook();
    /** Delete click handler */
    const deleteAssetHandler = async (templateId, row) => {
      dispatch(setLoader(false));
      let templateIdsFromDB = await DropService.getDropAssetIds();
      if (templateIdsFromDB?.data?.payload?.length > 0) {
        const assetNotUsedInDrop = templateIdsFromDB?.data?.payload?.includes(
          parseInt(templateId)
        );
        if(assetNotUsedInDrop){
          const matchWalletResponse = await matchConnectedWallet(
            clientWalletAddress
          );
          if (matchWalletResponse) {
            const modal = { scriptName: 9 };
            const deleteScript = await getBlockChainScriptFromDB(modal);
            const executeTx = await Transactions.DeleteAssetTransaction(
              deleteScript,
              templateId
            );
            if (!executeTx?.error) {
              let modal = {
                Assetid: templateId,
                BCTransactionId: executeTx?.message?.txId,
              };
              AssetService.deleteAssetFromDB(modal)
                .then((result) => {
                  dispatch(setLoader(false));
                  setUpdateAssetList(true);
                  if (result?.data?.payload) {
                    setOpenAlertModal(true);
                    setAlertContent({
                      title: "Success",
                      description: "Asset Deleted Successfully ",
                    });
                  }
                })
                .catch((error) => {
                  dispatch(setLoader(false));
                  setOpenAlertModal(true);
                  setAlertContent({
                    title: "Error",
                    description: "Failed to delete asset from DB ",
                  });
                  setUpdateAssetList(true);
                });
            } else {
              dispatch(setLoader(false));
              setOpenAlertModal(true);
              let defaultErrMsg = "Failed to delete asset from BlockChain ";
              let errDescription = BlockChainErrorsHandler(
                executeTx,
                defaultErrMsg
              );
              setAlertContent({
                title: "Error",
                description: errDescription,
              });
            }
          } else {
            if (matchWalletResponse === null) {
              dispatch(setLoader(false));
            } else {
              dispatch(setLoader(false));
              setOpenInvalidWalletModal(true);
    
              await disconnetWallet();
            }
          }

        }else{
          dispatch(setLoader(false));
          setOpenAlertModal(true);
          setAlertContent({
            title: "Error",
            description: "Asset can't be deleted as using in drop",
          });
          setUpdateAssetList(true);
        }

        
      
      }else{
        dispatch(setLoader(false));
        setOpenAlertModal(true);
        setAlertContent({
          title: "Error",
          description: "Something went wrong",
        });
        setUpdateAssetList(true);
      }
    

    
    };
    /** end of Delete click handler */

    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
              flexGrow: 1,
            }}
          >
            {" "}
            Action
          </div>
        ),
        accessor: "Actions",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="flex justify-center">
            <Menu className="" placement="left-start">
              <MenuHandler className="">
                <img
                  className="mr-1 text-center cursor-pointer hover:opacity-90 "
                  src={images.more_icon}
                  alt="icon"
                />
              </MenuHandler>
              <MenuList className="bg-[#060C07] flex flex-col min-w-[115px] py-4 px-0  z-20 border rounded-lg border-[#A4B1A7]/25">
                <Link
                  to={"/create-asset"}
                  state={{ assetData: row?.original }}
                  className="inline-block  font-normal py-1 px-4 mb-4 text-base text-white  focus-visible:bg-[#12221A]/80 font-manrope active:bg-[#12221A]/80 active:text-white hover:bg-[#12221A]/80 hover:text-[#FFFFFF] outline-0"
                >
                  Update
                </Link>
                <Link
                  onClick={() => {
                    deleteAssetHandler(
                      row?.original?.templateId,
                      row?.original
                    );
                  }}
                  className="inline-block  py-1 px-4 text-base text-white font-normal   focus-visible:bg-[#12221A]/80 font-manrope  active:bg-[#12221A]/80  active:text-white hover:bg-[#12221A]/80 hover:text-white hover:border-[#12221A] focus-visible:ring-offset-0 outline-0"
                >
                  Delete
                </Link>
              </MenuList>
            </Menu>
          </div>
        ),
      },
    ]);
  };

  // React table suggests to memoize the columns and data for avoiding the re creation of data on every render
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => assetListData, [assetListData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    gotoPage,
    pageCount,
    state, // For filtering
    setGlobalFilter, // For filtering
  } = useTable(
    {
      // ES6 shorthand notation having same key value name
      columns,
      data,
    },
    tableHooks,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex } = state;

  return (
    <>
      <ClientHeaderComponent title={"Asset List"} />
      {/* Heading and button */}
      <div className="flex items-center justify-between mb-8">
        <div className="max-w-[500px] w-full">
          <SearchFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
        <Tooltip
          open={
            assetListData?.length >= clientPackageResponse?.defaultNFtLimit
              ? true
              : false
          }
          className={`text-[white]  text-xs bg-[#12221A]   border border-[#12221A] rounded`}
          offset={10}
          placement={"bottom-center"}
          content={"Already reach you limit"}
        >
          <button
            disabled={
              assetListData?.length >= clientPackageResponse?.defaultNFtLimit
            }
            onClick={createAssetHandler}
            type="button"
            data-modal-toggle="Add_Modal"
            className="max-w-[202px]  btn-primary rounded-full "
          >
            Create Asset
          </button>
        </Tooltip>
        {/* <button
            onClick={createAssetHandler}
            type="button"
            data-modal-toggle="Add_Modal"
            className="max-w-[202px]  btn-primary rounded-full "
          >
            Create Asset
          </button> */}
      </div>
      <div>
        <BasicTable
          columns={columns}
          data={data}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          page={page}
          nextPage={nextPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          prepareRow={prepareRow}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          pageCount={pageCount}
          rows={rows}
          defaultMessage={
            "You do not have any assets created at the moment. Click on create asset to start building your storefront. "
          }
        />
      </div>
      {/**  Asset listing table  */}

      {OpenAlertModal && (
        <Modal
          modalVisible={OpenAlertModal}
          closeModal={setOpenAlertModal}
          children={<AlertModalContent message={alertContent} />}
          modal_Id="Add_Modal"
          isClose={true}
        />
      )}
      {openInvalidWalletModal && (
        <Modal
          modalVisible={openInvalidWalletModal}
          closeModal={setOpenInvalidWalletModal}
          children={<InvalidWalletContent />}
          modal_Id="Delete_Modal"
          isClose={true}
        />
      )}
    </>
  );
};

export default AssetContent;