import { BlockPicker } from "react-color";
import { useState } from "react";
import Spinner from "../ui-components/loader-components/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import FileResizer from "react-image-file-resizer";
import clientService from "../../services/client.service";
import { setLoader } from "../../redux/features/loading/loaderSlice";
import { images } from "../../constants";
import ThemeInfoComponent from "../ui-components/ThemeInfoComponent/ThemeInfoComponent";
import ColorPickerInputField from "../ui-components/form-component/ColorPickerInputField";
import themeConstants from "../../constants/client-admin-constants/clientThemeConstants/ClientThemeConstant";
import AlertModalContent from "../Modals/AlertModalContent";
import Modal from "../../common/ModalLayout";
import { useRef } from "react";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaviconSchema } from "../../utils/ValidationSchemas/faviconIcon";

const ThemeCustomization = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(FaviconSchema),
    mode: "onChange",
  });
  const [backgroundColor, setBackgroundColor] = useState("#060C07");

  const [backgroundPrimaryColor, setBackgroundPrimaryColor] =
    useState("#A5F33C");

  const [textBodyColor, setTextBodyColor] = useState("#FFFFFF");
  const [OpenAlertModal, setOpenAlertModal] = useState(false);
  const [fileSize, setFileSize] = useState();

  const [textPrimaryColor, setTextPrimaryColor] = useState("#12221A");

  const [backgroundTertiaryColor, setBackgroundTertiaryColor] =
    useState("#12221A");

  const [textTertiaryColor, setTextTertiaryColor] = useState("#FFFFFF");
  const [textSecondaryColor, setTextSecondaryColor] = useState("#8D9D91");
  const [file, setFile] = useState("");

  const [loading, setLoading] = useState(false);
  const [showCroppingPopup, setshowCroppingPopup] = useState();

  const user = useSelector((state) => state.user);

  const [clientThemeDetail, setClientThemeDetail] = useState();

  const [alertContent, setAlertContent] = useState({
    title: "",

    description: "",
    path: "",
    sublabel: "",
  });
  const getClientThemeDetails = () => {
    dispatch(setLoader(true));
    try {
      clientService
        .getClientThemeDetails(
          user?.clientDetail?.id,
          user?.userInfo?.access_token
        )
        .then((res) => {
          // console.log('ressss', res);
          dispatch(setLoader(false));
          if (res) {
            setClientThemeDetail(res?.data?.payload);
            if (res?.data?.payload?.themeColorJson) {
              let themeColorScheme = JSON.parse(
                res?.data?.payload?.themeColorJson
              );
              setBackgroundColor(themeColorScheme.backgroundColor);
              setBackgroundPrimaryColor(
                themeColorScheme.backgroundPrimaryColor
              );
              setBackgroundTertiaryColor(
                themeColorScheme.backgroundTertiaryColor
              );
              setTextPrimaryColor(themeColorScheme.textPrimaryColor);
              setTextBodyColor(themeColorScheme.textBodyColor);
              setTextTertiaryColor(themeColorScheme.textTertiaryColor);
              setTextSecondaryColor(themeColorScheme.textSecondaryColor);
            }
            if (res?.data?.payload?.faviconImage) {
              setValue("isFavIconExist", true);
              setValue("favIconImg", res?.data?.payload?.faviconImage);
              setFile(res?.data?.payload?.faviconImage);
            }
          }
        })
        .catch((err) => {
          dispatch(setLoader(false));
        });
    } catch (err) {
      dispatch(setLoader(false));
      // console.log('err', err)
    }
  };

  const handleChange = (e) => {
    setFileSize((e.target.files[0]?.size / 1024).toFixed(2));
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleRemoveImage = () => {
    setFile("");
    reset({ favIconImg: "" });
  };

  useEffect(() => {
    getClientThemeDetails();
  }, []);

  const submitForm = (image) => {
    let themeColorScheme = {
      backgroundColor: backgroundColor,
      backgroundPrimaryColor: backgroundPrimaryColor,
      textPrimaryColor: textPrimaryColor,
      textBodyColor: textBodyColor,
      backgroundTertiaryColor: backgroundTertiaryColor,
      textTertiaryColor: textTertiaryColor,
      textSecondaryColor: textSecondaryColor,
    };
    let formData = new FormData();
    formData.append("clientThemeSteps", 5);
    formData.append(
      "ClientThemeUuid",
      clientThemeDetail?.clientThemeUuid
        ? clientThemeDetail?.clientThemeUuid
        : ""
    );
    formData.append("ClientId", user?.clientDetail?.id);
    formData.append("BrandName", user?.clientDetail?.clientName);
    formData.append("ThemeColorJson", JSON.stringify(themeColorScheme));
    formData.append("FaviconImage", image);

    setLoading(true);

    clientService
      .createUpdateClientTheme(formData)
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          if (res?.data?.success === true) {
            setOpenAlertModal(true);
            setTimeout(() => {
              setOpenAlertModal(false);
            }, 3000);
            setAlertContent({
              title: "Success",
              description: "Changes have been saved successfully.",
              sublabel: "Auto closing in 3 seconds...",
            });
            setClientThemeDetail(res?.data?.payload);
          } else {
            setOpenAlertModal(true);
            setTimeout(() => {
              setOpenAlertModal(false);
            }, 3000);
            setAlertContent({
              title: "Error",
              description: "Failed to save changes. Please try again.",
              sublabel: "Auto closing in 3 seconds...",
            });
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setLoading(true);
    setBackgroundColor("#060C07");
    setBackgroundPrimaryColor("#A5F33C");
    setBackgroundTertiaryColor("#12221A");
    setTextPrimaryColor("#12221A");
    setTextBodyColor("#FFFFFF");
    setTextTertiaryColor("#FFFFFF");
    setTextSecondaryColor("#8D9D91");
    setLoading(false);
  };
  const faviconSubmit = (values) => {
    submitForm(values.favIconImg[0]);
  };

  return (
    <>
      <div className="text-white">
        <h1 className="text-xl font-bold ">Brand Colors</h1>
        <div className="mt-6">
          <div className="grid grid-cols-3 gap-8">
            <div className="p-4 border border-[#A4B1A7]/25 rounded-lg bg-[#12221A]/30 max-w-[300px]">
              <div className="flex justify-between items-center mb-3">
                <span className="text-base text-white font-bold">
                  Primary Brand Color
                </span>
                <ThemeInfoComponent
                  themeImage={images.ThemePrimaryColorImage}
                  backgroundText={"Primary Color"}
                  colorText={"Button Text Color"}
                  title={"Primary Brand Color"}
                  desc={themeConstants.PRIMARY_COLOR_DESC}
                  sm={true}
                />
              </div>
              <p className="mb-1 text-grey-100 text-xs">Primary Color</p>
              <ColorPickerInputField
                value={backgroundPrimaryColor}
                handleChange={setBackgroundPrimaryColor}
              />
              <p className="mb-1 mt-4 text-grey-100 text-xs">
                Button Text Color
              </p>
              <ColorPickerInputField
                value={textPrimaryColor}
                handleChange={setTextPrimaryColor}
              />
            </div>

            <div className="p-4 border border-[#A4B1A7]/25 rounded-lg bg-[#12221A]/30 max-w-[300px]">
              <div className="flex justify-between items-center mb-3">
                <span className="text-base text-white font-bold">
                  Body Color
                </span>
                <ThemeInfoComponent
                  themeImage={images.ThemebodyPage}
                  backgroundText={"Background Color"}
                  colorText={"Body Text Color"}
                  title={"Body Color"}
                  desc={themeConstants.BODY_COLOR_DESC}
                  sm={true}
                />
              </div>
              <p className="mb-1 text-grey-100 text-xs">Background Color</p>
              <ColorPickerInputField
                value={backgroundColor}
                handleChange={setBackgroundColor}
              />
              <p className="mb-1 mt-4 text-grey-100 text-xs">Body Text Color</p>
              <ColorPickerInputField
                value={textBodyColor}
                handleChange={setTextBodyColor}
              />
            </div>

            <div className="p-4 border border-[#A4B1A7]/25 rounded-lg bg-[#12221A]/30 max-w-[300px]">
              <div className="flex justify-between items-center mb-3">
                <span className="text-base text-white font-bold">
                  Footer Color
                </span>
                <ThemeInfoComponent
                  themeImage={images.ThemeFooterImage}
                  backgroundText={"Footer Color"}
                  colorText={"Text Color"}
                  title={"Footer Color"}
                  desc={themeConstants.FOOTER_COLOR_DESC}
                  sm={true}
                />
              </div>
              <p className="mb-1 text-grey-100 text-xs">Background Color</p>
              <ColorPickerInputField
                value={backgroundTertiaryColor}
                handleChange={setBackgroundTertiaryColor}
              />
              <p className="mb-1 mt-4 text-grey-100 text-xs">Text Color</p>
              <ColorPickerInputField
                value={textTertiaryColor}
                handleChange={setTextTertiaryColor}
              />
            </div>

            <div className="p-4 border border-[#A4B1A7]/25 rounded-lg bg-[#12221A]/30 max-w-[300px]">
              <div className="flex justify-between items-center mb-3">
                <span className="text-base text-white font-bold">
                  Secondary Color
                </span>
                <ThemeInfoComponent
                  themeImage={images.ThemeSecondaryColorImage}
                  backgroundText={"Paragraph Color"}
                  colorText={""}
                  title={"Secondary Color"}
                  desc={themeConstants.SECONDARY_COLOR_DESC}
                  sm={true}
                />
              </div>
              <p className="mb-1 text-grey-100 text-xs">Paragraph Color</p>
              <ColorPickerInputField
                value={textSecondaryColor}
                handleChange={setTextSecondaryColor}
              />
            </div>
          </div>
          <div className="pt-3">
            <h1 className="text-xl font-bold">Fav Icon</h1>
            <p className="text-xs font-normal leading-4 text-grey-100 pt-2">
              Lorem ipsum dolor sit amet consectetur. Lectus leo sed. Lorem
              ipsum dolor sit amet consectet
            </p>
            {/* File Uploader */}
            <form action="" onSubmit={handleSubmit(faviconSubmit)}>
              <label
                htmlFor="inputFileSelector2"
                className="mb-2 cursor-pointer flex w-1/2"
              >
                {/* Image Selector */}
                <input
                  hidden={true}
                  id="inputFileSelector2"
                  accept={".jpg,.jpeg,.png"}
                  type="file"
                  name="favIconImg"
                  // className={inputFieldStyle}
                  {...register("favIconImg", {
                    onChange: (e) => {
                      handleChange(e);
                    },
                  })}
                />

                <div className="flex items-center justify-between text-white font-poppins border border-[#A4B1A7] p-4 border-grey-100-700 border-solid rounded-xl w-full min-h-[96px] h-full mt-5">
                  <div className="flex items-center justify-start flex-grow-1">
                    <img className="mr-3" src={images.UploadIcon} alt="" />
                    <div className="flex flex-col">
                      <h4 className="text-base font-bold">Upload Banner</h4>
                      <p className="mb-2 text-xs"> Browse Files</p>
                      <p className="text-xs text-grey-100">
                        PNG,JPG,JPEG | Max file size: 5 MB (16×16 px)
                      </p>
                    </div>
                  </div>
                  <p className="flex-shrink text-xs font-bold text-grey-100">
                    OR
                  </p>
                  <p className="flex-shrink text-base font-bold text-center text-[#A5F33C] ">
                    Browse
                  </p>
                </div>
              </label>

              {/* Image Displayer */}
              <div className="w-3/6">
                {file && (
                  <div className="flex justify-between items-center text-xs mt-8 flex-grow py-3 px-4 rounded-xl bg-transparent border-2 border-zinc-700 min-w-[80px]">
                    <div className="flex items-center">
                      <img
                        className="object-contain mr-2 w-7 h-7"
                        src={file || getValues("favIconImg")}
                        alt="logo"
                      />
                      <span className="w-full p-0 font-bold bg-transparent border-none focus:ring-transparent">
                        Favicon
                      </span>
                    </div>
                    {fileSize && (
                      <span className="text-grey-100">{fileSize + "kb"}</span>
                    )}
                    <img
                      className="cursor-pointer hover:opacity-80"
                      src={images.cross_icon}
                      alt="X"
                      onClick={handleRemoveImage}
                    />
                  </div>
                )}

                <p className="mt-1 text-xs text-red-600">
                  {errors?.favIconImg?.message}
                </p>
              </div>
              <div className="flex mt-10 mb-[30px] max-w-[350px]  ">
                <button
                  type="submit"
                  className={`btn-secondary mr-3 disabled:cursor-not-allowed w-fit px-12 `}
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                  onClick={handleReset}
                >
                  Reset
                </button>

                <button
                  // onClick={handleSubmit(submitForm)}
                  type="submit"
                  className={`btn-primary px-4 py-3  disabled:cursor-not-allowed w-fit `}
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                  disabled={loading}
                >
                  {loading && <Spinner />}
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <div className="flex mt-10 mb-[30px] max-w-[350px]  ">
        <button
          type="submit"
          className={`btn-secondary mr-3 disabled:cursor-not-allowed w-fit px-12 `}
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          onClick={handleReset}
        >
          Reset
        </button>

        <button
          onClick={handleSubmit(submitForm)}
          type="submit"
          className={`btn-primary px-4 py-3  disabled:cursor-not-allowed w-fit `}
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          disabled={loading}
        >
          {loading && <Spinner />}
          Save Changes
        </button>
      </div> */}
      {OpenAlertModal && (
        <Modal
          modalVisible={OpenAlertModal}
          closeModal={setOpenAlertModal}
          children={<AlertModalContent message={alertContent} />}
          modal_Id="Save_Modal"
          isClose={true}
        />
      )}
    </>
  );
};

export default ThemeCustomization;
