import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user/userSlice";
import menuItemsReducer from "./features/menu/menuItemsSlice";
import LoaderReducer from './features/loading/loaderSlice';
import SidebarReducer from './features/sidebar-menu/sidebarMenuSlice'
import storage from 'redux-persist/lib/storage/session'
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
	key: "root",
	storage,
	// blacklist: ["menuItems"],
};

const persistedReducer = persistReducer(
	persistConfig,
	combineReducers({ user: userReducer, menuItems: menuItemsReducer ,loader: LoaderReducer ,sidebar :SidebarReducer })
);

const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk],
});

const persistor = persistStore(store);
export { store, persistor };

// const store = configureStore({
// 	reducer: {
// 		user: userReducer,
// 	},
// });

// export default store;
