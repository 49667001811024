import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { images } from "../../../constants/images";
import {
  setMenuSelected,
  setSubMenuSelected,
} from "../../../redux/features/sidebar-menu/sidebarMenuSlice";

import SubMenuItem from "./SubMenuItem";

const MenuItem = (props) => {
  const dispatch = useDispatch();
  const { openSubMenu, menuSelected } = useSelector((state) => state.sidebar);

  const { menuItem, handleSubMenu } = props;

  return (
    <>
      <li
        className={`relative  pl-0  p-[1.4px] hover:bg-[#A5F33C]/10 
        ${
          menuSelected === menuItem.value
            ? "bg-[#A5F33C]/10 menu-selected-before menu-selected-after"
            : ""
        }	rounded-r-lg  hover:before:top-0 hover:before:left-0 hover:before:absolute hover:before:h-full hover:before:w-1 hover:before:bg-[#A5F33C] hover:before:rounded-r`}
        onClick={() => {
          if (menuItem.submenu) {
            if (openSubMenu === "") {
              dispatch(setMenuSelected(-1));
              return;
            }
          }
          dispatch(setMenuSelected(menuItem.value));
          //  setSelectedMenu(menuItem.value);
        }}
      >
        <Link
          to={menuItem.url}
          className="flex items-center flex-grow cursor-pointer"
        >
          <div
            className="inline-flex items-center flex-grow py-3 pl-5 "
            onClick={() => {
              if (menuItem.submenu) {
                if (openSubMenu === "") {
                  dispatch(setMenuSelected(-1));

                  handleSubMenu(menuItem.title);
                  dispatch(setSubMenuSelected(1));
                } else {
                  handleSubMenu("");
                  dispatch(setSubMenuSelected(0));
                }
              } else {
                handleSubMenu("");
                dispatch(setSubMenuSelected(0));
              }
            }}
          >
            <span className="z-10 ">
              <img
                src={
                  menuSelected === menuItem.value ||
                  openSubMenu === menuItem.title
                    ? menuItem.icon
                    : menuItem.inactiveIcon
                }
                alt="bloomly"
              />
            </span>
            <span
              className={`z-10 ml-4 text-sm tracking-wide  truncate ${
                menuSelected === menuItem.value ||
                openSubMenu === menuItem.title
                  ? "text-white font-bold"
                  : "text-[#8D9D91] font-normal"
              }`}
            >
              {menuItem.title}
            </span>
            {menuItem.submenu && (
              <>
                <span className="z-10 inline-flex items-center justify-center ml-6">
                  <img
                    src={
                      openSubMenu === menuItem.title
                        ? images.ExpandCloseIcon
                        : images.ExpandIcon
                    }
                    alt="bloomly"
                  />
                </span>
              </>
            )}
          </div>
        </Link>
      </li>

      {openSubMenu === menuItem.title && (
        <SubMenuItem
          submenuItems={menuItem.submenu}
          openSubMenu={openSubMenu}
          menuItem={menuItem}
        />
      )}
    </>
  );
};

export default MenuItem;
