import React from "react";

const InvalidWalletContent = () => {
  return (
    <>
      <div className="flex items-center">
        <h3
          className={` text-2xl font-bold text-red
        `}
        >
          Invalid wallet address detected
        </h3>
      </div>

      <p
        className={`w-full py-2  my-2  text-white bg-transparent `}
        rows="5"
      >
        Please ensure that the wallet you're connecting is the same one used in
        the KYB process. If the problem persists, please contact
        <a className="hover:text-[#A5F33C] ml-1 text-[#F7C644]" href='mailto:support@bloomly.com' target={'_blank'}>support@bloomly.xyz </a> 
      </p>
    </>
  );
};

export default InvalidWalletContent;
