import React from "react";

import { images } from './../../constants/images';

const BrandTheme = (props) => {
  const { clientData } = props;
  return (
    <div className="text-white">
      <h1 className="mb-1 text-3xl font-bold">Brand Theme</h1>
      <p className="mb-6 text-xs text-grey-100">
      Brand theme selected by the client.
      </p>
      {clientData?.themeId  ? (
        <div className="inline-block p-4 border rounded border-white/25">
        <img  className="max-w-[#157] max-h-[350px]" src={images.ThemeDark} alt="brand_theme" />
        </div>
       
      ) : (
        "-"
      )}
    </div>
  );
};

export default BrandTheme;
