import React from "react";

const StepperComponent = (props) => {
  const { steps, activeStep, } = props;
 
  // console.log(steps)
  return (
    <>
      <div className="container relative z-20 grid grid-cols-2 ">
        <div className="columns-md max-w-[496px] ">
          <div className="flex justify-between mb-8">
            {steps?.map((step, index) => (
              <div className="inline-flex items-center " key={index}>
             
                  <div
                     className={` ${activeStep === step.value ?   `text-white font-semibold  bg-[#A5F33C]/10   border-[#A5F33C]` :` ${step?.IsCompleted ? 'bg-[#A5F33C] border-[#A5F33C] text-[#060C07] font-bold' : 'border-[#4D6553] bg-[#213E28] text-[#8D9D91] font-normal'}  `}   w-[32px] h-[32px] flex justify-center px-3 py-1 text-base  border rounded-xl  `}
                  >
                    {step.value}
                  </div>
              
                <div
                  className={`ml-4 text-base   ${
                    activeStep === step.value ||  step?.IsCompleted
                      ? "text-white font-semibold"
                      : "text-[#8D9D91]"
                  } ${step?.IsCompleted ? 'text-white font-semibold' :''} `}
                >
                  {step.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default StepperComponent;
