import React from 'react'
import { images } from '../../constants'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/features/user/userSlice';
import authService from './../../services/auth.service';
import useLogoutHook from '../../helpers/custom-hooks/useLogoutHook';

const KybThankyouModal = ({ closeModal, stepsCompleted, isClientApproved }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleLogout } = useLogoutHook();

    return (
        <>
            <img src={images.history_clock_icon} alt="icon" />
            <h3 className="mt-4 mb-2 text-2xl font-bold text-white">
            Thank you for {isClientApproved ? 'updating' : 'submitting'}  your KYB form!  
            </h3>
            {!isClientApproved && stepsCompleted && (
                 <p className='mb-5 text-xs text-grey-100'>
                 Your request has been sent to the Bloomly team. Once approved, you will receive an email to begin building your storefront.
                 </p>
            )}
         
            {!isClientApproved && stepsCompleted &&
                <button className="text-center capitalize cursor-pointer btn-secondary"
                    onClick={() => {
                        handleLogout()
                        authService.logoutUser();
                        navigate("/login");
                    }
                    }>
                    Logout
                </button>}
            {isClientApproved &&
                <button className="text-center capitalize cursor-pointer btn-secondary"
                    onClick={() => {
                        navigate("/client-dashboard");
                    }
                    }>
                    Go to dashboard
                </button>}
            {/* <Link
                // onClick={closeModal}
                to="/client-dashboard"
                type="submit"
                className="text-center rounded-lg btn-primary">
                Finish Setup &amp; Go to Dashboard
            </Link> */}
        </>
    )
}

export default KybThankyouModal