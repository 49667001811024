import React from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { images } from '../../../constants';

const UserAvatar = ({ firstname, lastname, pic ,isClientApproved }) => {
  const navigate = useNavigate()

  // const { isClientApproved } = useSelector(state => state.user.clientDetail)

  return (
    <div className="flex items-center w-auto space-x-4">
      	{isClientApproved && (
                         <div className="mr-4">
							<button className="capitalize btn-secondary " onClick={() =>navigate('/client-dashboard')}>Go To Dashboard</button>
						 </div>
					 )}
      <img className="w-12 h-12 rounded-full" src={images.OnBoardingAvator} alt="" />
      <div className="font-bold dark:text-white">
        <div>{`${firstname}  `}</div>
        {/* <div class="text-sm text-gray-500 dark:text-gray-400">Joined in August 2014</div> */}
      </div>
      {/* {isClientApproved && (<div>
        <Link to="/client-dashboard" className='px-4 capitalize btn-secondary'> Dashboard</Link>
      </div>)
      } */}
    </div>


  )
}

export default UserAvatar