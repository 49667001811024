import React from "react";
import PreviewLayout from "../PreviewCommonComponent/PreviewLayout";
import PreviewDrop from "./PreviewHomeComponent/PreviewDrop";
import PreviewLanding from "./PreviewHomeComponent/PreviewLanding";
import PreviewUpcoming from "./PreviewHomeComponent/PreviewUpcoming";

const BrandPreviewHome = () => {
  return (
    <>
      <PreviewLayout>
        <div className="pt-10 my-container">
          <PreviewLanding />
          <PreviewUpcoming />
          <PreviewDrop />
        </div>
      </PreviewLayout>
    </>
  );
};

export default BrandPreviewHome;
