import React from 'react'
import AddContributor from '../../../../components/ClientConfiguration/ClientContributor/AddContributor'
import ClientHeaderComponent from '../../components/ClientHeaderComponent'

const ContributorContent = () => {
  return (
    <>
    <ClientHeaderComponent title={"Configuration"} />
    <AddContributor />
    </>
  )
}

export default ContributorContent