// features/user/userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	userInfo: {}, // for user object
	userRole: "", // for storing the JWT
	clientUuid: "", // clientUuid
	clientDetail: {},
	error: null,
	isLoggedIn: false,
	success: false, // for monitoring the registration process.
	
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		login: (state, action) => {
			
			state.userRole = action.payload.role;
			state.userInfo = action.payload.token;
			state.clientUuid = action.payload.clientid;
			state.isLoggedIn = true;
			
		},
		logout: (state) => {
			state.isLoggedIn = false;
			state.userRole = "";
			state.userInfo = "";
			state.clientUuid = "";
			state.clientDetail = {};
			state.adminConnectWalletAddress = '';
			
		},
		clientDetailAction: (state, action) => {
			state.clientDetail = action.payload;
		},
		clientPackages:(state,action)=>{
			state.packagesData=action.payload
		},
		adminAddress: (state,action) => {
			state.adminConnectWalletAddress = action.payload;
		},
		clientFlowBalance:(state,action)=>{
			state.FlowBalance=action.payload
		},
		
	},
	
});

export const { login, logout, clientDetailAction,clientPackages ,adminAddress,clientFlowBalance } = userSlice.actions;

export default userSlice.reducer;
