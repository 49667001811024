import React from 'react'

const Circle = ({ top, right, bottom, left, width, height, background }) => {
    return (
        <div className={`absolute opacity-25 blur-[217px] z-0 ${top} ${left} ${width} ${height} ${right} ${bottom} ${background} rounded-full`}>

        </div>
    )
}

export default Circle;