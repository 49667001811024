// EITHER USE THIS OR RequireAuth.js
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = ({ userRole, isLoggedIn }) => {
	
	
	

	return isLoggedIn ? (
		<Outlet context={{ role: userRole }} />
	) : (
		<Navigate to="/login" />
	);
};

export default ProtectedRoutes;
