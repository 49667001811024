import React, { useState ,useEffect } from "react";

import CategoryListing from "./CategoryListing";

import { useDispatch } from 'react-redux';
import { setLoader } from "../../../redux/features/loading/loaderSlice";
import { CategoryService } from "../../../services/category.service";



const CategoryContent = () => {
  const dispatch = useDispatch()
  const [categoriesData, setCategoriesData] = useState([]);

  useEffect(() => {
    getCategoriesData()
  
    return () => {
      
    }
  }, [])
  const getCategoriesData = () => {
    dispatch(setLoader(true))
    CategoryService.GetCategoriesList()
    .then(response => {
      dispatch(setLoader(false))
      if(response?.data?.statusCodeValue === 200){

        setCategoriesData(response?.data?.payload?.reverse())
      }

    })
    .catch(error => {
      dispatch(setLoader(false))
    })
  }
  
  
  return (
    <>
     
       
       
        
       <CategoryListing categoriesData={categoriesData} setCategoriesData={setCategoriesData} />
     
    </>
  ); 
};

export default CategoryContent;
