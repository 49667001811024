import {  useSelector } from "react-redux";
import Transactions from "../../Blockchain-transactions/BlockchainTransactions";
import BlockchainScriptHook from "./BlockchainScriptHook";

const FetchTemplatesFromBC = () => {
    const { blockChainBrandId } = useSelector(
        (state) => state?.user?.clientDetail
    );
    const { getBlockChainScriptFromDB } = BlockchainScriptHook();


    const fetchTemplatesList = async () => {
        const scriptModal = {
            scriptName: 7
        }
      
        const dbscript = await getBlockChainScriptFromDB(scriptModal);

        const scriptResult = await Transactions.ExecuteGetAssetScripts(dbscript, blockChainBrandId?.toString());
        if (scriptResult?.error === false && scriptResult?.message != null)
            return scriptResult?.message;
        else return null;
    };
    return {
        fetchTemplatesList,
    };
};

export default FetchTemplatesFromBC;