import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../../redux/features/loading/loaderSlice";
import { ClientTxService } from "../../../../services/transaction.service";
import ClientHeaderComponent from "../../components/ClientHeaderComponent";
import TransactionListing from "./TransactionListing";
const ClientTransactionReporting = () => {
  const dispatch = useDispatch()
  const { clientDetail  } = useSelector((state) => state?.user);
  const [transactionRecords, setTransactionRecords] = useState([]);
  const [SummaryData, setSummaryData] = useState({})
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

 
 
  const [params, setParams] = useState({
    clientId:  clientDetail?.id?.toString(),
    startDate: null,
    endDate: null,
    paymentSource: null,
    success: true,
  });
  const [openTab, setOpenTab] = useState(1);


  useEffect(() => {
    getTransactionDetailReport();
    //eslint-disable-next-line
  }, [params]);


  useEffect(() => {
    getTransactionSummary();
     //eslint-disable-next-line
  }, [])



 


  const getTransactionSummary = () => {
    ClientTxService.getTransactionSummary(clientDetail.clientUuid ).then(response => {
    
      if(response?.data?.statusCodeValue === 200){
        
        setSummaryData(response?.data?.payload?.result)
      }

    }).catch(err =>{

    })
  }
  
  

  const getTransactionDetailReport = () => {
    dispatch(setLoader(true))
    ClientTxService.getTransactionDetail(params)
      .then((response) => {
        if (response?.data?.statusCodeValue === 200) {
          dispatch(setLoader(false))
          if (response?.data?.payload?.result?.length > 0) {

            setTransactionRecords(response?.data?.payload?.result);
          } else {
            setTransactionRecords([]);
          }
        }
      })
      .catch((err) => {
        dispatch(setLoader(false))
      });
  };


  return (
    <>
     
        <ClientHeaderComponent title={"Transaction History"} />
        {/** Transactions stats start */}

       
          <>
           <div className="flex items-center mb-8">
          
          {/* <div className="mr-[14px] text-base px-11 py-3 rounded-full border font-normal  border-[#A4B1A7]/25 bg-[#12221A]/30"> <span className='font-bold'>{ `${SummaryData.totalTransactions ?  SummaryData.totalTransactions :'0'} ${' '}  ` }</span> Total Transactions  </div> */}
       
        
          {/* <div className="mr-[14px] px-11 py-3 text-base rounded-full border font-normal  border-[#A4B1A7]/25 bg-[#12221A]/30"><span className='font-bold'> { `${SummaryData.transactionFee ? SummaryData.transactionFee :'0'}  `  } </span>Transaction Fee </div> */}
        <div className="flex grow ">
        <div className="mr-[14px] px-11 py-3 text-base rounded-full border font-normal  border-[#A4B1A7]/25 bg-[#12221A]/30"> Platform Fee: <span className='font-bold'>{ `$${SummaryData.platformFee ? SummaryData.platformFee :'0'}` }</span>  </div>
          <div className=" mr-[14px] px-11 py-3 text-base rounded-full border font-normal  border-[#A4B1A7]/25 bg-[#12221A]/30">Total Sales Amount: <span className='font-bold'>{ `$${SummaryData.totalPriceUsd ? SummaryData.totalPriceUsd :'0'} ` }</span>  </div>

        </div>

        {/* <div className="">
                <p className="text-xs text-grey-100">Wallet Balance  </p>
                <p className={`text-base text-[#B0E577] font-semibold`}>{clientWalletBalance ? `${clientWalletBalance} Flow  `  :  "-"}</p>
                
              </div> */}
      
          
          
      </div>
          
          </>
   
       
        {/** Transactions stats end */}

        {/** transaction listing table start */}
        

        <TransactionListing  startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} setOpenTab={setOpenTab} openTab={openTab} transactionRecords={transactionRecords} params={params} setParams={setParams} />

        {/**transaction listing table end */}
   
    </>
  );
};

export default ClientTransactionReporting;
