import React from 'react'

const Footer = () => {
    return (
        <footer className= '  px-[0px] pt-12 pb-0  left-60 flex space-between justify-center  items-center bg-transparent '>
            <div className='flex flex-grow'>
            <p className='flex-shrink text-xs text-grey-100'>© {new Date().getFullYear()}. All rights reserved </p>

            </div>
           
        </footer>
    )
}

export default Footer