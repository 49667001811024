import React from 'react'
import { useDispatch } from 'react-redux';
import { reset_menuItems } from '../../redux/features/menu/menuItemsSlice';
import { setMenuSelected, setOpenSubMenu, setSubMenuSelected } from '../../redux/features/sidebar-menu/sidebarMenuSlice';
import { logout } from '../../redux/features/user/userSlice';
import * as fcl from "@onflow/fcl";

const useLogoutHook =() => {
    const dispatch = useDispatch()

    const handleLogout = () => {
        dispatch(logout());
        dispatch(setMenuSelected(0))
         dispatch(setSubMenuSelected(0))
       
         dispatch(setOpenSubMenu(''))
        dispatch(reset_menuItems(6));
         fcl.unauthenticate();
    }
  return{
    handleLogout
  }
}

export default useLogoutHook