import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const DatePickerHelper=(props)=>{
    return (
      <>
      {props.selected &&   <label
                className={`text-xs text-grey-100 mt-2   absolute  -top-6 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-6  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-6   peer-focus:text-grey-100 peer-focus:text-xs`}
              >
                {props.name}
              </label>}
        <DatePicker
        
          selected={props.selected}
          onChange={props.onchangedate}
          showTimeSelect
          timeFormat="HH:mm:ss"
          timeIntervals={15}
          timeCaption="time"
          dateFormat={props.showformat ? 'MMMM dd,YYY' : 'MMMM dd,YYY HH:mm:ss'} 
          className="text-[15px] bg-[url('/src/assets/images/icons/calendar-icon.svg')]  bg-no-repeat bg-right relative block w-full p-3 pl-0 pr-0 text-white bg-transparent border-t-0 border-b-1 border-l-0 border-r-0 border-[#A4B1A7] rounded-none outline-none placeholder:text-white text-md focus:ring-0 focus:border-b-[#A4B1A7] "
          maxDate={props.maximumdate}
          placeholderText={props.placeholderText}
          showMonthDropdown
          showYearDropdown
          minDate={props.mindate}
          disabled={props.disabled}
          name={props.name}
          autoComplete="off"
          id={props.id}
          
        />
        </>
      );
}
export default DatePickerHelper;