import * as yup from "yup";



export const ContributorSchema = yup.object().shape({
  contributor: yup.array().of(
    
    yup.object().shape({
      contributorActive:yup.bool(),
      name: yup
        .string()
        .required("Name  is required")
        .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(100, "Max  length 100 characters"),
    receiverAddress: yup
      .string()
      .required("Address  is required")
      .matches(/^0x(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/, "Invalid Address")
      .max(18, "Max  length 18 characters"),
      percentage: yup
      .number()
      .required("Percentage  is required")
      .typeError("Please enter Amount in number")
      .positive("Number must be positive and greater than zero")
      .test(
        'is-decimal',
        'maximum two decimals allowed',
        value => (value + "").match(/^\d+(?:\.\d{0,2})?$/),
      ),
    })
  )
});