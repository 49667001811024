import { useState } from "react";
import AboutUs from "../../../components/ClientTheme/AboutUs";
import HomePage from "../../../components/ClientTheme/HomePage";
import PrivacyPolicy from "../../../components/ClientTheme/PrivacyPolicy";
import TermsAndConditions from "../../../components/ClientTheme/TermsAndConditions";
import ClientHeaderComponent from "../components/ClientHeaderComponent";
import {
    Tab,
    TabBody,
    TabComponent,
    TabHeader,
    TabPanel,
} from "../../../components/ui-components/tabs-component/tabs";
import ThemeCustomization from "../../../components/ClientTheme/ThemeCustomization";

const ClientTheme = () => {

    const [openTab, setOpenTab] = useState(1);

    return (
        <>
        
                <ClientHeaderComponent title={"Theme Editor"} />

                <div className="">
                    <TabComponent>
                        <TabHeader>
                            <Tab openTab={openTab} setOpenTab={setOpenTab} value={1}>
                                Home Page
                            </Tab>
                            <Tab openTab={openTab} setOpenTab={setOpenTab} value={2}>
                            Brand Colors
                            </Tab>
                            <Tab openTab={openTab} setOpenTab={setOpenTab} value={3}>
                                About Us
                            </Tab>
                            <Tab openTab={openTab} setOpenTab={setOpenTab} value={4}>
                                Terms and Conditions
                            </Tab>
                            <Tab openTab={openTab} setOpenTab={setOpenTab} value={5}>
                                Privacy Policy
                            </Tab>
                        </TabHeader>
                        <TabBody>
                            <TabPanel openTab={openTab} value={1}>
                                <HomePage />
                            </TabPanel>
                            <TabPanel openTab={openTab} value={2}>
                                <ThemeCustomization />
                            </TabPanel>
                            <TabPanel openTab={openTab} value={3}>
                                <AboutUs />
                            </TabPanel>
                            <TabPanel openTab={openTab} value={4}>
                                <TermsAndConditions />
                            </TabPanel>
                            <TabPanel openTab={openTab} value={5}>
                                <PrivacyPolicy />
                            </TabPanel>
                        
                        </TabBody>
                    </TabComponent>
                </div>
          

        </>
    )
}

export default ClientTheme;