import React, { useEffect } from "react";
import Select, { components } from "react-select";
import { Controller, useForm } from "react-hook-form";
import DatePickerHelper from "../../../../../helpers/DatePickerHelper";
import { addDropSchema } from "../../../../../utils/ValidationSchemas/AddDropSchema";
import VideoThumbnail from "react-video-thumbnail";
import { yupResolver } from "@hookform/resolvers/yup";
import { images } from "../../../../../constants";
import { useNavigate } from "react-router-dom";
import DropTypeSchemaOptions from "../../../../../utils/DropDownOptions/DropTypeSchemaOption";
import { customStyles } from "../../../../../components/ui-components/react-select-style/customStyle";
import SwitchComponent from "../../../../../components/ui-components/switch-component/SwitchComponent";
import AddWhitelistSelectionOption from "./AddWhitelistSelectionOption";
import { Tooltip } from "flowbite-react";

const CreateDropDetail = (props) => {
  const navigate = useNavigate();
  const {
    assetUploadingDiv,
    dropFileUploaderHandler,
    bannerVideoSrc,
    bannerImgSrc,
    crossClickHandler,
    bannerThumbnailSrc,
    dataUrlToFile,
    videoThumbnailSrc,
    AssetsArray,
    imageChecked,
    AddRemoveArtWorkHandler,
    setSelectCheckboxes,
    setActiveStep,
    steps,
    setSteps,
    setDropDetailData,
    dropDetailData,
    viewEditData,
    videoThumbNailViewOnly,
    claimValuePlusHandler,
    claimValueMinusHandler,
    claimMaxRef,
    claimValue,
    setClaimValue,
    claimInputChangeHandler,
    addClaimLimit,
    setAddClaimLimit,
    isMaxClaim,
    setIsMaxClaim,
    isWhitelistSection,
    setIsWhitelistSection,
    addWhitelistSwitch,
    setAddWhitelistSwitch,
    setSelectedWhitelistOption,
    selectedWhitelistOption,
    airdropUserList,
    setAirdropUserList,
  } = props;

  const options = DropTypeSchemaOptions();
  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      isEditStartDate: false,
      dropName: dropDetailData?.dropName || "",
      dropPrice: dropDetailData?.dropPrice || "",
      dropDescription: dropDetailData?.dropDescription || "",
      PublicSaleStartDate: dropDetailData?.PublicSaleStartDate
        ? new Date(dropDetailData?.PublicSaleStartDate)
        : null,
      PublicSaleEndDate: dropDetailData?.PublicSaleEndDate
        ? new Date(dropDetailData?.PublicSaleEndDate)
        : null,
      dropType: {
        label: dropDetailData?.dropType?.label || "",
        value: dropDetailData?.dropType?.value || "",
      },

      UserPurchaseLimit: dropDetailData?.userPurchaseLimit || "",
      AirDropConfigurationId: dropDetailData?.AirDropConfigurationId,
      whitelistOptions: {
        value: dropDetailData?.whitelistOptions?.value || "",
        label: dropDetailData?.whitelistOptions?.label || "",
      },
      addAirDropFile: dropDetailData?.addAirDropFile || false,

      whitelistName: dropDetailData?.whitelistName || "",
      airdropFile: dropDetailData?.airdropFile,
    },
    resolver: yupResolver(addDropSchema),
    mode: "all",
  });

  useEffect(() => {
    if (
      viewEditData?.isEdit &&
      dropDetailData?.dropName &&
      dropDetailData?.dropPrice &&
      dropDetailData?.dropDescription &&
      dropDetailData?.PublicSaleStartDate &&
      dropDetailData?.PublicSaleEndDate
    ) {
      setValue("isEditStartDate", true);
      setValue("dropName", dropDetailData?.dropName);
      setValue("dropPrice", dropDetailData?.dropPrice);
      setValue("dropDescription", dropDetailData?.dropDescription);
      if (typeof dropDetailData?.PublicSaleStartDate !== "object") {
        setValue(
          "PublicSaleStartDate",
          new Date(dropDetailData?.PublicSaleStartDate + "000Z")
        );
      }
      if (typeof dropDetailData?.PublicSaleEndDate !== "object") {
        setValue(
          "PublicSaleEndDate",
          new Date(dropDetailData?.PublicSaleEndDate + "000Z")
        );
      }
      setValue("dropType", {
        value: dropDetailData?.dropType?.value,
        label: dropDetailData?.dropType?.label,
      });
    }
    //eslint-disable-next-line
  }, [dropDetailData?.dropName]);

  const handleCreateDropDetail = (values) => {
    setDropDetailData(values);
    setActiveStep(2);
    setSteps(
      steps.map((step, index) =>
        step.value === 1 ? { ...step, IsCompleted: true } : step
      )
    );
  };
  const dropPriceExist = watch("dropPrice");

  const { SingleValue } = components;
  const SingleOption = (props) => (
    <SingleValue {...props} className="shadow-none text-capitalize">
      {props.data.value ? (
        <>
          <div className="relative">{props.data.label}</div>
        </>
      ) : (
        <p className="text-base text-white">Type of Drop</p>
      )}
    </SingleValue>
  );
  const checkDropType = watch("dropType");

  const handleSetClaimLimit = () => {
    setClaimValue(1);
    setAddClaimLimit(!addClaimLimit);
    setIsMaxClaim(!isMaxClaim);
  };

  const handleWhitelistSection = () => {
    setIsWhitelistSection(!isWhitelistSection);
    setAddWhitelistSwitch(!addWhitelistSwitch);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(handleCreateDropDetail)}
        className=" h-[calc(100vh-272px)] relative "
      >
        <div className="container relative grid h-full grid-cols-2 overflow-y-scroll ">
          <div className="mt-8 columns-md max-w-[494px]">
            <div className="relative mt-8 mb-8 ring-0 ring-offset-0 z-20">
              {getValues("dropType")?.value && (
                <label
                  className={`text-xs text-grey-100    absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
                >
                  Type of Drop
                </label>
              )}

              <Controller
                name="dropType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    components={{
                      SingleValue: SingleOption,
                    }}
                    onChange={(e) => {
                      field.onChange({ label: e.label, value: e.value });

                      if (e.label === "Air Drop") {
                        setValue("dropPrice", "");
                      }
                    }}
                    options={options}
                    placeholder={
                      <span className="text-white">Type of Drop</span>
                    }
                  />
                )}
              />

              <p className="mt-2 text-xs text-red-600">
                {errors?.dropType?.value?.message}
              </p>
            </div>
            <div className="relative z-10 mb-8">
              <input
                type="text"
                className={
                  "peer outline-none border-t-0 border-r-0 border-l-0 border-b-1 border-[#A4B1A7] rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-[#A4B1A7] block w-full p-2.5 bg-transparent"
                }
                placeholder=" "
                {...register("dropName")}
              />
              <label
                className={`text-xs text-grey-100 z-[-1]   absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
              >
                Drop Name
              </label>
              <p className="mt-2 text-xs text-red-600">
                {errors?.dropName?.message}
              </p>
            </div>

            <div
              className={`relative z-10 mb-8 ${
                parseFloat(checkDropType?.value) === 2 && "hidden"
              }`}
            >
              <input
                type="text"
                className={`${
                  getValues("dropPrice")?.length > 0 ? "pl-3" : "pl-0"
                }  peer outline-none border-t-0 border-r-0 border-l-0 border-b-1 border-[#A4B1A7] rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-[#A4B1A7] block w-full p-2.5 bg-transparent`}
                placeholder=" "
                {...register("dropPrice", {
                  onBlur: (e) => {
                    if (getValues("dropPrice").includes(".")) {
                      setValue("dropPrice", e.target.value);
                    } else {
                      if (e.target.value.length > 0) {
                        if (!isNaN(e.target.value)) {
                          setValue(
                            "dropPrice",
                            parseFloat(e.target.value).toFixed(2)
                          );
                        } else {
                          setValue("dropPrice", e.target.value);
                        }
                      }
                    }
                  },
                })}
              />
              <label
                className={`text-xs text-grey-100 z-[-1]   absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
              >
                Drop Price
              </label>
              {(getValues("dropPrice")?.length > 0 || dropPriceExist) && (
                <span className={`text-white text-md absolute  left-0 top-2.5`}>
                  $
                </span>
              )}
              <p className="mt-2 text-xs text-red-600">
                {errors?.dropPrice?.message}
              </p>
            </div>

            {/* <div className="relative z-10 mb-8 group">
              <input
                type="text"
                className={
                 ` peer ${getValues("dropPrice")?.length > 0 ? 'pl-3' :'pl-0'}  outline-none border-t-0 border-r-0  border-l-0 border-b-1 border-[#A4B1A7] rounded-none  pr-7 text-white text-md   focus:ring-0 focus:border-[#A4B1A7] block w-full p-2.5 bg-transparent`
                }
                placeholder=" "
                {...register("dropPrice", {
                  onBlur: (e) => {
                    if (getValues("dropPrice").includes(".")) {
                        setValue("dropPrice", e.target.value);
                    } else {
                      if (e.target.value.length > 0) {
                        setValue(
                          "dropPrice",
                           parseFloat(e.target.value).toFixed(2)
                        );
                      }
                    }
                  },
                })}
              />
              {(getValues("dropPrice")?.length > 0 || dropPriceExist)  && <span className={`text-white text-md absolute  left-0 top-2.5`}>$</span>}
              
              <label
                className={`text-xs text-grey-100  z-[-1]  absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
              >
                Drop Price
              </label>
              <p className="mt-2 text-xs text-red-600 peer-focus:hidden">
                {errors?.dropPrice?.message}
              </p>
            </div> */}
            {/*------------- Hidden checkbox for disabling start time ------------------*/}
            <div className="hidden form-group form-check">
              <input
                type="checkbox"
                className="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-transparent bg-center bg-no-repeat bg-contain border-2 border-gray-300 rounded-[4px] appearance-none cursor-pointer form-check-input checked:bg-[#A5F33C] checked:border-[#A5F33C] focus:shadow-none focus:outline-none"
                id="isOldPassword"
                {...register("isEditStartDate")}
              />
              <label
                className="inline-block text- form-check-label"
                htmlFor="exampleCheck2"
              >
                start time
              </label>
            </div>
            <div className="relative z-40 grid grid-cols-2 gap-6 mb-8 ">
              <div>
                <Controller
                  control={control}
                  name="PublicSaleStartDate"
                  render={({ field }) => (
                    <DatePickerHelper
                      selected={field.value}
                      // datevalue={publicSaleStartDate}
                      placeholderText="Public Sale Start"
                      minDate={new Date()}
                      onchangedate={(e) => field.onChange(e)}
                      name="Public Sale Start"
                      id="PublicSaleStartDate"
                    />
                  )}
                />
                <p className="mt-2 text-xs text-red-600">
                  {errors?.PublicSaleStartDate?.message}
                </p>
              </div>
              <div>
                <Controller
                  control={control}
                  name="PublicSaleEndDate"
                  render={({ field }) => (
                    <DatePickerHelper
                      className="bg"
                      //  datevalue={publicSaleEndDate}
                      placeholderText="Public Sale End"
                      minDate={new Date()}
                      onchangedate={(e) => field.onChange(e)}
                      name="Public Sale End"
                      id="PublicSaleEndDate"
                      selected={field.value}
                    />
                  )}
                />
                <p className="mt-2 text-xs text-red-600">
                  {errors?.PublicSaleEndDate?.message}
                </p>
              </div>
            </div>

            <div className="relative z-0 mt-12 mb-10 ">
              <textarea
                className="peer outline-none  px-3 py-2  border rounded-xl border-[#A4B1A7] text-white text-md  placeholder:text-white focus:ring-transparent focus:border-[#A4B1A7] focus:outline-none  block w-full  bg-transparent"
                rows="4"
                placeholder=" "
                name="dropDescription"
                {...register("dropDescription")}
              ></textarea>
              <label
                className={`text-xs text-grey-100 z-[-1]  left-0 absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3 peer-placeholder-shown:left-3   peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs peer-focus:left-0`}
              >
                Description
              </label>
              {errors?.dropDescription?.message && (
                <p className={`mt-2 text-xs text-red-600`}>
                  {errors?.dropDescription?.message}
                </p>
              )}

              <p className="mt-1 text-xs text-grey-100 ">
                This description will reflect under your drop banner on the
                storefront. ( Max length 500 characters )
              </p>
            </div>

            {parseFloat(checkDropType?.value) === 2 && (
              <>
                {/* ----------- CLAIM & WHITELISt FUNCTIONALITY START------------- */}
                <div className="relative mt-10 mb-12">
                  <h2 className="mb-1 text-2xl font-semibold">Claim Limit</h2>
                  <p className="mb-6 text-xs text-grey-100">
                    Please specify the number of NFTs that can be claimed per
                    wallet address.
                  </p>

                  {/*>>> CLAIM LIMIT START */}

                  <div className="flex items-center mb-8">
                    <div className="flex items-center now-counter justify-between">
                      {/* <span className="mr-8"> */}
                      Limited{" "}
                      <Tooltip
                        className="text-[white] text-xs bg-[#12221A] z-20 relative  border border-[#12221A] rounded max-w-[200px]"
                        offset={10}
                        placement={"top-center"}
                        content={
                          " Limited assets can be claimed per wallet address"
                        }
                      >
                        <img
                          className="inline px-4 align-top"
                          src={images.InfoIcon}
                          alt="icon"
                        />
                      </Tooltip>
                      {/* </span> */}
                      <img
                        src={images.MinusIcon}
                        onClick={claimValueMinusHandler}
                        className={`cursor-pointer inline-block p-[2px]  ${
                          isMaxClaim
                            ? "bg-[#557C1E]  pointer-events-none"
                            : "bg-[#A5F33C]"
                        }   rounded-[4px] `}
                        alt="icon"
                      />
                      <div className="input-wrap">
                        <input
                          className={`disabled:text-[#BDBDBD] text-center form-control no-icon bg-black border-none max-w-[80px] px-[12px] py-0 focus:bg-transparent focus:ring-0`}
                          type="text"
                          disabled={addClaimLimit}
                          ref={claimMaxRef}
                          id="ClaimMaxValue"
                          name="ClaimMaxValue"
                          value={claimValue}
                          pattern="[0-9]*"
                          onFocus={() => {
                            setClaimValue(
                              claimMaxRef.current === (0 || "") ? 1 : claimValue
                            );
                          }}
                          onBlur={() => {
                            setClaimValue(
                              claimMaxRef.current === (0 || "") ? 1 : claimValue
                            );
                          }}
                          onInput={claimInputChangeHandler}
                        />
                      </div>
                      <img
                        onClick={claimValuePlusHandler}
                        className={`inline-block p-[2px] ${
                          isMaxClaim
                            ? "bg-[#557C1E] pointer-events-none "
                            : "bg-[#A5F33C]"
                        } rounded-[4px] cursor-pointer`}
                        src={images.PlusIcon}
                        alt="icon"
                      />
                    </div>
                    <span className="mx-6">|</span>
                    <div className=" flex items-center justify-between">
                      {/* <span className="text-base "> */}
                      Unlimited{" "}
                      <Tooltip
                        className="text-[white] text-xs bg-[#12221A] z-20 relative  border border-[#12221A] rounded max-w-[200px]"
                        offset={10}
                        placement={"top-center"}
                        content={
                          " Unlimited assets can be claimed assets per wallet address"
                        }
                      >
                        <img
                          className="inline align-top px-3"
                          src={images.InfoIcon}
                          alt="icon"
                        />{" "}
                      </Tooltip>
                      {/* </span> */}
                    </div>

                    <SwitchComponent
                      onChange={handleSetClaimLimit}
                      value={addClaimLimit}
                      id={"claimLimit"}
                    />
                  </div>

                  {/* >>> CLAIM LIMIT END */}
                </div>

                {/* ### WHITE LIST SECTION STARTS */}

                {
                  <>
                    <div className="flex justify-between mb-1">
                      <h4 className="text-2xl font-bold text-white">
                        Whitelist
                      </h4>

                      <SwitchComponent
                        onChange={handleWhitelistSection}
                        value={addWhitelistSwitch}
                        id={"showWhitelist"}
                      />
                    </div>
                    <div className="mb-6">
                      <p className="text-xs text-grey-100">
                        Only users who have been whitelisted will have the
                        ability to claim this airdrop. Please download the
                        provided Excel file template to generate a list of
                        eligible users.
                      </p>
                    </div>
                  </>
                }

                {isWhitelistSection && (
                  <>
                    <div className="mb-12">
                      {/* Whitelist selection Box */}
                      {
                        <AddWhitelistSelectionOption
                          getValues={getValues}
                          components={components}
                          Controller={Controller}
                          control={control}
                          Select={Select}
                          customStyles={customStyles}
                          register={register}
                          errors={errors}
                          watch={watch}
                          reset={reset}
                          setSelectedWhitelistOption={
                            setSelectedWhitelistOption
                          }
                          selectedWhitelistOption={selectedWhitelistOption}
                          airdropUserList={airdropUserList}
                          setAirdropUserList={setAirdropUserList}
                          viewEditData={viewEditData}
                          dropDetailData={dropDetailData}
                          setValue={setValue}
                        />
                      }
                    </div>
                  </>
                )}

                {/* ### WHITE LIST SECTION ENDS */}

                {/* @----------- CLAIM & WHITELISt FUNCTIONALITY END-------------@ */}
              </>
            )}
          </div>
          <div className="mt-8 mr-6 columns-md ">
            <div className={assetUploadingDiv}>
              <label htmlFor="dropFileUploader" className="">
                <input
                  type="file"
                  id="dropFileUploader"
                  accept=".gif,.jpg,.jpeg,.png,.mp4"
                  name="dropFileUploader"
                  className="hidden"
                  // {...register("dropFileUploader",{
                  //   onChange: e => {
                  //     dropFileUploaderHandler(e ,setValue)
                  //   }
                  // })}
                  onChange={dropFileUploaderHandler}
                />
                <h4 className="mb-3 text-xl font-bold text-white">
                  {" "}
                  Upload Drop Media
                </h4>
                <div className="flex justify-end">
                  <div className="flex flex-col items-center justify-center border border-[#A4B1A7] border-solid rounded w-full  min-h-[250px] h-full">
                    <img
                      className="text-center "
                      src={images.UploadAssetIcon}
                      alt="upload-icon"
                    />

                    <h5 className="text-base text-center text-white ">
                      <span className="font-bold">Browse File</span>
                    </h5>
                    <p className="text-xs text-grey-100">
                      Larger recommended, up to 20MB each
                    </p>
                    <p
                      className={`text-grey-100 text-xs ${
                        bannerVideoSrc ? "hidden" : ""
                      }`}
                    >
                      {/* <strong>
                        Note: *Drop image should be greater than 1300 * 500
                      </strong> */}
                    </p>
                  </div>
                </div>
              </label>
              <p className="mt-2 text-xs text-red-600">
                {errors?.dropFileUploader?.message}
              </p>
            </div>

            <div className="flex items-start">
              {bannerImgSrc && (
                <>
                  <img
                    className="max-h-[300px] max-w-[500px]"
                    src={bannerImgSrc}
                    width="500"
                    height={"300"}
                  />
                  <img
                    src={images.RemoveFieldIcon}
                    alt="crossImg"
                    onClick={crossClickHandler}
                    className="-ml-4 cursor-pointer "
                  />
                </>
              )}
              {bannerVideoSrc && (
                <>
                  <video
                    src={bannerVideoSrc}
                    controls
                    width="500"
                    height={"300"}
                  />
                  <img
                    src={images.RemoveFieldIcon}
                    alt="crossImg"
                    onClick={crossClickHandler}
                    className="relative z-10 -ml-4 cursor-pointer"
                  />
                </>
              )}
              {videoThumbNailViewOnly && (
                <div className="thumb-view">
                  <label className="d-block">Thumbnail Image:</label>{" "}
                  <img
                    src={videoThumbNailViewOnly}
                    className="img-fluid max-h-[300px] max-w-[500px]"
                    alt="thumbnailimg"
                  />
                </div>
              )}
            </div>
            <div className="max-w-[500px] max-h-[300px]">
              {bannerThumbnailSrc && (
                <VideoThumbnail
                  snapshotAtTime={5}
                  videoUrl={bannerThumbnailSrc}
                  thumbnailHandler={(thumbnail) => {
                    let file = dataUrlToFile(thumbnail, "thumnailImg.png");
                    videoThumbnailSrc.current = file;
                  }}
                />
              )}
            </div>
            {/* <div>
                <input
                  type={"text"}
                  className="bg-black"
                  onChange={SearchAssetByNameHandler}
                />
              </div> */}
            {/* <div>
              <ul>
                {AssetsArray?.length > 0 ? (
                  AssetsArray?.map((data, index) => {
                    const { id, artSrc, assetName, templateType } = data;
                    return (
                      <li key={index}>
                        <input
                          type={"radio"}
                          checked={imageChecked === id}
                          onClick={(e) => {
                            AddRemoveArtWorkHandler(e, data);
                            e.currentTarget.checked
                              ? setSelectCheckboxes(id)
                              : setSelectCheckboxes("");
                          }}
                        />
                        <label htmlFor={id}>
                          <div className="img-thumbnails">
                            {templateType === "Image" ? (
                              <img
                                src={artSrc}
                                width="187"
                                height="204"
                                alt="cards"
                                onError={(e) => {
                                  //   e.currentTarget.attributes.src.value =
                                  //     PlaceHolder;
                                }}
                              />
                            ) : templateType === "Video" ? (
                              <video
                                width="187"
                                height="204"
                                controls
                                src={artSrc}
                              ></video>
                            ) : (
                              <video
                                controls
                                src={artSrc}
                                style={{ width: "187px" }}
                                height="204"
                                //  poster={audioImg}
                              ></video>
                            )}
                          </div>
                          <span className="d-flex align-items-center justify-content-between">
                            <div>
                              <strong>
                                {assetName.length > 10
                                  ? assetName.substring(0, 9) + "..."
                                  : assetName}
                              </strong>
                            </div>
                          </span>
                        </label>
                      </li>
                    );
                  })
                ) : (
                  <h3>No assets found</h3>
                )}
              </ul>
            </div> */}
          </div>
        </div>
        <div className="flex justify-between items-center  absolute -bottom-5 bg-[#060C07] w-full  z-30">
          <button
            className="btn-primary max-w-[400px] text-center "
            type="submit"
            disabled={
              (bannerImgSrc === "" && bannerVideoSrc === "") ||
              (getValues()?.whitelistOptions?.value === "" &&
                isWhitelistSection &&
                getValues()?.addAirDropFile === false &&
                parseFloat(checkDropType?.value) === 2)
            }
          >
            Next
          </button>
          <span
            className="mr-10 text-base font-bold  cursor-pointer hover:bg-[#A5F33C] hover:text-[#12221A] hover:rounded-full px-10 py-3"
            onClick={() => navigate("/drop-list")}
          >
            Cancel
          </span>
        </div>
      </form>
    </>
  );
};

export default CreateDropDetail;
