import { getRequest, postRequest } from "../utils/api/axiosMethods";

const addRoyaltyConfiguration = (data) => {

  return postRequest(`ClientRoyalty/add_royalty_configuration`, data).then(
    (response) => {
      return response;
    }
  );
};

const getRoyaltyConfiguration = (clientUuid) => {
  return getRequest(`clientroyalty/get_royalty_configuration?ClientId=${clientUuid}`).then(
    (response) => {
      return response;
    }
  );
};

export const configurationService = {
  addRoyaltyConfiguration,
  getRoyaltyConfiguration,
};
