import React from "react";
import { images } from "../../../constants";

const ColorPickerInputField = (props) => {
  const { value, handleChange } = props;
  return (
    <>
      <label className="border border-[#A4B1A7] rounded-[4px] p-3 flex   relative cursor-pointer">
        <span
          className="w-[24px] h-[24px] mr-2 rounded-full"
          style={{ background: value }}
        />

        <span className="p clr-rockblue  grow mr-2 relative">
          {value}
          <input
            style={{ padding: "0px" }}
            type="color"
            name="btnColor"
            value={value}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            className="invisible  absolute left-0 "
          />
        </span>
        <span className="colorimg-style">
          <img src={images.ColorDropIcon} alt="" />
        </span>
      </label>
    </>
  );
};

export default ColorPickerInputField;
