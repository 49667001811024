import React from 'react';
import * as fcl from "@onflow/fcl";
import { Link, useLocation } from 'react-router-dom';
import RoundedBorderNav from './RoundedBorderNav';
import { useDispatch } from 'react-redux';
import { logout } from '../../../redux/features/user/userSlice';
import { setMenuSelected, setOpenSubMenu, setSubMenuSelected } from '../../../redux/features/sidebar-menu/sidebarMenuSlice';
import { reset_menuItems } from '../../../redux/features/menu/menuItemsSlice';
import { images } from '../../../constants';

const OnboardingSidebar = ({ menuItems }) => {
const dispatch = useDispatch();
    const { pathname } = useLocation();

    const handleLogout = async () => {
        dispatch(logout());
        dispatch(setMenuSelected(0))
        dispatch(setSubMenuSelected(0))
        dispatch(setOpenSubMenu(''))
        dispatch(reset_menuItems(6));
         fcl.unauthenticate();
      };

  

    return (
        <div className='flex flex-col flex-shrink-0 antialiased text-gray-800 bg-[#12221A] mt-10'>
            <div className="fixed top-0 left-0 flex flex-col w-[35%] h-full border-opacity-50  rounded-r-3xl bg-[#12221A] shadow-[6px_0px_5px_0px_#0000005C] 
                            bg-no-repeat bg-left-top bg-[url('/src/assets/images/icons/onboarding-bg.svg')] bg-cover">
                <div className="mb-[60px] ml-[70px] mt-3">
                    <img
                        className="z-20 top-6"
                        src={images.BloomlyLogo}
                        alt="logo"
                    />
                </div>
                <nav className='flex flex-col items-start ml-[70px] text-white mb-6'>
                    <h1 className='mb-1 text-2xl font-bold'>Setup your account in few steps </h1>
                    <p className='mr-20 text-xs text-grey-100'>The information provided will allow us to set up your Bloomly account and to spin up your brand storefront.</p>
                </nav>

                <RoundedBorderNav items={menuItems} activeItem={pathname} />
                <div className='flex flex-col ml-[65px]'>
                <Link
                to="/login"
                onClick={handleLogout}
                className="absolute flex items-center flex-grow pt-3 pl-6 bg-transparent rounded-r-full cursor-pointer bottom-4"
              >
                <span className="z-10 ">
                  <img src={images.LogoutIcon} alt="logout" />
                </span>
                <span className="z-10 ml-4 text-sm tracking-wide text-white truncate">
                  Logout
                </span>
              </Link>
                  
                </div>
            </div>
        </div>
    )
}

export default OnboardingSidebar