import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Select, { components } from "react-select";
import { customStyles } from "../../../../../../components/ui-components/react-select-style/customStyle";
import { addAssetSchema } from "../../../../../../utils/ValidationSchemas/AddAssetSchema";
import AssetSchema from "../../AssetSchema";
import AddTextFieldComponent from "../AddTextFieldComponent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import SwitchComponent from "../../../../../../components/ui-components/switch-component/SwitchComponent";
import ImmutableToggle from "./ImmutableToggle";

const ImmutableComponent = (props) => {
  const navigate = useNavigate();
  const {
    schemaUploadDiv,
    setschemaUploadDiv,
    UploadSchemaHandler,
    setshowUploadedSchema,
    showUploadedSchema,
    setuploadedSchemaSrc,
    uploadedSchemaSrc,
    setThumbnailSource,
    crossImgClickHandler,
    OrignalImgUrlRef,
    setSelectedSchemaFileValue,
    ImageUploadInputRef,
    croppedImgUrl,
    setshowCroppingPopup,
    showCroppingPopup,
    selectedSchemaType,
    thumbnailSource,
    setSelectedSchemaType,
    clientSchemasOptions,
    setActiveStep,
    inputImmutableFields,
    setImmutableInputFields,
    ImmutableData,
    setImmutableData,
    setIsMediaSelect,
    isMediaSelect,
    OpenAlertModal,
    setOpenAlertModal,
    setAlertContent,
    alertContent,
    setFieldIndex,
    fieldIndex,
    setSteps,
    steps,
    showImmutableKeyValue,
    setShowImmutableKeyValue,
    assetCreationErrorMsgs
  } = props;

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      assetName: ImmutableData.assetName || "",
      assetDescription: ImmutableData.assetDescription || "",
      assetType: {
        value: ImmutableData?.assetType?.value || 1,
        label: ImmutableData?.assetType?.label || "Image",
      },
    },
    resolver: yupResolver(addAssetSchema),
    mode: "all",
  });


  const [checkFieldkeyDuplicate, setcheckFieldDuplicate] = useState(false);

  const { SingleValue } = components;
  const SingleOption = (props) => (
    <SingleValue {...props} className="shadow-none text-capitalize">
      {props.data.value ? (
        <>
          <div className="relative">{props.data.label}</div>
        </>
      ) : (
        <p className="text-base text-white">Type of NFT</p>
      )}
    </SingleValue>
  );

  const handleCreateAssetImmutableStep = (values) => {
    let checkAllKeyFieldsValueExists = true;
    if (!uploadedSchemaSrc) {
      setIsMediaSelect({
        ...isMediaSelect,
        status: false,
        message: "Please select Media",
      });
    } else {
      if (selectedSchemaType === 1) {
        if (croppedImgUrl.current === null) {
          setIsMediaSelect({
            ...isMediaSelect,
            status: false,
            message: "Crop image is required",
          });
        } else {
          immutableKeyValuePairValidation(values);

        }
      } else {
        immutableKeyValuePairValidation(values);
      }
    }
  };
  const immutableKeyValuePairValidation = (values) => {
    if (inputImmutableFields?.length === 1 &&
      inputImmutableFields?.[0]?.fieldKey?.trim() === '' &&
      inputImmutableFields?.[0].fieldValue?.trim() === '') {
        moveToMutableTab(values)
    }
    else if (inputImmutableFields?.length >= 1) {
      let bEmptyPair = inputImmutableFields?.filter(data => {
        return (data?.fieldKey?.trim() === '' || data?.fieldValue?.trim() === '')
      });
      if (bEmptyPair?.length > 0) {
        setAlertContent({
          title: "Error",
          description: "Please enter key value pair ",
        });
        setActiveStep(1);
        setOpenAlertModal(true);
      }
      else {
       moveToMutableTab(values)
      }
    }
  }
  const moveToMutableTab=(values)=>{
    setImmutableData(values);
    setActiveStep(2);
    setSteps(
      steps.map((step, index) =>
        step.value === 1 ? { ...step, IsCompleted: true } : step
      )
    );
  }
  const handleImmutableKeyValue = () => {
    setShowImmutableKeyValue(!showImmutableKeyValue)
  }
  return (
    <>
      <form onSubmit={handleSubmit(handleCreateAssetImmutableStep)} className=' h-[calc(100vh-272px)] relative '>
        <div className={`container relative z-[50] grid h-full grid-cols-2 overflow-y-auto`}>
          <div className=" columns-md max-w-[494px]">
            <h2 className="text-xl font-semibold">Immutable</h2>
            <p className="mt-1 text-xs text-grey-100">
            When properties are attached to the asset in step 1 (immutable), it can't be changed or updated. It means that the asset type, asset name, description and image below cannot be altered. 
            </p>

            <div className="relative mt-8 mb-8 ring-0 ring-offset-0">
              {
                getValues('assetType')?.value &&
                <label
                  className={`text-xs text-grey-100    absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
                >
                  Type of NFT
                </label>
              }

              <Controller
                name="assetType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    components={{
                      SingleValue: SingleOption,
                    }}
                    onChange={(e) => {
                      crossImgClickHandler();
                      field.onChange({ label: e.label, value: e.value });
                      const selectedSchemaVal = e.value;
                      setSelectedSchemaType(selectedSchemaVal);
                    }}
                    options={clientSchemasOptions}
                  />
                )}
              />

              <p className="mt-2 text-xs text-red-600">
                {errors?.assetType?.value?.message}
              </p>
            </div>
            <div className="relative z-0 mb-8 ">
              <input
                type="text"
                className={
                  "peer outline-none border-t-0 border-r-0 border-l-0 border-b-1 border-[#A4B1A7]          A4B1A7] rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-b-[#A4B1A7]  block w-full p-2.5 bg-transparent"
                }
                placeholder=" "
                {...register("assetName")}
              />
              <label
                className={`text-xs text-grey-100 z-[-1]   absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
              >
                Asset Name
              </label>
              <p className="mt-2 text-xs text-red-600">
                {errors?.assetName?.message}
              </p>
            </div>

            <div className="relative z-0 mt-12 mb-8">
              <textarea
                type="text"
                className={
                  "peer outline-none  px-3 py-2  border rounded-xl border-[#A4B1A7] text-white text-md  placeholder:text-white focus:ring-transparent focus:border-[#A4B1A7] focus:outline-none  block w-full  bg-transparent"
                }
                rows="4"
                maxLength={'501'}
                placeholder=" "
                {...register("assetDescription")}
              ></textarea>
              <label
                className={`text-xs text-grey-100 z-[-1]  left-0 absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3 peer-placeholder-shown:left-3   peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs peer-focus:left-0`}
              >
                Description
              </label>
             {!errors?.assetDescription?.message && <p className='mt-2 text-xs text-grey-100'>(Max  length 500 characters)</p> } 
              <p className="mt-2 text-xs text-red-600">
                {errors?.assetDescription?.message}
              </p>
            </div>
        
                <div className="mb-4">
              <div className="flex justify-between mb-3">
              <h4 className="mb-1 text-2xl font-bold text-white">Other Properties </h4>
              <ImmutableToggle
                onChange={handleImmutableKeyValue}
                value={showImmutableKeyValue}
                id={'immutable'}
              />
              </div>
              <p className="text-xs text-grey-100 ">
              Assign additional properties and traits that are immutable to the asset
              <br />
              Property (Example: Rarity ) 
              Trait (Example: Rare ) 
              <br />
              (Max  length 200 characters)
              </p>
            </div>
            {showImmutableKeyValue && (
              <>
            <AddTextFieldComponent
              inputFields={inputImmutableFields}
              setInputFields={setImmutableInputFields}
              setcheckFieldDuplicate={setcheckFieldDuplicate}
              checkFieldkeyDuplicate={checkFieldkeyDuplicate}
              setFieldIndex={setFieldIndex}
              fieldIndex={fieldIndex}
              OpenAlertModal={OpenAlertModal}
              setOpenAlertModal={setOpenAlertModal}
              setAlertContent={setAlertContent}
            />
              </>
            )}

          
          </div>
          <div className="ml-4 columns-md">
            <AssetSchema
              isMediaSelect={isMediaSelect}
              setIsMediaSelect={setIsMediaSelect}
              schemaUploadDiv={schemaUploadDiv}
              setschemaUploadDiv={setschemaUploadDiv}
              UploadSchemaHandler={UploadSchemaHandler}
              setshowUploadedSchema={setshowUploadedSchema}
              showUploadedSchema={showUploadedSchema}
              setuploadedSchemaSrc={setuploadedSchemaSrc}
              uploadedSchemaSrc={uploadedSchemaSrc}
              OrignalImgUrlRef={OrignalImgUrlRef}
              setSelectedSchemaFileValue={setSelectedSchemaFileValue}
              ImageUploadInputRef={ImageUploadInputRef}
              croppedImgUrl={croppedImgUrl}
              showCroppingPopup={showCroppingPopup}
              setshowCroppingPopup={setshowCroppingPopup}
              crossImgClickHandler={crossImgClickHandler}
              selectedSchemaType={selectedSchemaType}
              setThumbnailSource={setThumbnailSource}
              thumbnailSource={thumbnailSource}
              assetCreationErrorMsgs={assetCreationErrorMsgs}
              setOpenAlertModal={setOpenAlertModal}
            />
          </div>
        </div>
        <div className={`flex justify-between items-center absolute -bottom-9 bg-[#060C07] w-full ${showCroppingPopup ? 'z-40' : 'z-50'} `}>
          <button
            className="btn-primary max-w-[494px] text-center  disabled:cursor-not-allowed"
            type="submit"
            disabled={
              !isValid || !isMediaSelect.status || checkFieldkeyDuplicate
            }
          >
            Next
          </button>
          <span
            className="px-10 text-base font-bold cursor-pointer py-3 hover:bg-[#A5F33C] hover:text-[#12221A] hover:rounded-full mr-10 "
            onClick={() => navigate("/asset-list")}
          >
            Cancel
          </span>
        </div>
      </form>
    </>
  );
};

export default ImmutableComponent;
