import { axiosClient } from "./apiClient";

/// all the axios http requests like get/post/put/delete

const getRequest = async (URL) => { 
	const response = await axiosClient.get(`/${URL}`, {});
	return response;
};

const postRequest = async (URL, payload) => {
	const response = await axiosClient.post(`/${URL}`, payload);
	return response;
};

const putRequest = async (URL, payload) => {
	const response = await axiosClient.put(`/${URL}`, payload);
	return response;
};

const deleteRequest = async (URL) => {
	const response = await axiosClient.delete(`/${URL}`);
	return response;
};

export { getRequest, postRequest, putRequest, deleteRequest };

// export default axiosMethods;
