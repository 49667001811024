import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import BasicTable from "../../../../components/BasicTable";
import { COLUMNS } from "../../../../components/ClientReporting/TransactionReporting/TransactionColumn";
import TransactionData from "../../../../data/transactionhistoryData.json";
import Select, { components } from "react-select";
import SearchFilter from "../../../../components/SearchFilter";
import { customStyles } from "../../../../components/ui-components/react-select-style/customStyle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import {
  Tab,
  TabBody,
  TabComponent,
  TabHeader,
  TabPanel,
} from "../../../../components/ui-components/tabs-component/tabs";
import moment from "moment";
import { images } from "./../../../../constants/images";

const TransactionListing = (props) => {
  const {
    transactionRecords,
    params,
    setParams,
    setOpenTab,
    openTab,
    startDate,
    endDate,
    setEndDate,
    setStartDate,
    flowPrice,
  } = props;
  const navigate = useNavigate();

  const PaymentSourceOptions = [
    { label: "All", value: null },
    { label: "Stripe", value: "1" },
    { label: "Blocto", value: "2" },
  ];

  const { SingleValue } = components;
  const SingleOption = (props) => (
    <SingleValue {...props} className="text-capitalize">
      {props.data.value ? (
        <>{props.data.label}</>
      ) : (
        <p className="text-base text-white">Payment Source</p>
      )}
    </SingleValue>
  );

  // React table suggests to memoize the columns and data for avoiding the re creation of data on every render

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => transactionRecords, [transactionRecords]);

  const getRowDataDetail = (transactionData) => {
    const data = { ...transactionData };

    navigate(`/tx-detail/${transactionData.purchaserName}`, {
      state: { data },
    });
  };

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
              flexGrow: 1,
            }}
          >
            {" "}
            Action
          </div>
        ),
        accessor: "Action",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="">
            <div
              className="flex justify-center cursor-pointer"
              onClick={() => getRowDataDetail(row.values)}
            >
              <img src={images.ActionEyeIcon} alt="image" />
            </div>
          </div>
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    gotoPage,
    pageCount,
    state, // For filtering
    setGlobalFilter, // For filtering
  } = useTable(
    {
      // ES6 shorthand notation having same key value name
      columns,
      data,
      initialState: {
        hiddenColumns: [
          "orderNumber",
          "description",
          "orderId",
          "assetVariationObject",
        ],
      },
    },
    tableHooks,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex } = state;

  const handleStartDateFilter = (e) => {
    setStartDate(e);
    let StartDate = moment(e).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    StartDate = moment(StartDate).toISOString();
    StartDate = StartDate.substring(0, StartDate.length - 5);
    setParams({ ...params, startDate: StartDate });
  };
  const handleEndDateFilter = (e) => {
    setEndDate(e);
    let EndDate = moment(e).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    EndDate = moment(EndDate).toISOString();
    setParams({ ...params, endDate: EndDate });
  };
  // console.log("first",params?.paymentSource)
  return (
    <>
      {/** tabs  */}
      <div className="border border-t-0 border-b border-l-0 border-r-0 mb-11 border-white/30">
        <TabComponent>
          <TabHeader>
            <div
              onClick={() =>
                setParams({
                  ...params,
                  success: true,
                  startDate: null,
                  endDate: null,
                })
              }
            >
              <Tab openTab={openTab} setOpenTab={setOpenTab} value={1}>
                Successful
              </Tab>
            </div>
            <div
              onClick={() =>
                setParams({
                  ...params,
                  success: false,
                  startDate: null,
                  endDate: null,
                })
              }
            >
              <Tab openTab={openTab} setOpenTab={setOpenTab} value={2}>
                Unsuccessful
              </Tab>
            </div>
          </TabHeader>
        </TabComponent>
      </div>
      {/** filters section start */}
      <div className="flex mb-9">
        <div className="flex-1 mr-6">
          <SearchFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>

        <div className="min-w-[167px] relative mr-6">
          {params?.paymentSource && (
            <label
              className={`text-xs text-grey-100    absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-5  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs`}
            >
              Payment Source
            </label>
          )}
          <Select
            styles={customStyles}
            components={{
              SingleValue: SingleOption,
            }}
            onChange={(e) => {
              setParams({ ...params, paymentSource: e.value });
            }}
            options={PaymentSourceOptions}
            defaultValue={[
              {
                label: "All",
                value: null,
              },
            ]}
          />
        </div>
        <div className="mr-6 min-w-[167px] relative">
          {startDate && (
            <label
              className={`text-xs text-grey-100    absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-5  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs`}
            >
              From Date
            </label>
          )}
          <DatePicker
            className="text-[15px] bg-[url('/src/assets/images/icons/calendar-icon.svg')] bg-no-repeat bg-right relative block w-full p-3 pl-0 pr-0 text-white bg-transparent border-t-0 border-b-2 border-l-0 border-r-0 border-[#A4B1A7] rounded-none outline-none placeholder:text-white text-md focus:ring-0 focus:border-b-white "
            selected={startDate}
            placeholderText="From Date"
            onChange={(e) => {
              handleStartDateFilter(e);
            }}
            name="PublicSaleStartDate"
            id="PublicSaleStartDate"
            dateFormat={"MMMM dd,YYY"}
          />
        </div>
        <div className=" min-w-[167px] relative">
          {endDate && (
            <label
              className={`text-xs text-grey-100    absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-5  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs`}
            >
              To Date
            </label>
          )}
          <DatePicker
            selected={endDate}
            className=" text-[15px] bg-[url('/src/assets/images/icons/calendar-icon.svg')] bg-no-repeat bg-right relative block w-full p-3 pl-0 pr-0 text-white bg-transparent border-t-0 border-b-2 border-l-0 border-r-0 border-[#A4B1A7]  rounded-none outline-none placeholder:text-white text-md focus:ring-0 focus:border-b-white "
            placeholderText="To Date"
            onChange={(e) => handleEndDateFilter(e)}
            name="PublicSaleStartDate"
            id="PublicSaleStartDate"
            dateFormat={"MMMM dd,YYY"}
          />
        </div>
      </div>
      {/** filters section end */}
      <div className="">
        <BasicTable
          columns={columns}
          data={data}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          page={page}
          nextPage={nextPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          prepareRow={prepareRow}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          pageCount={pageCount}
          rows={rows}
          defaultMessage={
            "You do not have any transactions history at the moment."
          }
        />
      </div>
    </>
  );
};

export default TransactionListing;
