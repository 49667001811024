import * as yup from "yup";

export const ClientThemeSchema = yup.object().shape({
  heroHeading: yup.string().required("Hero heading is required").matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
  .max(100, "Max  length 100 characters"),
 
  heroImage: yup
    .mixed()
    .test("required", "Hero image is required", (file) => {
    
      if (file && file[0]) return true;
      return false;
    })
    // .test("filesize", "Max file size allowed is 5MB", (value) => {
    //   return value && value[0]?.size <= 5000000;
    // })
    // .test("type", "JPG, PNG & GIF supported only", (value) => {
    //   console.log('type', value);
    //   return (
    //     value && value[0]?.type == ("image/png" || "image/gif" || "image/jpg" || "image/jpeg")
    //   );
    // })
    ,
  heroDescription: yup.string().required("Description is required").matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric") .max(200, "Max  length 200 characters"),
});
