import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";
import { images } from "../../../constants";

const ThemeInfoComponent = (props) => {
  const { desc, sm, title, themeImage ,backgroundText ,colorText } = props;
  const [openPopover, setOpenPopover] = useState(false);
  const triggers = {
    onMouseEnter: () => setOpenPopover(true),
    onMouseLeave: () => setOpenPopover(false),
  };

  return (
    <>
      <Popover
        placement={"right"}
        open={openPopover}
        handler={setOpenPopover}
        animate={{
          mount: { scale: 1, x: 25 },
          unmount: { scale: 0, x: 1 },
        }}
      >
        <PopoverHandler {...triggers}>
          <img
            className=" cursor-pointer ml-3"
            src={images.InfoIcon}
            alt="info-icon"
          />
        </PopoverHandler>

        <PopoverContent
          {...triggers}
          className="z-[100] bg-[#060C07]  p-0 rounded-2xl border-transparent border "
        >
          <div
            className={` ${
              sm ? "p-4" : "p-8"
            } font-manrope bg-[#060C07] border border-[#A4B1A7]/25  rounded-lg  max-w-[348px] `}
          >
            <h4
              className={`${
                sm ? "mb-1 text-base" : "mb-4  text-2xl"
              } font-bold text-white`}
            >
              {title}{" "}
            </h4>
            <ul class="list-disc text-grey-100 mb-4 text-xs ml-6">
              {desc.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <p className="text-xs text-white ">{backgroundText}</p>

            <img
              className=" mx-auto"
              src={themeImage}
              alt="icon"
            />
            <p className="text-xs text-white ">{colorText}</p>
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default ThemeInfoComponent;

// how to make unordered list in tailwind css rect js
