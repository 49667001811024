import React from "react";

const SwitchComponent = (props) => {

  const {fieldIndex, id, value, onChange } = props;
 
  return (
    <>
      <div class="inline-flex items-center">
        <div class="relative inline-block h-6 w-10 cursor-pointer rounded-full">
          <input
            onChange={(e) => onChange(e,fieldIndex, !value)}
            id={id}
            name={id}
            checked={value}
            type="checkbox"
            class="peer absolute h-6 w-10 right-0 border-[#A4B1A7]/25  focus:ring-offset-0 ring-transparent focus-visible:outline-0 focus-visible:outline-offset-0 focus-visible:ring-offset-0 focus:ring-transparent cursor-pointer  appearance-none rounded-full bg-[#12221A] transition-colors duration-300 checked:bg-[length:0px_0px] checked:bg-[#12221A] checked:border-[#A4B1A7]/25 peer-checked:before:border-[#A4B1A7] peer-checked:before:bg-[#12221A]"
          />
          <label
            for={id}
            class={`before:content[''] absolute top-2/4 left-[2px] h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-[#557C1E] bg-[#557C1E] shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-6 before:w-10  before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-transparent before:opacity-0 before:transition-opacity hover:before:opacity-10 ${
              value &&
              "  peer-checked:translate-x-[75%] peer-checked:border-[#A5F33C] peer-checked:bg-[#A5F33C] peer-checked:before:bg-transparent"
            }`}
          ></label>
        </div>
      </div>
    </>
  );
};

export default SwitchComponent;
