import { getRequest } from "../utils/api/axiosMethods";

const getDdScript = (modal) => {
	return getRequest(`BlockChain/get_script_from_db?scriptName=${modal.scriptName}`).then((response) => {
		return response;
	});
};


const blockchainScriptService = {
    getDdScript
  }

  export default blockchainScriptService;