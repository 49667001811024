import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    menuSelected: 0,
    submenuSelected:0,
    openSubMenu:""
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setMenuSelected: (state, action) => {
      state.menuSelected = action.payload;
    },
    setSubMenuSelected: (state, action) => {
        state.submenuSelected = action.payload;
      },
      setOpenSubMenu: (state, action) => {
        state.openSubMenu = action.payload;
      },
  },
});
export const { setMenuSelected ,setSubMenuSelected ,setOpenSubMenu } = sidebarSlice.actions;
export default sidebarSlice.reducer;