import React, { useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { COLUMNS } from "./claimWhitelistColumns";
import ClientHeaderComponent from "../ClientPortal/components/ClientHeaderComponent";
import SearchFilter from "../../components/SearchFilter";
import BasicTable from "../../components/BasicTable";

const ClaimListingTable = ({ defaultData }) => {
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => defaultData, [defaultData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    gotoPage,
    pageCount,
    state, // For filtering
    setGlobalFilter, // For filtering
  } = useTable(
    {
      // ES6 shorthand notation having same key value name
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex } = state;

  return (
    <>
      <ClientHeaderComponent title={"Whitelist"} />
      {/* Heading and button */}
      <div className="flex items-center justify-between mb-8">
        <div className="w-full">
          <SearchFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
      </div>

      <div>
        <BasicTable
          columns={columns}
          data={data}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          page={page}
          nextPage={nextPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          prepareRow={prepareRow}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          pageCount={pageCount}
          rows={rows}
          defaultMessage={
            "No items have been claimed so far... Check back later. "
          }
        />
      </div>
    </>
  );
};

export default ClaimListingTable;
