import axios from "axios";
import { setLoader } from "../redux/features/loading/loaderSlice";
import useRefreshToken from "../helpers/custom-hooks/useRefreshToken";

let configureStoreClientTransaction;
export const SetupTransactionInterceptors = (store) => {
  configureStoreClientTransaction = store;
};
const axiosClientTxInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_TRANSACTION,
});

axiosClientTxInstance.interceptors.request.use(
  function (config) {
    const token = sessionStorage?.getItem("persist:root") || [];
    const jwt = JSON.parse(token || null);
    const user = JSON.parse(jwt?.user || null) || [];
    config.headers.Authorization = user?.userInfo
      ? `Bearer ${user?.userInfo?.access_token}`
      : "";
    return config;
  },
  (error) => {
    return error;
  }
);

axiosClientTxInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { dispatch } = configureStoreClientTransaction;
    dispatch(setLoader(false));
    const originalConfig = error.config;
    if (error?.response?.status === 401 && !originalConfig?._retry) {
      originalConfig._retry = true;
      const { refreshToken } = useRefreshToken();
      await refreshToken(dispatch);
      return axiosClientTxInstance(originalConfig);
    }

    return Promise.reject(error);
  }
);



const getTransactionDetail = async (params) => {
  let payload = {
    clientId: params.clientId,
    paymentSource: params.paymentSource,
    fromDate: params.startDate,
    todate: params.endDate,
    isSuccess: params.success,
  };

  const response = await axiosClientTxInstance.post(
    `Order/get-transaction-detail-report`,
    payload
  );
  return response;
};

const getTransactionSummary = async (clientUuid) => {
  const response = await axiosClientTxInstance.get(
    `Order/get-transaction-summary-report?clientId=${clientUuid}`
  );
  return response;
};

export const ClientTxService = {
  getTransactionDetail,
  getTransactionSummary,
};
