import React, { useEffect, useState } from "react";
import BrandThemeImage1 from "./../../assets/images/Brand_Theme1.png";
import BrandThemeImage2 from "./../../assets/images/Brand_Theme2.png";
import btn_updated from "./../../assets/images/icons/updated_button.png";
import { useNavigate } from "react-router-dom";
import clientService from "./../../services/client.service";
import Spinner from "./../ui-components/loader-components/Spinner";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { update_menu_items_state } from "../../redux/features/menu/menuItemsSlice";
import authService from "./../../services/auth.service";
import BrandThemeCheckbox from "../ui-components/custom-checkbox/BrandThemeCheckbox";
import { clientDetailAction } from "../../redux/features/user/userSlice";
import { images } from "../../constants";

const KybBrandTheme = () => {
  const [loading, setLoading] = useState(false);
  const [selectTheme, setSelectTheme] = useState();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    // console.log(user.clientDetail);

    if (user.clientDetail.themeId) {
     
        setSelectTheme(user.clientDetail.themeId);
    }
  }, []);

  const handleShowDemo = () => {
    setSelectTheme(true);
  };
  const handleChangeTheme = (value) => {
    setSelectTheme(value);
  };

  const callDispatch = (payload) => {
    dispatch(update_menu_items_state(4));
    dispatch(clientDetailAction(payload));
    navigate("/onboarding/social-links");
  };

  const submitThemeForm = () => {
    // const body = {
    //     ThemeId: 1,
    //     stepsEnum: 4,
    // }
    setLoading(true);
    const formData = new FormData();
    formData.append("ClientUuid", user.clientUuid);
    formData.append("ThemeId", selectTheme);
    formData.append("stepsEnum", 4);

    clientService
      .updateClient(formData)
      .then((response) => {
        if (response.data.statusCodeValue === 200) {
          setLoading(false);
          
          callDispatch(response?.data?.payload);
          // console.log("Theme selected successfully!");
        }
      })
      .catch((err) => {
        // console.log(err);
        if (err.response.status === 401) {
          //     authService.refreshAccessToken(user?.userInfo?.refresh_token, user?.clientUuid);
          authService.logoutUser();
          navigate("/login");
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <h1 className="text-2xl font-bold">Brand Theme</h1>
      <p className="mt-1 mb-6 text-xs text-grey-100 max-w-[448px]">Please select one of the following themes.
      <br />
More customizability options are available in our paid plans. 
</p>

      <div className="flex items-center justify-start mb-12">
        
          <BrandThemeCheckbox
            value={1}
            media={images.ThemeDark}
            isChecked={selectTheme}
            onChange={() => handleChangeTheme(1)}
            style={'mr-6'}
            themeName="Drops Dark Theme"
           
          />
              <BrandThemeCheckbox
            value={2}
            media={images.ThemeDrop}
            isChecked={selectTheme}
            onChange={() => handleChangeTheme()}
            style={'mr-6'}
            disabled={true}
            themeName={'Drops Light Theme'}
          
          />
          <BrandThemeCheckbox
            value={2}
            media={images.ThemeMemberShip}
            isChecked={selectTheme}
            onChange={() => handleChangeTheme()}
            disabled={true}
            style={''}
            themeName={'Membership Theme'}
          
          />
         
        
      

        {/* <div className="relative mr-6 cursor-pointer">
          {selectTheme && (
            <img
              className="absolute left-4 -top-3"
              src={btn_updated}
              alt="btn_selected"
            />
          )}
          <img className="" src={BrandThemeImage1} alt="brand_theme1" />
          {!selectTheme && (
            <Link
              to="/storefront-demo1"
              target="_blank"
              className="btn-primary max-w-[130px] px-6 py-1 mx-8 absolute -bottom-3"
              onClick={() => {
                handleShowDemo();
              }}
            >
              View Demo
            </Link>
          )}
        </div> */}

        {/* <div className='relative opacity-100 cursor-not-allowed'>
                 <img className='' src={BrandThemeImage2} alt="brand_theme2" />
                    
                </div> */}
      </div>

      <button
        onClick={submitThemeForm}
        disabled={!selectTheme || loading}
        className="max-w-md mb-16 text-base btn-primary disabled:opacity-40 disabled:pointer-events-none"
      >
        {loading && <Spinner />} Save &amp; Continue
      </button>
    </div>
  );
};

export default KybBrandTheme;
