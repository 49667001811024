import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRouteClient = (props) => {
  const { clientDetail } = props;
  
  return (
    <>
      {clientDetail?.isClientApproved &&
      clientDetail?.capabilityTxId !== null ? (
        <Outlet />
      ) : (
        <>
          {clientDetail?.isClientApproved ? (
            <Navigate to="/create-capability" />
          ) : (
            clientDetail?.stepsCompleted ? <Navigate to='/onboarding/social-links' /> : <Navigate to="/onboarding" />
            
          )}
        </>
      )}
    </>
  );
};

export default ProtectedRouteClient;
