import * as yup from "yup";

export const FaviconSchema = yup.object().shape({
  favIconImg: yup.mixed().when("isFavIconExist", {
    is: true,
    then: yup.mixed(),
    otherwise: yup
      .mixed()
      .test("required", "File is required", (file) => {
        if (file[0]) return true;
        return false;
      })
      .test("fileSize", "File size is too large", (value) => {
        if (!value) return true;
        return value[0]?.size <= 50000;
      })

      .test("dimensions", "Image dimensions must be 16x16 pixels", (value) => {
        if (!value) return true; // Return true if no file is selected

        return new Promise((resolve) => {
          const image = new Image();
          console.log("check", value);
          if (value[0]) image.src = URL.createObjectURL(value[0]);

          image.onload = () => {
            resolve(image.width <= 16 && image.height <= 16);
          };
        });
      }),
  }),
});
