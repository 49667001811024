import React, { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";
import { images } from "../../../constants";
import BootStrapperLite from "../../ClientPackages/BootStrapperLite";
import BootStrapper from "../../ClientPackages/BootStrapper";
import Professional from "../../ClientPackages/Professional";

const SelectPackage = (props) => {
  const { packageName, platformFee, register, packageId  ,ClientPackagesData} = props;
  const [openPopover, setOpenPopover] = useState(false);
  const triggers = {
    onMouseEnter: () => setOpenPopover(true),
    onMouseLeave: () => setOpenPopover(false),
  };
 
  return (
 

      <div className=" bg-[#12221A] border-[#A4B1A7]   border rounded-2xl flex items-center justify-between text-white py-3 px-5 ">
        <div className="flex items-center flex-grow">
          <img className="mr-3" src={images.VisaCardIcon} alt="mastercard" />
          <div>
            <p className={`"font-semibold ${packageId === 1  ? 'text-white font-bold' : 'text-[#8D9D91]'}  max-[430px]:text-sm max-[430px]:mr-4"`}>
              {" "}
              {packageName}
            </p>
            <span className={`text-xs capitalize ${packageId === 1  ? 'text-white' : 'text-[#8D9D91]'}  uppercase`}>
              {`Platform Fee :  ${platformFee}%`}
            </span>
          </div>
        </div>
        <div className={` rounded-2xl   mr-10 ${packageId === 1 ? "hidden" : ""}`}>
          <div className="py-1 px-3 bg-[#A5F33C]  rounded-2xl">
            <p className="text-xs text-[#12221A] font-poppins">Coming soon</p>
          </div>
        </div>
        <Popover
        placement={"right"}
          open={openPopover}
          handler={setOpenPopover}
          animate={{
            mount: { scale: 1, x: 25 },
            unmount: { scale: 0, x: 1 },
          }}
        >
          <PopoverHandler {...triggers}>
            <div>
            <input
              checked={packageId === 1 ? true : false}
              {...register("package")}
              type={"radio"}             
              value={packageId}
              className={
                "bg-black ring-offset-0 cursor-pointer  focus:ring-offset-0 ring-transparent focus:ring-transparent  checked:border-[#A5F33C] checked:bg-[url('/src/assets/images/icons/active-check-icon.svg')]  rounded-full w-6 h-6 border-2 border-grey-100 mb-2"
              }
            />
            </div>
          </PopoverHandler>
          <PopoverContent {...triggers} className="z-[100] bg-[#060C07] p-0 rounded-2xl border-transparent border">
            {packageId === 1 &&
            <BootStrapperLite sm={true} ClientPackagesData={[ClientPackagesData?.[0]]} />
            }
            {packageId === 2 && <BootStrapper sm={true} ClientPackagesData={[ClientPackagesData?.[1]]} />}
            {packageId ===3 && <Professional sm={true} ClientPackagesData={[ClientPackagesData?.[2]]} />}
            
          </PopoverContent>
        </Popover>
      </div>

   
  );
};

export default SelectPackage;
