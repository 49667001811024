import React, { useEffect } from "react";

const AuthAlertComponent = (props) => {
  const { alert, setAlert ,alertmessageStatus } = props;
  useEffect(() => {
    let interval = setTimeout(() => {
        setAlert('')
    }, 4000);
  
    return () => {
      clearTimeout(interval)
    }
    //eslint-disable-next-line
  }, [alert])
  
  return (
    <>
      {alert !== "" && (
        <div>
          <p className={`text-red-500 ${alertmessageStatus === 'success' ? 'text-green-500' :''}`}>{alert} </p>
        </div>
      )}
    </>
  );
};

export default AuthAlertComponent;
