import React from 'react'
import { Link } from 'react-router-dom'

const PreviewFooter = () => {
  return (
    <>
     <div className="relative z-10 w-full bg-[#12221A]">
        <div className="xl:container  md:max-w-[1150px] px-4 lg:px-16 m-auto py-10 ">
          <div className="grid grid-flow-col grid-rows-1 gap-4 max-[850px]:grid-rows-2 ">
            <div className="col-span-8 text-white max-[850px]:col-span-12 ">
              <h2 className="text-xl font-semibold">
                Sign up to get the latest news
              </h2>

              <div className="flex items-center max-w-full mt-8 max-[850px]:mt-4">
                <div  className='position'>
                  <input
                    type="email"
                   
                    placeholder="Your Email"
                    className="relative py-3 text-white bg-transparent border border-solid max-sm:py-2 max-sm:px-4 px-7 pr-11 focus:border-white rounded-3xl placeholder:text-white border-neutral-700"
                  />

                  <button
                
                 
                    className="absolute px-6 py-3 pb-4 -ml-10 font-bold text-black transition-all bg-white border-transparent max-sm:py-2 disabled:cursor-default md:px-12 sm:px-7 rounded-3xl hover:bg-[#A5F33C] hover:text-black"
                  >
                 
                    Subscribe
                  </button>

               
                  <div className="ml-4">
                  {" "}
                 
                
                </div>
                </div>

               
              </div>
              <ul className="mt-10 max-[850px]:mt-5 max-sm:mt-3">
               
                  <li className="inline-block mr-6">
                    {/* <a className="text-white cursor-pointer hover:text-[#A5F33C]">Terms & Conditions</a> */}
                    <Link
                      to="#"
                      className="text-white transition-all cursor-pointer hover:text-[#A5F33C]"
                    >
                      Term of Services
                    </Link>
                  </li>
                
                
                  <li className="inline-block">
                    {/* <a className="text-white cursor-pointer hover:text-[#A5F33C]">Privacy Policy</a> */}
                    <Link
                      to="#"
                      className="text-white transition-all cursor-pointer hover:text-[#A5F33C]"
                    >
                      Privacy Policy
                    </Link>
                  </li>
              
              </ul>
            </div>
          
              <div className=" ml-auto max-[850px]:ml-0 max-[850px]:col-span-12 max-[850px]:mb-3 flex flex-col justify-end ">
                <ul className="text-right max-[850px]:text-left ">
                  <li className="inline-block mr-4 text-xl text-white align-middle max-md:text-base max-sm:mb-2">
                    Join Our Community
                  </li>
                
                
                </ul>
              
                <p className="mt-10 max-[850px]:mt-5 text-sm min-md:text-right max-sm:text-left textgrey-100">
                  © Bloomly{new Date().getFullYear()}. All rights reserved 
                  {/* <a className="hover:cursor-pointer hover:text-[#A5F33C]">
                    Privacy Policy
                  </a> */}
                </p>
              </div>
            
          </div>
        </div>
      </div>
    </>
  )
}

export default PreviewFooter