import * as yup from "yup";

export const addAssetSchema = yup.object().shape({
   assetType: yup.object()
   .shape({
     label: yup.string().required("Asset is required "),
     value: yup.string().required("Asset is required")
   }),
    assetName: yup.string().required("Asset name is required").matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
    .max(100, "Max  length 100 characters"),
	// assetRarity: yup.object()
  //   .shape({
  //     label: yup.string().required("Asset Rarity is required "),
  //     value: yup.string().required("Asset Rarity is required")
  //   }),
  //    assetCategory: yup.object()
  //    .shape({
  //      label: yup.string().required("Asset Rarity is required "),
  //      value: yup.string().required("Asset Rarity is required")
  //    }),
     assetDescription: yup.string().required("Asset description is required").matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
     .max(500, "Max  length 500 characters"),

});