import { useEffect, useState } from "react";
import axios from "axios";

function useUSDToFlow() {
	const [exchangeRate, setExchangeRate] = useState(null);

	useEffect(() => {
		async function fetchExchangeRate() {
			try {
				const response = await axios.get(
					// "https://api.coingecko.com/api/v3/simple/price?ids=flow&vs_currencies=usd"
					"https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=Flow"
				);
				// console.log("price response", { response });
				// const rate = 1 / response.data.flow.usd;
				const rate = response.data.FLOW;
				setExchangeRate(rate);
			} catch (error) {
				console.error(error);
			}
		}
		fetchExchangeRate();
	}, []);

	return exchangeRate;
}

export default useUSDToFlow;
