import React, { useEffect, useState, useReducer } from "react";
import { images } from "../../constants";

import * as fcl from "@onflow/fcl";
import { useSelector } from 'react-redux';
import Spinner from './../ui-components/loader-components/Spinner';
import { useNavigate } from 'react-router-dom';
import clientService from './../../services/client.service';
import { INITIAL_STATE, menuItemsReducer } from "./Utils/menuItemsReducer";
import { useDispatch } from 'react-redux';
import { update_menu_items_state, activate_all_menuItems } from "../../redux/features/menu/menuItemsSlice";
import {  clientDetailAction } from "../../redux/features/user/userSlice";
import Modal from "../../common/ModalLayout";
import AlertModalContent from "../Modals/AlertModalContent";
import WalletBalanceHook from "../../helpers/custom-hooks/FetchWalletBalance";


const KybConnectWallet = () => {

	const dispatch = useDispatch();

	const user = useSelector(state => state.user);
	
	const [loading, setLoading] = useState(false);
	console.log("user?.clientDetail?.clientWalletAddress", typeof(user?.clientDetail?.clientWalletAddress))
	const [wallet, setWallet] = useState(user?.clientDetail?.clientWalletAddress || null);
	const [checkWalletAssociation, setCheckWalletAssociation] = useState(false)
	const [alertModalContent, setAlertModalContent] = useState({ title: "", description: "" });
	const [clientWalletBalance, setClientWalletBalance] = useState('')
	
	
	const navigate = useNavigate();

	const handleBloctoConnect = async () => {
	
		setLoading(true);
		const response = await fcl.authenticate();
		if (response.loggedIn && response.addr === user?.clientDetail?.clientWalletAddress) {
			setLoading(false);
			callDispatch(user?.clientDetail); 
		} else {
			if(response.loggedIn && response.addr){
				submitConnectWalletForm(response.addr);
				setLoading(true);
			}else{
				setLoading(false);
			}
		
		}
	}

	const handleBloctoDisconnect = () => {
		
		fcl.unauthenticate();
		setWallet(null)
		setCheckWalletAssociation(false)
	}

	const callDispatch = (payload) => {
		dispatch(update_menu_items_state(1));
		dispatch(clientDetailAction(payload));
		navigate('/onboarding/brand-details');
	}

	useEffect(() => {
		getWalletBalance();
		// EDITING MODE
		if (user.clientDetail.isClientApproved) {
			// handleBloctoDisconnect();
			// console.log("Approved User")
			setWallet(user.clientDetail.clientWalletAddress);
			dispatch(activate_all_menuItems("activate"));
			fcl.currentUser().subscribe(user.clientDetail.clientWalletAddress);
		} else {
			// FIRST TIME KYB PROCESS
			if (user?.clientDetail?.clientWalletAddress == null) {
				handleBloctoDisconnect();
			} else if (user?.clientDetail?.clientWalletAddress !== null) {
				// console.log("2nd if else");
				// setWallet(user?.clientDetail?.clientWalletAddress);
				fcl.currentUser().subscribe(user.clientDetail.clientWalletAddress);
			} else {
				fcl.currentUser().subscribe(user.clientDetail.clientWalletAddress);
			}
		}
       //eslint-disable-next-line
	}, []);

	const submitConnectWalletForm = (walletAddress) => {

		const formData = new FormData();
		formData.append("ClientUuid", user.clientUuid);
		formData.append("ClientWalletAddress", walletAddress);
		formData.append("stepsEnum", 1);

		clientService.updateClient(formData).then((response) => {
			if (response.data.statusCodeValue === 200) {
				setLoading(false);
				console.log("Wallet Connected successfully and step 1 completed!");
				callDispatch(response.data.payload);
				// navigate('/onboarding/brand-details');
				// dispatch({ type: "UPDATE_MENU_ITEM_STATE", payload: 1 })
			}
		}).catch((err) => {
			setLoading(false);
			
			if(err?.response?.status === 400){
				if(err?.response?.data?.message?.includes("is already associated")){
					setCheckWalletAssociation(true)
					setAlertModalContent({title:'Error', description: err?.response?.data?.message ?? 'something went wrong' ,btnTitle:"Close",alertHander: true})
				}
			}
		
		
		
		})
	}
	const handleClosingAlertModal = () => {
		fcl.unauthenticate();
		setWallet(null)
		setCheckWalletAssociation(false)
	}

	const getWalletBalance =async () => {
		const{getWalletBalance} = WalletBalanceHook();
		const balance  = await  getWalletBalance(user?.clientDetail?.clientWalletAddress);
		setClientWalletBalance(balance)
	   
		
	  }


	return (
		 <>
		<div>
			<h1 className="text-2xl font-bold">Connect your Blocto wallet </h1>
			<p className="mt-1 mb-6 text-xs text-grey-100 max-w-[448px]">Click below to create your Blocto NFT wallet. This Blocto wallet will be used to sign in after your KYB process is approved by Bloomly. </p>

			

			{/* BLOCTO BUTTON */}

			<div className="flex items-center justify-between max-w-md mb-10">
				<div className='rounded-xl flex-grow p-[1.5px] background-border-rad  mr-2'>
					<div className='  flex items-center justify-start flex-grow px-8 py-4 bg-[#12221A] rounded-xl bg-opacity-80'>
						<img src={images.blocto} alt="blocto_icon" />
						<div className="flex flex-col flex-1 ml-6">
							<h5 className="text-base font-bold text-white capitalize" > {wallet ? `${clientWalletBalance} Flow` :"Blocto"}  </h5>
							<span className="text-xs text-grey-100">{wallet ? wallet : 'Your entrance to the blockchain world'}  </span>
							
						</div>
					{!user.clientDetail.isClientApproved && (<>{wallet  && <span className="ml-1 text-xs text-[#A5F33C] cursor-pointer" onClick={handleBloctoDisconnect}>  Disconnect</span>} </> )}	
					</div>
				</div>
			</div>
			{/* BLOCTO BUTTON */}

			<button disabled={loading || user.clientDetail.isClientApproved  } className='max-w-md text-base btn-primary disabled:cursor-not-allowed'
				onClick={() => {checkWalletAssociation ? handleBloctoDisconnect() :handleBloctoConnect()} }>
				{loading && <Spinner />}
				{loading ? "Connecting..." :checkWalletAssociation ? 'Disconnect' : "Connect Wallet"}
			</button>



		</div>
		{checkWalletAssociation && (
        <Modal
          modalVisible={checkWalletAssociation}
          closeModal={setCheckWalletAssociation}
          children={
            <AlertModalContent
              message={alertModalContent}
			  btnHandler={handleClosingAlertModal}
            />
          }
          modal_Id="Delete_Modal"
       
        />
      )}
		 </>
	);
};

export default KybConnectWallet;
