import { images } from "../../../constants";
import DateFormat from "../../../helpers/DateFormat";

export const COLUMNS = [
  {
    Header: "Asset Name",
    Footer: "Asset Name",
    accessor: "assetName",
  },
  {
    Header: "Purchaser Name",
    Footer: "Purchaser Name",
    accessor: "purchaserName",
  },
  {
    Header: "Total Price",
    Footer: "Total Price",
    accessor: "totalPriceUsd",
    Cell: ({ row }) => <>{<p>{`$${row?.values?.totalPriceUsd}`}</p>}</>,
  },
  {
    Header: "Platform Fee",
    Footer: "Platform Fee",
    accessor: "platformFee",
    Cell: ({ row }) => (
      <>
        {
          <p>
            {row?.values?.platformFee ? `$${row?.values?.platformFee}` : "-"}
          </p>
        }
      </>
    ),
  },
  {
    Header: "Order Date",
    Footer: "Order Date",
    accessor: "orderDate",
    Cell: ({ row }) => (
      <>
        {
          <p>
            {row?.values?.orderDate ? (
              <DateFormat date={row?.values?.orderDate} />
            ) : (
              "-"
            )}
          </p>
        }
      </>
    ),
  },

  {
    Header: "Payment Source",
    Footer: "Payment Source",
    accessor: "paymentProcessor",
    Cell: ({ row }) => (
      <>
        {
          <img
            className="cursor-pointer "
            src={
              row?.values?.paymentProcessor === "Blocto"
                ? images.BloctoIcon
                : images.StripeIcon
            }
            alt="icon"
          />
        }
      </>
    ),
  },
  {
    Header: "Transaction Status",
    Footer: "Transaction Status",
    accessor: "transactionHash",
    Cell: ({ row }) => (
      <>
        {row?.values?.paymentProcessor === "Blocto" ||
        row?.values?.paymentProcessor === "Stripe" ? (
          <a
            target="_blank"
            rel="noreferrer"
            href={
              process.env.REACT_APP_ACCESS_NODE?.includes("testnet")
                ? `${process.env.REACT_APP_FLOW_TESTNET_STORAGE_TRANSACTION_LINK}${row?.values?.transactionHash}`
                : `${process.env.REACT_APP_FLOW_MAINNET_STORAGE_TRANSACTION_LINK}${row?.values?.transactionHash}`
            }
            className=""
          >
            <img
              className="cursor-pointer "
              src={images.FlowChainIcon}
              alt="icon"
            />{" "}
          </a>
        ) : (
          <></>
        )}
      </>
    ),
  },
  {
    Header: "orderId",
    Footer: "orderId",
    accessor: "orderId",
  },

  {
    Header: "AssetDescription",
    Footer: "AssetDescription",
    accessor: "description",
  },

  {
    Header: "assetType",
    Footer: "assetType",
    accessor: "assetVariationObject",
  },
];
