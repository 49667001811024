import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import BootStrapper from '../../../components/ClientPackages/BootStrapper'
import BootStrapperLite from '../../../components/ClientPackages/BootStrapperLite'
import Professional from '../../../components/ClientPackages/Professional'
import useClientPackages from '../../../helpers/custom-hooks/useClientPackages'
import { setLoader } from '../../../redux/features/loading/loaderSlice'

const PackagesContent = () => {
  const { fetchClientAllPackages } = useClientPackages();
  const [ClientPackagesData, setClientPackagesData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    getAllClientPackages();
    // eslint-disable-next-line
  }, []);
  const getAllClientPackages = async () => {

    const packagesReslt = await fetchClientAllPackages();
    if (packagesReslt) {
      setClientPackagesData(packagesReslt);
      // dispatch(clientPackages(packagesReslt))
    }
    else
      dispatch(setLoader(false));
  }
  return (
    <>

        <div className="flex items-center justify-between mb-10">
          <h1 className="text-2xl font-bold text-white">Packages</h1>
        </div>
        {ClientPackagesData?.length > 0 && (
          <div className='grid grid-cols-3 gap-2'>
            <div>
              <BootStrapperLite ClientPackagesData={[ClientPackagesData?.[0]]} />
            </div>
            <div>
              <BootStrapper ClientPackagesData={[ClientPackagesData?.[1]]} />
            </div>
            <div>
              <Professional ClientPackagesData={[ClientPackagesData?.[2]]} />
            </div>

          </div>
        )}
    
    </>
  )
}

export default PackagesContent