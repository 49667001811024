import * as yup from "yup";

export const RoyaltyAssetSchema = yup.object().shape({
  royalty: yup.array().of(
    yup.object().shape({
      royaltyActive: yup.bool(),
      name: yup.string().when("royaltyActive", {
        is: true,
        then: yup
          .string()
          .required("Name is required")
          .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumeric")
          .max(100, "Max length 100 characters"),
        otherwise: yup.string().notRequired(),
      }),
      receiverAddress: yup.string().when("royaltyActive", {
        is: true,
        then: yup
          .string()
          .required("Address is required")
          .matches(
            /^0x(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/,
            "Invalid Address"
          )
          .max(18, "Max length 18 characters"),
        otherwise: yup.string().notRequired(),
      }),
      royaltyPercentage: yup.mixed().when("royaltyActive", {
        is: true,
        then: yup
          .number()
          .min(1, "Number must be greater than or equal to 1")
          .max(100, "Number must be less than or equal to 100")
          .required("Amount is required")
          .typeError("Please enter royalty in number")
          .positive("Number must be positive and greater than zero")
          .test("is-decimal", "Maximum two decimals allowed", (value) =>
            (value + "").match(/^\d+(?:\.\d{0,2})?$/)
          ),
        otherwise: yup.mixed().notRequired(),
      }),
      description: yup.string().when("royaltyActive", {
        is: true,
        then: yup
          .string()
          .required("Description is required")
          .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumeric")
          .max(400, "Max length 400 characters"),
        otherwise: yup.string().notRequired(),
      }),
    })
  ),
});