import React from "react";

const ReviewDropDescription = (props) => {
  const { title, value } = props;
  return (
    <>
      {/* Asset price Hide */}
      <div className="mr-6 min-w-[170px]">
        <p className="text-xs text-grey-100">{title}</p>
        <p className="text-base">{value}</p>
      </div>
    </>
  );
};

export default ReviewDropDescription;
