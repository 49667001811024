import { useRef, useState } from "react";

const useCopyUrl = () => {
  const copyAddress = useRef();
  const [showCopiedText, setShowCopiedText] = useState(false);

  const copyText = async () => {
    debugger;
    await navigator.clipboard
      .writeText(copyAddress.current?.getAttribute("value")) //copyAddress.current.getAttribute("value"))
      .then(() => {
        setShowCopiedText(true)
        const interval = setTimeout(() => {
          setShowCopiedText(false);
          clearInterval(interval);
        }, 3000);
      })
      .catch(() => {});
  };
  return {
    copyAddress,
    copyText,
    showCopiedText
  };
};
export default useCopyUrl;
