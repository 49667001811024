import React from 'react'
import PreviewHeader from './PreviewHeader';
import PreviewFooter from './PreviewFooter';
import Circle from './Circle';
import { Outlet } from 'react-router-dom';

const PreviewLayout = ({children}) => {
  return (
    <>
            <main className="relative min-h-screen font-poppins">
                <div className="relative overflow-hidden min-h-screen bg-no-repeat bg-cover bg-[center_32rem] bg-[url('/src/assets/images/icons/lines-layout.png')]">
                    <PreviewHeader />
                    <Circle
                        width="w-[562px]"
                        height={"h-[562px]"}
                        bottom={"-top-[300px]"}
                        right={"-left-[110px]"}
                        background={"bg-[#144FF5]"}
                    />
                    <Circle
                        width="w-[562px]"
                        height={"h-[562px]"}
                        bottom={"top-[490px]"}
                        right={"left-[12px]"}
                        background={"bg-[#EC5676]"}
                    />
                    <Circle
                        width="w-[562px]"
                        height={"h-[562px]"}
                        bottom={"top-[1190px]"}
                        right={"-left-[122px]"}
                        background={"bg-[#EC5676]"}
                    />
                    <Circle
                        width="w-[550px]"
                        height={"h-[550px]"}
                        bottom={"top-[195px]"}
                        right={"-right-[70px]"}
                        background={"bg-[#F7C644]"}
                    />
                    <Circle
                        width="w-[550px]"
                        height={"h-[550px]"}
                        bottom={"-bottom-[195px]"}
                        right={"-right-[70px]"}
                        background={"bg-[#59C27B]"}
                    />
                    {children}
                    <PreviewFooter />
                </div>
            </main>
    </>
  )
}

export default PreviewLayout