import {
    deleteRequest,
    getRequest,
    postRequest,
  } from "../utils/api/axiosMethods";

  const AddCategory=(categoryName)=>{
    const payload = {
        categoryName : categoryName
    }
    return postRequest(`Category/add-categories`,payload).then((response) => {
		return response;
	});
}
const GetCategoriesList=()=>{
    return getRequest(`Category/categories`).then((response) => {
		return response;
	});
}
const DeleteCategory=(id)=>{
    const payload= {
        categoryId:id
    }
    return postRequest(`Category/delete-categories`,payload).then((response) => {
		return response;
	});
}

const UpdateCategory=(id ,name)=>{
    const payload= {
        categoryId: id,
        categoryName: name
    }
    return postRequest(`Category/update-categories`,payload).then((response) => {
		return response;
	});
}


  export const CategoryService = {
    AddCategory,
    GetCategoriesList,
    DeleteCategory,
    UpdateCategory
  }
