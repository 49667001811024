import React, { useEffect, useState } from "react";


import { Link, Outlet, useNavigate } from "react-router-dom";
import Circle from "../components/Circle";
import { useSelector } from "react-redux";
import { images } from "../constants/images";

const Login = () => {
  const navigate = useNavigate();
  const [alert, setAlert] = useState("");
  const { isLoggedIn, userRole, clientDetail } = useSelector(
    (state) => state.user
  );


  useEffect(() => {
    if (isLoggedIn) {
      if (userRole === "Client") {
        if (Object.entries(clientDetail).length === 0) return;

        if (clientDetail?.isClientApproved === true) {
          if (clientDetail?.stepsCompleted) {
            navigate("/client-dashboard");
            return;
          } else {
            navigate("/onboarding");
            return;
          }
        } else {
          
          navigate('/client-dashboard');
          
      }
      }
      else if (userRole?.toLowerCase() === "super admin"){
        navigate("/dashboard");
        return;
      } 
      else {
        navigate("/login");
        return;
      }
    }
    //eslint-disable-next-line
  }, [isLoggedIn, userRole, clientDetail?.isClientApproved]);

  const handleLogin = () => {
    return navigate("/dashboard");
  };

  const inputFieldStyle = `placeholder:text-white outline-none border-t-0 border-r-0 border-l-0 border-b-1 border-[#A4B1A7] rounded-none pl-0 pr-7 text-white text-md
	focus:ring-0 focus:border-b-[#A4B1A7] block w-full p-2.5 bg-transparent`;

  return (
    <>
      <section className="h-screen bg-black font-manrope">
        <div className="relative h-full  overflow-hidden bg-[url('/src/assets/images/login-bg.png')] bg-no-repeat bg-[length:100%_100%] bg-center ">
          <div className="flex flex-wrap items-center justify-start h-full min-h-screen text-gray-800 sm:justify-center g-6">
            <Link to="/">
              <img
                className="absolute z-20 mb-3 top-3"
                src={images.BloomlyLogo}
                alt="logo"
              />
            </Link>
           

            <Outlet
              context={{ inputFieldStyle, handleLogin, setAlert, alert }}
            />

            <div className="mb-12 md:w-8/12 lg:w-6/12 md:mb-0 lg:ml-40">
              {/* <div className="rounded-xl skew-y-3 h-auto mx-auto mt-10 bg-gradient-to-l p-[3px] from-[#59C27B] via-[#F7C644] via-[#EC5676] to-[#144FF5]"> */}
              {/* <div className="flex flex-col justify-between h-full p-10 text-white bg-black rounded-lg"> */}
              <img src={images.login_image} className="w-11/12" alt="NFT Icon" />
              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
