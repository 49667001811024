import { images } from "../../../../../constants";

const DropSearch = ({SearchAssetByNameHandler}) => {
    return (
        <div className="mb-8 flex items-center flex-grow py-3 px-4 rounded-full bg-[#121212] border-2 border-zinc-700 min-w-[80px] ">
            <img className="mr-2" src={images.searchIcon} alt="search" />
            <input
                className="w-full p-0 bg-transparent border-none placeholder:text-white focus:ring-transparent"
                placeholder="Search Assets"
                type="text"
                onChange={SearchAssetByNameHandler}
            />
        </div>
    );
}
export default DropSearch;