import { postRequest } from '../utils/api/axiosMethods';

const claimTransactionsReportList = (payload) => {
	return postRequest(`Airdrop/get-claim-transaction-report`, payload)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
};

const AirdropService = {
	claimTransactionsReportList,
};

export default AirdropService;
