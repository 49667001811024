import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_rounded from "highcharts-rounded-corners";
import { useDispatch, useSelector } from "react-redux";
import Transactions from "../../Blockchain-transactions/BlockchainTransactions";
import KeyValueView from "../../components/ui-components/views-component/KeyValueView";
import BlockchainScriptHook from "../../helpers/custom-hooks/BlockchainScriptHook";
import { setLoader } from "../../redux/features/loading/loaderSlice";
import ClientHeaderComponent from "./components/ClientHeaderComponent";
import { options } from "../../helpers/charts-options/ClientDashboardOption";
import moment from "moment";
import { images } from "../../constants";
import AssetService from "../../services/asset.service";
import { Tooltip } from "@material-tailwind/react";
import Modal from "../../common/ModalLayout";
import ConnectStripeModal from "../../components/Modals/ConnectStripeModal";
import { useLocation } from "react-router-dom";
import clientStripeService from "../../services/clientstripe.service";

HC_rounded(Highcharts);

const ClientDashboard = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Access  query parameters
  const IsStripeLinkExpired = queryParams.get("IsStripeLinkExpired");
  const IsStripeDetailsSubmitted = queryParams.get("IsStripeDetailsSubmitted");

  const [assetDetails, setAssetDetails] = useState();
  const [stripeInfo, setStripeInfo] = useState();
  console.log(
    "🚀 ~ file: ClientDashboard.js:37 ~ ClientDashboard ~ stripeInfo:",
    stripeInfo
  );
  const [clientStripeAccountInfo, setClientStripeAccountInfo] = useState();
  console.log(
    "🚀 ~ file: ClientDashboard.js:40 ~ ClientDashboard ~ clientStripeAccountInfo:",
    clientStripeAccountInfo
  );
  const [openConnectStripeModal, setOpenConnectStripeModal] = useState(false);
  const [openStripeConnectLink, setOpenStripeConnectLink] = useState({
    openLink: false,
    url: "",
  });

  const clientInfo = useSelector((state) => state?.user?.clientDetail);
  // console.log("client info::", clientInfo);
  const { getBlockChainScriptFromDB } = BlockchainScriptHook();

  const getClientInfo = async () => {
    checkClientCapability(clientInfo?.clientWalletAddress);
  };
  const checkClientCapability = async (clientAddress) => {
    const cap_ScriptModal = {
      scriptName: 5,
    };
    const createCapModal = {
      scriptName: 4,
    };
    const cap_DbScript = await getBlockChainScriptFromDB(cap_ScriptModal);

    const scriptResult = await Transactions.ExecuteBrandsCapabilityScript(
      cap_DbScript,
      clientAddress
    );

    if (!scriptResult?.error && !scriptResult?.message) {
      dispatch(setLoader(true));
      const dbScript = await getBlockChainScriptFromDB(createCapModal);
      const resultData = await Transactions.BrandMultiSignTransaction(dbScript);

      if (!resultData?.error) {
        //alert("Capability creation failed")
        dispatch(setLoader(false));
      }
    } else {
      dispatch(setLoader(false));
    }
  };
  useEffect(() => {
    getAssetsCount();
    getStripeAccountInfo();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (IsStripeLinkExpired) {
      GetClientStripeAccountUrl();
    }
    if (IsStripeDetailsSubmitted) {
      getStripeAccountInfo();
    }
    //eslint-disable-next-line
  }, [IsStripeLinkExpired, IsStripeDetailsSubmitted]);

  /** function to get stripe account information tell us about status of account and stripe account id */

  const getStripeAccountInfo = () => {
    dispatch(setLoader(true));
    if (clientInfo?.clientUuid) {
      clientStripeService
        .getStripeInfo(clientInfo?.clientUuid)
        .then((response) => {
          dispatch(setLoader(false));
          if (response?.data?.payload) {
            let verify = response?.data?.payload?.isAccoundVerified;
            setStripeInfo(response?.data?.payload);
            if (!verify) {
              getClientStripeAccountInfo(verify);
            }
          }
        })
        .catch((err) => {
          dispatch(setLoader(false));
        });
    }
  };

  /** function to get client stripe account information tell us about client detail sunmit and other info */

  const getClientStripeAccountInfo = (verify) => {
    dispatch(setLoader(true));
    if (clientInfo?.clientUuid) {
      return clientStripeService
        .getClientStripeAccountInfo(clientInfo?.clientUuid)
        .then((response) => {
          dispatch(setLoader(false));
          if (response?.data?.payload) {
            setClientStripeAccountInfo(response?.data?.payload);
            let stripeid = response?.data?.payload.id;
            let detailsSubmitted = response?.data?.payload?.detailsSubmitted;
            if (!verify && !detailsSubmitted) {
              GetClientStripeAccountUrl();
            }
            if (!verify && detailsSubmitted) {
              UpdateClientStripeAccount(stripeid);
            }
          }
        })
        .catch((err) => {
          dispatch(setLoader(false));
        });
    }
  };

  const getAssetsCount = () => {
    dispatch(setLoader(true));
    if (clientInfo?.clientUuid) {
      AssetService.assetsCount(clientInfo?.clientUuid)
        .then((response) => {
          dispatch(setLoader(false));
          if (response?.data?.statusCode === 200) {
            setAssetDetails(response?.data?.payload);
          }
        })
        .catch((err) => {
          dispatch(setLoader(false));
        });
    }
  };

  /** function to get Url for client for onboard on stripe dashboard */
  const GetClientStripeAccountUrl = () => {
    dispatch(setLoader(true));
    if (clientInfo?.clientUuid) {
      clientStripeService
        .getClientStripeAccountUrl(clientInfo?.clientUuid)
        .then((response) => {
          dispatch(setLoader(false));
          if (response?.data?.payload?.url) {
            // setOpenConnectStripeModal(true);
            setOpenStripeConnectLink({
              openLink: false,
              url: response?.data?.payload?.url,
            });
          }
        })
        .catch((err) => {
          dispatch(setLoader(false));
        });
    }
  };

  /** function to update the client status of stripe account */

  const UpdateClientStripeAccount = (stripeId) => {
    dispatch(setLoader(true));
    clientStripeService
      .updateClientStripeAccount(clientInfo?.id, stripeId)
      .then((response) => {
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };

  const handleConnectStripeAccount = (values) => {
    dispatch(setLoader(true));
    const modal = {
      ClientId: clientInfo?.id,
    };
    clientStripeService
      .createClientStripeAccount(modal)
      .then((response) => {
        dispatch(setLoader(false));
        if (response?.data?.payload) {
          setOpenStripeConnectLink({
            openLink: true,
            url: response?.data?.payload?.url,
          });
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };

  // how to give radical gradient border in tailwind css react js
  return (
    <>
      <ClientHeaderComponent title={"Dashboard"} />
      {/* Todo: Future changes need to be uncommented after exclusible demo */}
      {/* Stripe Functionality for exclusible we hide for the time being */}
      <div className="my-8">
        <div className="p-3 border-[#A4B1A7]/25 border rounded-lg bg-[#12221A]/30 max-w-[342px]">
          <div className="flex justify-between items-center mb-1">
            <h3 className="text-white text-[20px]  font-bold">
              Connect Your Stripe Account
            </h3>
            {stripeInfo?.isAccoundVerified ? (
              <>
                <span className="text-[#B0E577] text-xs font-bold ">
                  <img
                    className="inline align-sub  mr-1"
                    src={images.ApprovedSmIcon}
                    alt="icon"
                  />
                  Completed
                </span>
              </>
            ) : (
              <>
                <span className="text-[#E7E15A] text-xs font-bold ">
                  <img
                    className="inline align-sub mr-1"
                    src={images.PendingIcon}
                    alt="icon"
                  />
                  Pending
                </span>
              </>
            )}
          </div>
          <p className="text-white text-xs mb-8">
            {stripeInfo?.clientStripeAccountId ? (
              <>
                <a
                  className="underline"
                  href={"https://dashboard.stripe.com/"}
                  target="_blank"
                >
                  {stripeInfo?.clientStripeAccountId}
                </a>
              </>
            ) : (
              "To accept fiat payments on your storefront, please connect your Stripe account."
            )}
          </p>
          {!stripeInfo?.isAccoundVerified && (
            <>
              <button
                className="btn-primary"
                onClick={
                  clientStripeAccountInfo?.detailsSubmitted
                    ? getStripeAccountInfo
                    : handleConnectStripeAccount
                }
              >
                {clientStripeAccountInfo?.detailsSubmitted
                  ? "Refresh"
                  : "Connect Stripe"}
              </button>
            </>
          )}
        </div>
      </div>

      <div className="background-border-rad p-[1px] rounded-full ">
        <div className="flex justify-between bg-[#060C07] p-6 rounded-full  ">
          <div className="">
            <KeyValueView
              title={"Brand Name"}
              value={clientInfo?.clientName ?? "-"}
            />
          </div>

          <div className="">
            <KeyValueView
              title={"Domain Name"}
              value={clientInfo?.clientDomainName ?? "-"}
              path={true}
              style={"font-bold underline"}
              media={images.LinkIcon}
              mainStyle={"mr-8"}
            />
          </div>
          <div className="">
            <KeyValueView
              title={"Status"}
              value={clientInfo?.isClientApproved ? "Approved" : "-"}
              success={true}
            />
          </div>
          <div className="">
            <KeyValueView
              title={"Asset Created"}
              value={
                assetDetails?.totalAssetsCount || assetDetails?.maxLimit
                  ? `${assetDetails?.totalAssetsCount}/${assetDetails?.maxLimit}`
                  : assetDetails === null
                  ? `0/${clientInfo?.clientPackageResponse?.defaultNFtLimit}`
                  : "-"
              }
            />
          </div>
          {/* <div className="">
            <KeyValueView
              title={"Wallet Balance"}
              value={clientWalletBalance ? `${clientWalletBalance} Flow  ` : "-"}
              success={true}
              mainStyle={"mr-8"}
            />
          </div> */}
          <div className="">
            <KeyValueView
              title={"Date Created"}
              value={
                clientInfo?.clientCreatedDate
                  ? moment(clientInfo?.clientCreatedDate).format("DD MMM, YYYY")
                  : "-"
              }
            />
          </div>
        </div>
      </div>
      <Tooltip
        className="text-[white] text-base bg-[#12221A]  border border-[#12221A]  relative z-30 rounded max-w-[200px]"
        offset={10}
        placement={"top-center"}
        content={"Coming soon "}
      >
        <div className="mt-8 background-border-rad rounded-[32px] p-[1px] opacity-[0.5]">
          <div className="bg-[#060C07] rounded-[32px] relative">
            <HighchartsReact
              className="rounded-full"
              highcharts={Highcharts}
              options={options}
            />
            <div className="absolute flex flex-col top-6 right-16">
              <span className="mb-1 text-xs text-white/70">
                <img
                  className="inline mr-2 align-text-top"
                  src={images.DroplegendIcon}
                  alt="icon"
                />
                Drops
              </span>
              <span className="text-xs text-white/70">
                <img
                  className="inline mr-2 align-text-top"
                  src={images.CollectionLegendIcon}
                  alt="icon"
                />
                Collections
              </span>
            </div>
          </div>
        </div>
      </Tooltip>
      {openStripeConnectLink?.openLink && (
        <Modal
          modalVisible={openStripeConnectLink?.openLink}
          closeModal={setOpenStripeConnectLink}
          isClose={true}
          children={
            <ConnectStripeModal
              clientInfo={clientInfo}
              openStripeConnectLink={openStripeConnectLink}
              setOpenStripeConnectLink={setOpenStripeConnectLink}
            />
          }
        />
      )}
    </>
  );
};

export default ClientDashboard;
