import { getRequest, postRequest } from "../utils/api/axiosMethods";
const sendAlertEmailToSuperAdmin=(emailModal)=>{
    return postRequest(`emailtemplate/arweave_token_alert`,emailModal).then((response) => {
      return response;
    });
    
  }
  const EmailService = {
    sendAlertEmailToSuperAdmin
    }
  
    export default EmailService;