import { useDispatch } from "react-redux";
import { configurationService } from "../../services/client.configuration.service";
import { setLoader } from "../../redux/features/loading/loaderSlice";

const GetClientRoyaltyData = () => {
    const dispatch = useDispatch()
  const getRoyaltyData = async (clientUuid) => {
    const result = await configurationService
      .getRoyaltyConfiguration(clientUuid)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const royaltyJson = response?.data.payload.result.clientRoyaltyJson;
          if (royaltyJson) {
            const addedroyaltyData = JSON.parse(royaltyJson);
            dispatch(setLoader(false));
            return addedroyaltyData;
          }
          dispatch(setLoader(false));
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
    return result;
  };
  return {
    getRoyaltyData
  };
};

export default GetClientRoyaltyData;
