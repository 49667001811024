import React from 'react'
import { useSelector } from 'react-redux';
import AppSpinner from '../components/ui-components/loader-components/AppSpinner';


const Loader = () => {

  const { loadingState ,loadingMessage} = useSelector(state => state.loader);
    
 
    return (
      <>
        <div
          className="sweet-loading"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 999999,
            width: "100vw",
            height: "100vh",
            backgroundColor: "#000",
            opacity: 0.6,
            display: loadingState ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
            flexDirection:"column"
          }}
        >
          
          {loadingState && <AppSpinner /> }  
         {loadingMessage && <p className='text-[#A5F33C] mt-2 mb-2 text-base'>{loadingMessage}</p> } 
        </div>
      </>
    );
}

export default Loader