import React, { useState } from "react";

import { images } from "../../constants";

import CopyIconView from "../ui-components/views-component/CopyIconView";
import useCopyUrl from "../../helpers/custom-hooks/useCopyUrl";

const ConnectStripeModal = (props) => {
  const { copyAddress,
    copyText,showCopiedText} = useCopyUrl()
  const { clientInfo, openStripeConnectLink, setOpenStripeConnectLink } = props;

  return (
    <>
      <h4 className="mb-4 text-2xl font-bold text-white">
        Create a Connected Account
      </h4>

      <>
        <div className="p-3 border border-[#A4B1A7]/25 rounded-xl flex justify-start items-start mb-4">
          <img className="mr-2" src={images.InputFieldInfoIcon} alt="icon" />
          <p className="text-xs text-white">
            This link can only be used once. If you’d like to onboard more
            users. request more links.
          </p>
        </div>
        <p className="text-white mb-8 text-xs">
          Send this one-time-use. Express onboarding link to your user. It will
          expire in seven days, but you can create another one if needed. Once
          they complete the flow, their account will be created and will show up
          in your accounts list.
        </p>
        <span className="text-grey-100 text-xs mb-1"> Link</span>
        <div ref={copyAddress} value={openStripeConnectLink?.url} className="border border-[#A4B1A7]/50 p-3 flex justify-start items-start rounded-xl mb-10">
          <a
            href={openStripeConnectLink?.url}
            className="text-white text-xs  break-all"
          >
            {openStripeConnectLink?.url}
          </a>
          <span className="ml-2 text-white hover:text-[#A5F33C] cursor-pointer" onClick={copyText}>
            {" "}
            {showCopiedText ? (
            <span className="text-xs text-[#A5F33C] ml-1 -mt-1">
              Copied 
            </span>
          ):(<CopyIconView  />)} 

          </span>
        </div>
        <a
          href={openStripeConnectLink?.url}
          className="btn-primary text-center"
        >
          Done
        </a>
      </>
    </>
  );
};

export default ConnectStripeModal;
