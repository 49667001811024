import React from "react";
import { Outlet } from "react-router-dom";
import Circle from "../components/Circle";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";

const Layout = () => {
  const inputFieldStyle = `placeholder:text-white  text-white outline-none border-t-0 border-r-0 border-l-0 border-b-1 border-[#A4B1A7] rounded-none pl-0 pr-7 text-white text-md
	focus:ring-0 focus:border-b-[#A4B1A7] block w-full p-2.5 bg-transparent`;
  return (
    <div>
      {/* <h1>Layout Page</h1> */}
      {/* <Dashboard /> */}
      {/* <section className="h-screen sm:h-full bg-[#1E1E1E]">
				<div className="container h-full bg-[#1E1E1E]">
                    
                </div>
			</section> */}
      <div className="relative flex flex-col overflow-x-hidden overflow-y-hidden antialiased text-gray-800 bg-[#060C07] bg-[url('/src/assets/images/main-dashboard-bg.png')] bg-no-repeat bg-[length:100%_100%]   font-manrope">
        {/* <Circle
					width="w-[562px]"
					height={"h-[562px]"}
					bottom={"-top-[300px]"}
					right={"-left-[110px]"}
					background={"bg-[#144FF5]"}
				/>
				<Circle
					width="w-[562px]"
					height={"h-[562px]"}
					bottom={"-bottom-[190px]"}
					right={"left-[12px]"}
					background={"bg-[#EA5677]"}
				/>
				<Circle
					width="w-[693px]"
					height={"h-[693px]"}
					bottom={"-bottom-[495px]"}
					right={"-right-[270px]"}
					background={"bg-[#F7C644]"}
				/> */}
        {/* <div className="flex w-full relative overflow-auto h-full px-6 py-8 bg-no-repeat bg-cover bg-black bg-[url('/src/assets/images/KYB_Background.png')]"></div> */}
        <div className="flex w-full">
          <Sidebar />
          <main className="relative flex flex-col flex-auto px-10 pb-10 pt-[22px] overflow-x-hidden overflow-y-auto text-white ml-[245px] w-fit ">
           
		   <div className=" grow">
		   <Outlet context={{ inputFieldStyle }} />
			</div> 
            <div className="">
              <Footer />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
