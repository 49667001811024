import React from "react";
import { images } from "../../../../../constants";
import stringTruncate from "../../../../../helpers/StringTruncate";
import { Tooltip } from "@material-tailwind/react";

const AssetCheckbox = (props) => {
  const { name, media, isChecked, onChange, templateType } = props;
  return (
    <>
      <div
        className={`d-inherit overflow-hidden p-[1px] max-w-[235px] h-[261px] ${
          isChecked
            ? "background-border-rad rounded-3xl"
            : "background-border-rad rounded-3xl"
        }`}
      >
        <label
          className={`p-4 relative bg-[#060C07] border-1 rounded-3xl custom-checkbox ${
            isChecked ? "border-transparent" : "border-zinc-700"
          }  `}
        >
          <input
            checked={isChecked}
            onChange={onChange}
            type={"checkbox"}
            className={
              "bg-black absolute z-10    ring-offset-0 cursor-pointer  focus:ring-offset-0 ring-transparent focus:ring-transparent checked:bg-black checked:border-[#A5F33C] checked:bg-[url('/src/assets/images/icons/active-check-icon.svg')]   rounded-full w-6 h-6 border-2 border-[#94A3B8] mb-2"
            }
          />

          <div className="flex justify-center ">
            {templateType === "Audio" && (
              <video
                id="audio"
                controls
                src={media}
                poster={images.audioPoster}
                onClick={() => onChange()}
                width="203"
                height={"203"}
                className=""
              ></video>
            )}
            {templateType === "Video" && (
              <video
                id="video"
                controls
                src={media}
                className=""
                width="203"
                height={"203"}
              ></video>
            )}
            {templateType === "Image" && (
              <div className="h-[203px]">
                <img
                  className="w-[203px] h-[203px] object-cover rounded-2xl"
                  src={media}
                  alt="icon"
                />
              </div>
            )}
          </div>

          <div className="absolute text-center bottom-3 left-4 ">
            <Tooltip
              className="text-[white] text-xs bg-[#12221A] z-50  border border-[#12221A] rounded max-w-[200px]"
              offset={10}
              placement={"top-start"}
              content={name}
            >
              <h6 className="">{stringTruncate(name, 25)} </h6>
            </Tooltip>
          </div>
        </label>
      </div>
    </>
  );
};

export default AssetCheckbox;
