import React from "react";
import { images } from './../../constants/images';

const ArweaveViewModalContent = (props) => {
    const{setOpenViewModal ,ViewData} = props;
  return (
    <>
      <h3 className="mb-2 text-2xl font-bold text-center text-white">Arweave View</h3>
      <div className="grid grid-cols-1 gap-3 mb-10 justify-items-center">
        <div >
          {ViewData?.imageType?.split("/")[0]==="image" ?  <img className="w-[260px] h-[248px] object-cover" src={`https://arweave.net/${ViewData?.ImageValue}`} alt='icon' />:<video src={`https://arweave.net/${ViewData?.ImageValue}`} controls autoPlay muted/>}
         </div>
        {/* <div>
          <div className="mb-4">
            <p className=" text-[#94A3B8] text-xs">Asset Name</p>
            <p className="text-base text-white">{`${ViewData.Creator-Name}`}</p>
          </div>
          <div className="mb-4">
            <p className=" text-[#94A3B8] text-xs">Asset Link</p>
            <p className="text-base text-white truncate ">{ViewData.AssetLink}</p>
          </div>
          <div className="mb-4">
            <p className=" text-[#94A3B8] text-xs">Type</p>
            <p className="text-base text-white">{ViewData?.AssetType}</p>
          </div>
          <div className="mb-4">
            <p className=" text-[#94A3B8] text-xs">Date</p>
            <p className="text-base text-white">{ViewData?.AssetDate}</p>
          </div>
        </div> */}
      </div>
      <button className="btn-secondary" onClick={()=>setOpenViewModal(false)}>
      Close
    </button>
    </>
  );
};

export default ArweaveViewModalContent;
