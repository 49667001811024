import * as yup from "yup";



export const BrandDetailsSchema = yup.object().shape({
  brandName: yup.string().required("Brand name is required").matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
  .max(20, "Max  length 20 characters"),
  brandWebUrl: yup
    .string()
    .required("Brand web url is required")
    
    ,
  registrationNumber: yup.string().required("Registration number is required").matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
  .max(100, "Max  length 100 characters"),
  contributorAddress: yup.string().required("Contributor is required").matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
  .max(18, "Max  length 18 characters"),
  brandImage: yup
    .mixed()
    .test("required", "Brand logo is required", (file) => {
      if (file[0]) return true;
      return false;
    })
    // .test("filesize", "max file size allowed is 5mb", (value) => {
    //   return value && value[0]?.size <= FILE_SIZE;
    // })
    // .test("type", "JPG, PNG & GIF supported only", 
      
    //     // value && value[0]?.type == SUPPORTED_FORMATS
    //     (value) => value && SUPPORTED_FORMATS.includes(value[0]?.type)
    //   )
   ,
  brandDescription: yup.string().required("Description is required").matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
  .max(1000, "Max  length 1000 characters"),
});
