import React, { useState } from "react";
import { images } from "../../../constants";
import InputField from "../../ui-components/form-component/InputFieldComponent";
import { Tooltip } from "@material-tailwind/react";
import SwitchComponent from "../../ui-components/switch-component/SwitchComponent";
import SwitchFieldComponent from "../../ui-components/switch-component/SwitchFieldComponent";

const AddContributorFields = (props) => {
  const { register, unregister, clearErrors, errors } = props;

  const [inputFields, setInputFields] = useState([
    { name: "", receiverAddress: "", percentage: "", contributorActive: false },
  ]);

  /** Add input Field */
  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        name: "",
        receiverAddress: "",
        percentage: "",
        contributorActive: false,
      },
    ]);
  };

  /** Remove Input Field */
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    unregister(`contributor.${index}.name`);
    unregister(`contributor.${index}.receiverAddress`);
    unregister(`contributor.${index}.percentage`);
    unregister(`contributor.${index}.contributorActive`);
    clearErrors(`contributor.${index}.name`);
    clearErrors(`contributor.${index}.receiverAddress`);
    clearErrors(`contributor.${index}.percentage`);

    setInputFields(rows);
  };
  return (
    <>
      <div className="max-w-[494px]">
        {inputFields?.map((element, index) => {
          return (
            <div className="relative mt-6" key={index}>
              <div className=" px-4 py-4 pt-4 bg-[#12221A]/30 border-[#A4B1A7]/25 form-group border  rounded-lg">
                <div className="absolute z-20 right-4 top-4">
                  <SwitchFieldComponent
                    name={`contributor.${index}.contributorActive`}
                    register={register}
                  />
                </div>
                <div className="relative mt-3 mb-8">
                  <InputField
                    type="text"
                    label="Name"
                    error={errors?.contributor?.[index]?.name?.message}
                    register={register}
                    name={`contributor.${index}.name`}
                  />

                  <p className="mt-1 text-xs text-red-600">
                    {errors?.contributor?.[index]?.name?.message}
                  </p>
                </div>

                <div className="grid grid-cols-2 gap-8">
                  <div className="relative ">
                    <InputField
                      type="text"
                      label="Receiver Address"
                      error={errors?.royalty?.[index]?.receiverAddress?.message}
                      register={register}
                      name={`contributor.${index}.receiverAddress`}
                    />

                    <p className="mt-1 text-xs text-red-600">
                      {errors?.contributor?.[index]?.receiverAddress?.message}
                    </p>
                  </div>
                  <div className="relative z-10 ">
                    <input
                      type="text"
                      className={`peer outline-none border-t-0 border-r-0 border-l-0 border-b border-[#A4B1A7] rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-[#A4B1A7] block w-full p-3 bg-transparent`}
                      placeholder=" "
                      {...register(`contributor.${index}.percentage`)}
                    />
                    <label
                      className={`text-xs text-grey-100 z-[-1]   absolute  -top-3 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-3   peer-focus:text-grey-100 peer-focus:text-xs`}
                    >
                      Percentage(%)
                    </label>
                    <p className="mt-1 text-xs text-red-600">
                      {errors?.contributor?.[index]?.percentage?.message}
                    </p>
                    <Tooltip
                      className="text-[white] text-xs bg-[#12221A]  border border-[#12221A]  relative z-30 rounded max-w-[200px]"
                      offset={10}
                      placement={"top-center"}
                      content={
                        "Lorem ipsum dolor sit amet,  ex ea commodo consequat. "
                      }
                    >
                      <img
                        className="absolute right-0 cursor-pointer top-3.5"
                        src={images.InputFieldInfoIcon}
                        alt="info-icon"
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>

              <div className="absolute top-[0px] w-[20px] h-[20px] -right-[4px] cursor-pointer ">
                {inputFields.length !== 1 ? (
                  <img
                    src={images.RemoveFieldIcon}
                    className=""
                    alt="icon"
                    onClick={() => {
                      removeInputFields(index);
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
        <span
          className={`${
            false
              ? "disabled:text-opacity-60 disabled:cursor-not-allowed"
              : "cursor-pointer"
          }  flex items-center mt-2 mb-10 text-xs font-bold `}
          onClick={() => {
            addInputField();
          }}
        >
          <img className="mr-1" src={images.AddFieldIcon} alt="add-icon" />
          Add Contributor
        </span>
      </div>
    </>
  );
};

export default AddContributorFields;
