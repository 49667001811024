
import { reset_menuItems } from "../../redux/features/menu/menuItemsSlice";
import { setMenuSelected, setSubMenuSelected } from "../../redux/features/sidebar-menu/sidebarMenuSlice";
import { login, logout } from "../../redux/features/user/userSlice";

import * as fcl from "@onflow/fcl";
import authService from "../../services/auth.service";

const useRefreshToken=()=>{
    const refreshToken= async(dispatch)=>{
       
        if (sessionStorage.getItem("persist:root")) {
            let persist_Storage = sessionStorage.getItem("persist:root");
            if (persist_Storage?.length > 0) {
            let parsedValue = JSON.parse(persist_Storage);
            let user = JSON.parse(parsedValue?.user);
            let refreshTokenResp=await authService.refreshAccessToken(user?.userInfo?.refresh_token,user?.userInfo?.user_id).then((result) => {
                return result;
            }).catch((error) => {
                return null;
            });;
            
            if(refreshTokenResp?.data?.token){
               
                refreshTokenResp.data.clientid=user?.clientUuid;
                refreshTokenResp.data.role=user?.userRole;
                dispatch(login(refreshTokenResp?.data));
                user.userInfo.access_token=refreshTokenResp?.data?.token?.access_token;
                user.userInfo.refresh_token=refreshTokenResp?.data?.token?.refresh_token;
                parsedValue.user=JSON.stringify(user);
                sessionStorage.setItem("persist:root",JSON.stringify(parsedValue))
                return refreshTokenResp?.data?.token;
            }
        }
        } else {
            dispatch(logout());
          
            dispatch(setMenuSelected(0))
          dispatch(setSubMenuSelected(0))
        
          dispatch(reset_menuItems(6));
          await fcl.unauthenticate();
        }
    }
    return {
        refreshToken
    }
}
export default useRefreshToken;