import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ContributorSchema } from "../../../utils/ValidationSchemas/AddContributorSchema";
import SwitchComponent from "../../ui-components/switch-component/SwitchComponent";
import AddContributorFields from "./AddContributorFields";

const AddContributor = () => {
  const [openContributor, setOpenContributor] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    unregister,
    clearErrors,
    formState: { errors, isDirty, isValid },
  } = useForm({ resolver: yupResolver(ContributorSchema), mode: "all" });

  const handleAddRoyalty = (values) => { 
  };

  const handleSetContributor = () => {
    setOpenContributor(!openContributor);
  };
  return (
    <>
      <div className="max-w-[494px]">
        <div className="flex justify-between mb-1">
          <h4 className="text-xl font-bold text-white">Add Contributor</h4>
          <div>
            <SwitchComponent
              onChange={handleSetContributor}
              value={openContributor}
              id={'contributor'}
            />
          </div>
        </div>
        
          <p className="text-xs text-grey-100">
            Lorem ipsum dolor sit amet consectetur. Lectus leo sed. Lorem ipsum
            dolor sit amet consectet{" "}
          </p>
        
      </div>
      {openContributor && (
        <form
          className="h-[calc(100vh-280px)] relative"
          onSubmit={handleSubmit(handleAddRoyalty)}
        >
          <div className="container relative z-20 grid h-full grid-cols-2 overflow-y-scroll ">
            <div className="columns-md max-w-[494px]">
              <div>
                <AddContributorFields
                  register={register}
                  handleSubmit={handleSubmit}
                  reset={reset}
                  setValue={setValue}
                  unregister={unregister}
                  clearErrors={clearErrors}
                  errors={errors}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-3 absolute -bottom-5 bg-[#060C07] w-full  z-50 max-w-[494px]">
            <button className="btn-secondary">Cancel</button>
            <button className="btn-primary" disabled={!isDirty || !isValid}>
              Submit
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default AddContributor;
