import React from "react";
import { images } from "../../../../constants";

const PreviewLanding = () => {
  return (
    <>
      <div className="grid items-center grid-cols-1 gap-4 md:grid-cols-2 relative before:absolute before:w-full before:h-[calc(100%+80px)] before:-top-[112px] before:bg-center before:bg-[length:100%_100%] before:-z-[1]">
        <div className="order-2 md:order-1">
          <h1 className="max-md:mt-10 max-[1280px]:text-4xl text-5xl font-bold  text-white max-lg:text-3xl max-md:text-3xl tracking-little ">
            Discover, collect, and sell extraordinary NFTs
          </h1>

          <p className=" max-lg:whitespace-nowrap max-lg:overflow-hidden max-lg:text-ellipsis  max-md:max-w-full  max-h-[130px]  text-grey-100 max-w-[550px] mt-3 tracking-little">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Arcu ac
            faucibus amet amet.
          </p>
          {/* <button className="px-10 py-3 text-xl font-medium leading-snug text-white capitalize transition duration-150 ease-in-out bg-[#A5F33C] rounded-full shadow-md mt-11 hover:bg-[#A5F33C] hover:shadow-lg focus:bg-[#A5F33C] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#A5F33C] active:shadow-lg">Explore Collections</button> */}
          <div className="max-md:text-center">
            <button className="max-w-xs px-16 py-3 mb-6 text-xl text-[#12221A] capitalize transition duration-150 ease-in-out bg-[#A5F33C] rounded-full shadow-md mt-11 btn w-fit hover:bg-[#A5F33C] hover:shadow-lg focus:bg-[#A5F33C] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#A5F33C]text-white active:bg-[#A5F33C] lg:mb-0">
              Explore Collections
            </button>
          </div>
        </div>

        {/* HERO IMAGE */}
        <div className="max-w-full max-h-[540px] xl:w-[610px] xl:h-[540px] md:order-2 order-1">
          {/* <img src={heroSectionContent.heroImgUrl || images.HeroImage} className="w-full" alt="hero banner" /> */}
          <img
            src={images.DefaultPreviewHeroIcon}
            className="object-cover w-full h-full rounded"
            alt="hero banner"
          />
        </div>
      </div>
    </>
  );
};

export default PreviewLanding;
