import axios from "axios";

const getCurrentArweaveUSDPrice = () => {
    return axios.get("https://min-api.cryptocompare.com/data/price?fsym=AR&tsyms=USD")
      .then((result) => {
        return result?.data ?? result
      });
  };
  const getCurrentFlowUSDPrice = () => {
    return axios.get("https://min-api.cryptocompare.com/data/price?fsym=FLOW&tsyms=USD")
      .then((result) => {
        return result?.data ?? result
      });
  };
const externalService = {
    getCurrentArweaveUSDPrice,
    getCurrentFlowUSDPrice
    
  };
export default externalService;