import { axiosClientIdentityServer } from "./apiClientIdentityServer";

/// all the axios http requests like get/post/put/delete

export const getRequestIdentity = async (URL) => {
	const response = await axiosClientIdentityServer.get(`/${URL}`, {});
	return response;
};

export const postRequestIdentity = async (URL, payload) => {
	const response = await axiosClientIdentityServer.post(`/${URL}`, payload);
	return response;
};

export const putRequestIdentity = async (URL, payload) => {
	const response = await axiosClientIdentityServer.put(`/${URL}`, payload);
	return response;
};

export const deleteRequestIdentity = async (URL) => {
	const response = await axiosClientIdentityServer.delete(`/${URL}`);
	return response;
};
