const EditorToolbar = (props) => {
    return (
        <>
            <div id={props.id}>

                <span className="ql-formats">
                    <button className="ql-bold" />
                    <button className="ql-italic" />
                    <button className="ql-underline" />
                </span>

                <span className="ql-formats">
                    <button className="ql-list" value="ordered" />
                    <button className="ql-list" value="bullet" />
                    <button className="ql-indent" value="-1" />
                    <button className="ql-indent" value="+1" />
                </span>

                <span className="ql-formats">
                    <button className="ql-link" />
                    <button className="ql-image" />
                </span>

                <select className="ql-size">
                    <option value="small">Small</option>
                    <option value="medium">
                        Medium
                    </option>
                    <option value="large">Large</option>
                    <option value="huge">Huge</option>
                </select>

                <select className="ql-align" />

                <select className="ql-color" />

                <select className="ql-background" />

                <button className="ql-clean" />


            </div>
        </>
    )
};

export default EditorToolbar;