
import * as fcl from "@onflow/fcl";

const MatchApproveAndConnectWalletHook = () => {

  const matchConnectedWallet = async (approvedWallet) => {
    const response = await fcl.authenticate();

    if (response.loggedIn) {
      if (response?.addr === approvedWallet) {
        return true;
      } else {
        return false;
      }
    }
    else {
      return null;
    }
  };
  
  const disconnetWallet = async () => {
    await fcl.unauthenticate();
  };


  return {
    matchConnectedWallet,
    disconnetWallet,
  };
};

export default MatchApproveAndConnectWalletHook;
