import React from "react";
import WalletAddress from "./../components/WalletAddress";
import UserAvatar from "./../components/UserAvatar";
import { useSelector } from "react-redux";
import { images } from "./../../../constants/images";
import { useOutletContext } from "react-router-dom";
import SetPassword from "../../../components/Auth/SetPassword";

const ChangePassword = () => {
	const clientInfo = useSelector((state) => state.user.clientDetail);
	const inputFieldStyle = useOutletContext();

	return (
		<>
			<div className="flex items-center justify-between mb-8">
				<h1 className="text-2xl font-bold text-white">Change Password</h1>
				<div className="flex items-center justify-between">
					<WalletAddress walletAddress={clientInfo?.clientWalletAddress} />
					<UserAvatar
						firstname={clientInfo?.contactFirstName}
						lastname={clientInfo?.contactLastName}
						pic={images.avatar}
					/>
				</div>
			</div>
			<div className="p-6 border rounded-md border-[#A4B1A7]/25">
				<h3 className="text-xl font-bold ">Change Password</h3>
				<SetPassword isClientPortal={true} />
			</div>
			</>
	);
};

export default ChangePassword;
