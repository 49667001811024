import { Color } from "highcharts";

export const options = {
  chart: {
    type: "column",
    backgroundColor: "#060C07",
    borderRadius: "64px",
    marginTop: 100,
    margin: 70,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "Drop Performance",
    align: "left",
    x: 24,
    y: 24,
    style: {
      color: "white",
      fontSize: "16px",
      fontFamily: "inherit",
      lineHeight: "24px",
    },
  },
  subtitle: {
    x: 24,
    y: 46,
    text: "Drops and Collections: 2023-2023",
    align: "left",
    style: {
      borderBottom: "1px solid white",
      color: "#8D9D91",
      width: "100%",
      display: "block",
      left: 0,
      textAlign: "center",
    },
  },

  xAxis: {
    min: 0,
    gridLineDashStyle: "LongDash",
    max: 4,
    categories: ["2020", "2021", "2022", "2023", "2024"],
    crosshair: true,
    labels: {
      style: {
        color: "white",
      },
    },
  },
  yAxis: {
    min: 0,
    max: 4,
    gridLineDashStyle: "LongDash",
    title: {
      text: "",
    },
    gridLineColor: "#ffffffb3",
    labels: {
      y: -20,
      style: {
        color: "white",
      },
    },
    categories: ["200", "400", "600", "800", "1.2k"],
  },

  plotOptions: {
    series: {
      borderRadiusTopLeft: "50%",
      borderRadiusTopRight: "50%",
      plotBorderColor: "white",
      plotBorderWidth: 2,
    },
    column: {
      pointPadding: 0.2,

      borderWidth: 0,
    },
  },
  legend: {
    
      enabled: false
  ,
    itemStyle: { color: "white", fontWeight: "400", hover: "white" },
    align: "right",
    verticalAlign: "top",
    layout: "vertical",
    x: -50,
    y: 0,
    symbolRadius: 0,
    labelFormatter: function () {
      return (
        `<div style="hover:white;color:white;opacity:0.7"> ${this.name}</div>` +
        "<img src='https://pbs.twimg.com/profile_images/538262176134221824/Vx4_Psj1_400x400.png' width='20' height='20'> "
      );
    },
  },
  series: [
    {
      name: "Drops",

      data: [],
      color: {
        linearGradient: [0, 0, 0, 400],
        stops: [
          [0, "#EC5676"],
          [1, "#144FF5"],
        ],
      },
    
    },
    {
      name: "Collections",
      
      data: [],
      color: {
        linearGradient: [0, 0, 0, 400],
        stops: [
          [0, "#F7C644"],
          [1, " #EC5676"],
        ],
      },
      
    },
  ],
};
