import * as yup from "yup";



export const RoyaltySchema = yup.object().shape({
  royalty: yup.array().of(
    yup.object().shape({
      royaltyActive:yup.bool(),
      name: yup
        .string()
        .required("Name  is required")
        .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
        .max(100, "Max  length 100 characters"),
      receiverAddress: yup
        .string()
        .required("Address  is required")
        .matches(/^0x(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/, "Invalid Address")
        .max(18, "Max  length 18 characters"),
      royaltyPercentage: yup
        .number()
        .required("Amount  is required")
        .min(1, "Number must be greater than or equal to 1")
        .max(100, "Number must be less than or equal to 100")
        .typeError("Please enter royalty in number")
        .positive("Number must be positive and greater than zero")
        .test(
          'is-decimal',
          'maximum two decimals allowed',
          value => (value + "").match(/^\d+(?:\.\d{0,2})?$/),
        ),
      description: yup
        .string()
        .required("Description  is required")
        .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
        .max(400, "Max  length 400 characters"),
    })
  )
});