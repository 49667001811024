import * as fcl from "@onflow/fcl";
import axios from "axios";
import useRefreshToken from "../../helpers/custom-hooks/useRefreshToken";
import { setLoader } from "../../redux/features/loading/loaderSlice";

let configureStoreApiClient;
const SetupInterceptors = (store) => {
  configureStoreApiClient = store;
}; 
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL_BACKEND,
});

axiosClient.interceptors.request.use(
  function (config) { 
    const token = sessionStorage?.getItem("persist:root") || [];
    const jwt = JSON.parse(token || null);
    const user = JSON.parse(jwt?.user || null) || [];
    config.headers.Authorization = user?.userInfo
      ? `Bearer ${user?.userInfo?.access_token}`
      : "";
    return config;
  },
  (error) => {
    return error;
  }
);
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { dispatch } = configureStoreApiClient;
    dispatch(setLoader(false));
    const originalConfig = error.config;
    if (error?.response?.status === 401 && !originalConfig?._retry) {
      originalConfig._retry = true;
      const { refreshToken } = useRefreshToken();
      await refreshToken(dispatch);
      return axiosClient(originalConfig);
      // window.location.reload();
    }
    return Promise.reject(error);
  }
);

export { axiosClient, SetupInterceptors };
