import { Tooltip } from "@material-tailwind/react";

export const COLUMNS = [
  {
    Header: "Sr.No",
    Footer: "ID",
    accessor: "id",
  },
  {
    Header: "Client",
    Footer: "Client",
    accessor:"clientUuid"
  },
  {
    Header: "Brand Name",
    Footer: "Brand Name",
    accessor: 'clientName'
  },
  {
    Header: "Client Name",
    Footer: "Client Name",
    accessor: "contactName",
    Cell: ({ row }) => (
      <>
       
         
            <Tooltip className="text-[white] text-xs bg-[#12221A]   border border-[#12221A] rounded" offset={10} placement={'top-start'} content={row?.values?.contactName}>
            <p className="">{row?.values?.contactName}</p>
            </Tooltip>
          
        
        
      </>
    ),
  },
  {
    Header: "Wallet Address",
    Footer: "Wallet Address",
    accessor: "walletAddress",
  },
  {
    Header: "Category",
    Footer: "Category",
    accessor: "categoryName",
  },
  {
    Header: "Status",
    Footer: "Status",
    // accessor: (status) => (
    // <>
    // 	{status.status === "pending" && (
    // 		<span className="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900">
    // 			Pending
    // 		</span>
    // 	)}
    // 	{status.status === "active" && (
    // 		<span classNameName="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
    // 			Active
    // 		</span>
    // 	)}
    // 	{status.status === "in active" && (
    // 		<span classNameName="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
    // 			In Active
    // 		</span>
    // 	)}
    // </>
    // ),
    accessor: "status",
    Cell: ({ row }) => (
      <>
        {row.values.status === "InQueue" && (
          <span className="">InQueue</span>
        )}
        {row.values.status === "Pending" && (
     
       <span>   Pending</span> 
      
        )}
        {row.values.status === "Active" && (
         
           <span> Active</span>
       
        )}
        {row.values.status === "Deactive" && (
          <span className="text-[#8D9D91]">
            Deactive
          </span>
        )}
         {row.values.status === "Declined" && (
          <span className="bg-zinc-800 text-red text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
            Declined
          </span>
        )}
      </>
    ),
  },
  // {
  // 	Header: "Image",
  // 	accessor: "image_url",
  // },
  // {
  // 	Header: "Action",
  // 	Footer: "Action",
  // 	disableSortBy: true,
  // 	accessor: (btn) => (
  // 		<div classNameName="flex justify-between">
  // 			{btn.status === "active" && (
  // 				<Link
  // 					classNameName="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 mr-2"
  // 					type="button"
  // 					to={`/client/${btn.id}`}>
  // 					Edit
  // 				</Link>
  // 			)}
  // 			{btn.status === "pending" && (
  // 				<button
  // 					classNameName="bg-gray-500 text-black p-2 rounded-md hover:bg-gray-600 mr-2"
  // 					type="button"
  // 					onClick={() => {}}>
  // 					Approve Decline
  // 				</button>
  // 			)}
  // 			{btn.status === "in active" && (
  // 				<button
  // 					classNameName="bg-green-500 text-white p-2 rounded-md hover:bg-green-600"
  // 					type="button">
  // 					Activate
  // 				</button>
  // 			)}
  // 		</div>
  // 	),
  // },
];
