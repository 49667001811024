import React from 'react'

const ResendEmailComponent = (props) => {
    const {loading} = props;
  return (
    <>
    <div>
    <p className="mb-10 text-md text-grey-100">
    An email has been sent, please click the link in the email to recover your password.    
            </p>
            <button
                    type='submit'
                    className={`btn-secondary border-[#A5F33C]  disabled:cursor-not-allowed`}
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    disabled={loading}>
                   
                   Re-Send Email
                </button>
    </div>
    </>
  )
}

export default ResendEmailComponent