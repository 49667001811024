import { gql } from "@apollo/client";

const arweaveTrans = gql`
query Transactions($first: Int) {
    transactions(first: $first, tags: [{name: "App-Name", values: ["Bloomly"]}]) {
      edges {
        cursor
        node {
           id
          anchor
          signature
                  recipient
                  owner {
                      address
                  }
                  fee {
                      winston
                      ar
                  }
          data {
                      size
                      type
                  }
                
          tags {
            name
            value
          }
          block {
            timestamp
            id
            __typename
            height
          }
          id
          __typename
        }
       
      }
     
    }
  }
`;
const getAllArweaveTransactions = () => {
  return arweaveTrans;
};
const GraphQL = {
  getAllArweaveTransactions
};
export default GraphQL;
