import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FileResizer from "react-image-file-resizer";
import { useDispatch, useSelector } from "react-redux";
import { images } from "../../constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Spinner from "./../ui-components/loader-components/Spinner";
import Modal from "../../common/ModalLayout";
import ImageCropper from "../../components/Modals/ImageCropper";
import { ClientThemeSchema } from "../../utils/ValidationSchemas/ClientThemeSchema";
import clientService from "../../services/client.service";
import AlertModalContent from "../Modals/AlertModalContent";
import dataUrlToFile from "../../helpers/dataUrlToFile";
import { setLoader } from "../../redux/features/loading/loaderSlice";

const HomePage = () => {
  const fileInputRef = useRef();
  const croppedImgUrl = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [file, setFile] = useState("");
  const [fileSize, setFileSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showCroppingPopup, setshowCroppingPopup] = useState();
  const [OpenAlertModal, setOpenAlertModal] = useState(false);
  const [alertContent, setAlertContent] = useState({
    title: "",

    description: "",
    path: "",
    sublabel: "",
  });

  const [clientThemeDetail, setClientThemeDetail] = useState();

  const user = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ClientThemeSchema),
    mode: "all",
  });

  const handleChange = (e) => {
    setFileSize((e.target.files[0]?.size / 1024).toFixed(2));
    setFile(URL.createObjectURL(e.target.files[0]));
    let file_data = e.target.files[0];
    // setFile(e.target.files[0])
    let reg = /(.*?)\.(jpg|jpeg|PNG|png|gif)$/;
    if (file_data === undefined || !file_data.name.match(reg)) {
      e.target.value = null;
      return false;
    }
    if (file_data.size > 209715200) {
      setOpenAlertModal(true);
      setAlertContent({
        title: "Error",
        description: "File size should be less than 200 MB ",
      });

      return false;
    }

    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    let img = new Image();
    reader.onload = (fileList) => {
      img.onload = () => {
        if (img.width < 610 || img.height < 540) {
          setFile("");
          e.target.value = null;
          setOpenAlertModal(true);
          setAlertContent({
            title: "Error",
            description:
              "Image dimension must be greater than or equal to 610 width * 540 height ",
          });
        } else {
          const maxWidth = img.width > 700 ? 700 : img.width;
          const maxHeight = img.height > 610 ? 610 : img.height;
          FileResizer.imageFileResizer(
            e.target.files[0],
            maxWidth,
            maxHeight,
            e.target.files[0].type.split("/")[1],
            100,
            0,
            (result) => {
              const blob = result;

              let fileName = e.target.files[0].type.split("/")[1];
              let fileeBlob = dataUrlToFile(blob, fileName);
              setFile(URL.createObjectURL(fileeBlob));
              setshowCroppingPopup(true);
            },
            "File"
          );
        }
      };
      img.src = fileList.target.result;
    };
  };

  // Cross icon to remove the selected file
  const handleRemoveImage = () => {
    setFile("");
    reset({ brandImage: "" });
  };

  const getCroppedImgFile = (imgFile) => {
    setFile(URL.createObjectURL(imgFile));
    croppedImgUrl.current = imgFile;
    setshowCroppingPopup(false);
  };

  const getClientThemeDetails = () => {
    dispatch(setLoader(true));
    try {
      clientService
        .getClientThemeDetails(
          user?.clientDetail?.id,
          user?.userInfo?.access_token
        )
        .then((res) => {
          // console.log('ressss', res);
          dispatch(setLoader(false));
          if (res) {
            setClientThemeDetail(res?.data?.payload);
            setValue("heroHeading", res?.data?.payload?.heroHeading);
            setValue("heroDescription", res?.data?.payload?.heroDescription);
            setValue("heroImage", res?.data?.payload?.heroImgUrl);
          }
        })
        .catch((err) => {
          dispatch(setLoader(false));
        });
    } catch (err) {
      dispatch(setLoader(false));
      // console.log('err', err)
    }
  };

  useEffect(() => {
    getClientThemeDetails();
  }, []);

  const submitForm = (data) => {
    let formData = new FormData();
    formData.append("clientThemeSteps", 1);
    formData.append(
      "ClientThemeUuid",
      clientThemeDetail?.clientThemeUuid
        ? clientThemeDetail?.clientThemeUuid
        : ""
    );

    formData.append("ClientId", user?.clientDetail?.id);
    formData.append("BrandName", user?.clientDetail?.clientName);
    formData.append("HeroHeading", data?.heroHeading);
    formData.append("HeroDescription", data?.heroDescription);
    formData.append("HeroImg", data?.heroImage[0]);
    setLoading(true);
    clientService
      .createUpdateClientTheme(formData)
      .then((res) => {
        // console.log('res', res);
        setLoading(false);
        if (res?.status === 200) {
          if (res?.data?.success === true) {
            setOpenAlertModal(true);
            setTimeout(() => {
              setOpenAlertModal(false);
            }, 3000);
            setAlertContent({
              title: "Success",
              description: "Changes have been saved successfully.",
              sublabel: "Auto closing in 3 seconds...",
            });
            setFile("");
            setClientThemeDetail(res?.data?.payload);
          } else {
            setOpenAlertModal(true);
            setTimeout(() => {
              setOpenAlertModal(false);
            }, 3000);
            setAlertContent({
              title: "Error",
              description: "Failed to save changes. Please try again.",
              sublabel: "Auto closing in 3 seconds...",
            });
          }
        }
      })
      .catch((err) => {
        // console.log("err", err);
        setLoading(false);
      });
  };

  {
    /** will implement this feature in next phase */
  }
  // const gotoWebsite = () => {
  //   navigate("/storefront-demo1");
  // };
  // console.log("erro",errors)

  return (
    <>
      <div className="grid grid-cols-2">
        <div>
          <h1 className="mb-1 text-xl font-bold">Home Page Details</h1>
          <form
            className="max-w-[494px] mt-6 text-white"
            onSubmit={handleSubmit(submitForm)}
          >
            <div className="relative z-10 mt-10 mb-6">
              <input
                type="text"
                maxLength={"101"}
                className={`${
                  errors?.heroHeading?.message &&
                  "focus:border-b-red-600 border-b-red-600"
                } peer outline-none border-t-0 border-r-0 border-l-0 border-b border-[#A4B1A7]rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-[#A4B1A7] block w-full p-2.5 bg-transparent `}
                placeholder=" "
                {...register("heroHeading")}
              />
              <label
                className={`text-xs text-grey-100 z-[-1] absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs`}
              >
                Hero Heading
              </label>
              <p className="mt-1 text-xs text-red-600">
                {errors?.heroHeading?.message}
              </p>
            </div>
            <div className="relative z-0 mt-12 mb-6 ">
              <textarea
                type="text"
                name="heroDescription"
                rows="4"
                maxLength="201"
                className={`peer outline-none  px-3 py-2  border rounded-xl border-[#A4B1A7] text-white text-md  placeholder:text-white focus:ring-transparent focus:border-[#A4B1A7] focus:outline-none  block w-full  bg-transparent `}
                placeholder=" "
                {...register("heroDescription")}
              ></textarea>
              <label
                className={`text-xs text-grey-100 z-[-1]  left-0 absolute  -top-5 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3 peer-placeholder-shown:left-3   peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-5   peer-focus:text-grey-100 peer-focus:text-xs peer-focus:left-0`}
              >
                Hero Description
              </label>
              {!errors?.heroDescription?.message && (
                <p className="mt-2 text-xs text-grey-100">
                  (Max length 200 characters)
                </p>
              )}
              <p className="mt-1 text-xs text-red-600">
                {errors?.heroDescription?.message}
              </p>
            </div>
            {/* File Uploader */}
            <label htmlFor="inputFileSelector" className="mb-2 cursor-pointer">
              {/* Image Selector */}
              <input
                hidden={true}
                id="inputFileSelector"
                ref={fileInputRef}
                accept={".gif,.jpg,.jpeg,.png"}
                type="file"
                name="heroImage"
                // className={inputFieldStyle}
                {...register("heroImage", {
                  onChange: (e) => {
                    handleChange(e);
                  },
                })}
              />

              <div className="flex items-center justify-between text-white font-poppins border border-[#A4B1A7] p-4 border-grey-100-700 border-solid rounded-xl w-full min-h-[96px] h-full">
                <div className="flex items-center justify-start flex-grow-1">
                  <img className="mr-3" src={images.UploadIcon} alt="" />
                  <div className="flex flex-col">
                    <h4 className="text-base font-bold">Upload Banner</h4>
                    <p className="mb-2 text-xs"> Browse Files</p>
                    <p className="text-xs text-grey-100">
                      JPG, GIF, PNG | Max file size: 5 MB
                    </p>
                    <p className="text-xs text-grey-100">
                      Dimension 610 width * 540 height
                    </p>
                  </div>
                </div>
                <p className="flex-shrink text-xs font-bold text-grey-100">
                  OR
                </p>
                <p className="flex-shrink text-base font-bold text-center text-[#A5F33C] ">
                  Browse
                </p>
              </div>
            </label>
            {/* Image Displayer */}
            <div className="">
              {file && (
                <div className="flex justify-between items-center text-xs mt-8 flex-grow py-3 px-4 rounded-xl bg-transparent border-2 border-zinc-700 min-w-[80px]">
                  <div className="flex items-center">
                    <img
                      className="object-contain mr-2 w-7 h-7"
                      src={file}
                      alt="logo"
                    />
                    <span className="w-full p-0 font-bold bg-transparent border-none focus:ring-transparent">
                      Hero Image
                    </span>
                  </div>
                  <span className="text-grey-100">{fileSize + "kb"}</span>
                  <img
                    className="cursor-pointer hover:opacity-80"
                    src={images.cross_icon}
                    alt="X"
                    onClick={handleRemoveImage}
                  />
                </div>
              )}

              <p className="mt-1 text-xs text-red-600">
                {errors?.heroImage?.message}
              </p>
            </div>

            {/* Save  */}
            <div className="flex justify-between mt-10 mb-[30px]">
              {/** We will implement this is next phase  */}
              {/* <button
                type="button"
                className={`btn-secondary disabled:cursor-not-allowed mr-4 capitalize`}
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                disabled={loading}
                onClick={gotoWebsite}
              >
                Live Preview
              </button> */}
              <button
                type="submit"
                className={`btn-primary  disabled:cursor-not-allowed`}
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                disabled={loading}
              >
                {loading && <Spinner />}
                Save Changes
              </button>
            </div>
          </form>
        </div>

        {!file &&
          ((clientThemeDetail &&
            Object.keys(clientThemeDetail).length !== 0 &&
            Object.getPrototypeOf(clientThemeDetail) !== Object.prototype) ||
            (clientThemeDetail &&
              clientThemeDetail?.heroImgUrl &&
              clientThemeDetail?.heroImgUrl !== null)) && (
            <div className="flex items-center justify-center">
              <img
                src={clientThemeDetail?.heroImgUrl}
                className="object-cover h-[300px] mt-3 mr-2 w-[300px] rounded-2xl "
                alt="hero_image"
              />
            </div>
          )}
      </div>

      <div>
        {showCroppingPopup && (
          <Modal
            modalVisible={showCroppingPopup}
            closeModal={setshowCroppingPopup}
            children={
              <ImageCropper
                imgSrc={file}
                getCroppedImgFile={getCroppedImgFile}
                aspectRatio={28 / 24}
                style={"h-[360px]"}
              />
            }
            modal_Id="Add_Modal"
            isClose={true}
          />
        )}
      </div>

      {OpenAlertModal && (
        <Modal
          modalVisible={OpenAlertModal}
          closeModal={setOpenAlertModal}
          children={<AlertModalContent message={alertContent} />}
          modal_Id="Save_Modal"
          isClose={true}
        />
      )}
    </>
  );
};

export default HomePage;
