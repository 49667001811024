import React, { useEffect, useState } from "react";
import ClaimListingTable from "./ClaimListingTable";
import AirdropService from "../../services/airdrop.service";
import { useSelector } from "react-redux";

const WhitelistListing = () => {
  // const defaultData = [
  // 	{
  // 		image: images.whitelistTableTempImage,
  // 		assetName: 'Black is beautiful NFT',
  // 		dropName: 'Daigaku Dragon Eggs',
  // 		purchaserEmail: 'binhan@gmail.com',
  // 		walletAddress: '0x34320635edfecc1e',
  // 		itemsClaimedPerTranx: 5,
  // 		claimDate: 'June 08, 2023',
  // 		transactionHash: 'www.google.com',
  // 	},
  // 	{
  // 		image: images.whitelistTableTempImage,
  // 		assetName: 'Monsuta',
  // 		dropName: 'Doodles',
  // 		purchaserEmail: 'doodlehan@gmail.com',
  // 		walletAddress: '0x34320631gUfecc1e',
  // 		itemsClaimedPerTranx: 2,
  // 		claimDate: 'June 01, 2023',
  // 		transactionHash: 'www.google.com',
  // 	},
  // 	{
  // 		image: images.whitelistTableTempImage,
  // 		assetName: 'Moonbirds',
  // 		dropName: 'Hit FM',
  // 		purchaserEmail: 'hitfm@gmail.com',
  // 		walletAddress: '0x09670635edccdr1e',
  // 		itemsClaimedPerTranx: 8,
  // 		claimDate: 'May 18, 2023',
  // 		transactionHash: 'www.google.com',
  // 	},
  // ];

  const [claimList, setClaimList] = useState([]);

  const { id } = useSelector((state) => state.user.clientDetail);

  useEffect(() => {
    getClaimReportList();
  }, []);

  const getClaimReportList = async () => {
    const payload = {
      clientId: id.toString(),
      fromDate: null,
      todate: null,
    };
    const response = await AirdropService.claimTransactionsReportList(payload);

    // })
    setClaimList(response?.data?.payload?.reverse());
  };

  return <div>{<ClaimListingTable defaultData={claimList} />}</div>;
};

export default WhitelistListing;
