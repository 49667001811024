import { account, } from "@onflow/fcl";

const WalletBalanceHook = () => {
    const getWalletBalance = async (walletAddress) => {
        const accountInfo = await account(walletAddress);
        if(accountInfo?.balance ) {
            const balance = (accountInfo?.balance / 100000000) ;
            return balance;
        }else{
            return 0;
        }
       


       
    };
   
    return {
        getWalletBalance
    };
};

export default WalletBalanceHook;
