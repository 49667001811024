import React, { useEffect } from "react";
import { images } from "../../../../../constants";
import AssetCheckbox from "./AssetCheckbox";
import DropSearch from "./DropSearch";

const AddDropAsset = (props) => {
  const {
    SearchAssetByNameHandler,
    setActiveStep,
    steps,
    setSteps,
    setDropAssetData,
    assetDrop,
    viewEditData,
    setAssetDrop,
    originalAssetsArrayRefVal
  } = props;
  // useEffect(() => {
  //   if(viewEditData?.isEdit){
  //     if (assetDrop?.length > 0){
  //       const assetList =   assetDrop.map((asset, ) => {
  //          return { ...asset, isChecked: true }; 
  //       });
  //       setAssetDrop(assetList)
  //     }
  //   }
  // }, [])




  const handleChange = (e, index) => {
    if (e.target.checked) {
      const assetList = assetDrop.map((asset, assetIndex) => {
        if (index === assetIndex) return { ...asset, isChecked: true };
        else return { ...asset, isChecked: false };
      });
      setAssetDrop(assetList);
    } else {
      const assetList = assetDrop.map((asset, assetIndex) => {
        if (index === assetIndex) return { ...asset, isChecked: false };
        else return { ...asset };
      });
      setAssetDrop(assetList);
    }
  };

  const handleDropAssetData = () => {
    const assetData = assetDrop?.filter(item => item?.isChecked === true);
    if (assetData?.length > 0) {
      setDropAssetData(assetData)
      setActiveStep(3);
      setSteps(
        steps.map((step, index) =>
          step.value === 2 ? { ...step, IsCompleted: true } : step
        )
      );
    }
  }
  return (
    <>
      {assetDrop?.length > 0 ? (
        <>
         <DropSearch SearchAssetByNameHandler={SearchAssetByNameHandler} />
        <div className=' h-[calc(100vh-356px)] relative '>
          
          <div className="h-full overflow-y-scroll">

         
         

          <div className="grid grid-cols-4 gap-6 ">
            {assetDrop?.map((asset, index) => (
              <AssetCheckbox
                templateType={asset?.templateType}
                name={asset?.assetName}
                media={asset?.artSrc}
                isChecked={asset?.isChecked}
                onChange={(e) => handleChange(e, index)}
              />
            ))}
          </div>
          </div>
          <div className="flex justify-between items-center mt-10 absolute -bottom-5 bg-[#060C07] w-full  z-50">
            <div className="flex ">
              <button
                className="px-20 py-3 mr-10 text-center btn-secondary"
                type="submit"
                onClick={() => {
                  setActiveStep(1);
                }}
              >
                Back
              </button>
              <button
                className="px-20 py-3 text-center btn-primary disabled:cursor-not-allowed"
                type="submit"
                disabled={assetDrop?.filter(item => item?.isChecked === true)?.length === 0}
                onClick={handleDropAssetData}
              >
                Next
              </button>
            </div>

            <span className="mr-10 text-base cursor-pointer hover:bg-[#A5F33C] font-bold hover:text-[#12221A] hover:rounded-full px-10 py-3">Cancel</span>
          </div>
        </div>
        </>
      ) : (

        <div className="p-4">
          <DropSearch SearchAssetByNameHandler={SearchAssetByNameHandler} />
          <p className="mb-4 text-base text-grey-100">You do not have any assets created at the moment. Kindly create a new asset and then attach it to a Drop. </p>
          <button
            className="max-w-[200px] px-20 py-3 mr-10 text-center btn-secondary"
            type="submit"
            onClick={() => {
              originalAssetsArrayRefVal?.length > 0 ?  setAssetDrop(originalAssetsArrayRefVal) :  setActiveStep(1);
            }}
          >
            Back
          </button>
        </div>


      )}

    </>
  );
};

export default AddDropAsset;
