import * as yup from "yup";

export const PriceConfigurationSchema = yup.object().shape({
  platformFee:yup.number()
  // .integer("Enter a number without decimal value")
  .typeError("  Please enter platform fee in number ")
  .positive("Number must be positive and greater than zero")
  .required(" Please enter  platform fee  in number "),
  // marketplaceFee: yup
  // .number()
  // // .integer("Enter a number without decimal value")
  // .typeError("  Please enter marketplace Fee in number")
  // .positive("Number must be positive and greater than zero")
  // .required(" Please enter marketplace Fee in number "),
});
