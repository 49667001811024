import React from 'react'

const PreviewCountDownTimer = () => {
  return (
    <>
    <div className="inline-flex flex-col items-center mr-6 text-white">

<div className="bg-[#A5F33C] max-lg:rounded-xl rounded-[14px] opacity-[0.9]  p-[1px] inline-block  ">
  <div className="inline-flex flex-col bg-[#000000be]  max-lg:p-2 max-xl:p-3 max-lg:rounded-xl px-4 py-4 text-base font-normal text-center text-white rounded-[14px] max-lg:min-w-[52px] max-xl:min-w-[62px] min-w-[78px]  ">
    <span className="inline-block text-2xl font-bold text-center max-xl:text-xl max-lg:text-base ">
      {" "}
      2
    </span>
    <span className="font-normal text-grey-100">
  Days
</span>
  </div>
</div>

</div>
<div className="inline-flex flex-col items-center mr-6 text-white">

<div className="bg-[#A5F33C] max-lg:rounded-xl  rounded-[14px] opacity-[0.9] p-[1px] inline-block   ">
  <div className="inline-flex flex-col bg-[#000000be] max-lg:rounded-xl max-lg:p-2 max-xl:p-3 px-4 py-4 text-base font-normal text-white rounded-[14px] text-center max-lg:min-w-[52px] max-xl:min-w-[62px] min-w-[78px]">
    <span className="min-w-[36px] font-bold max-lg:text-base text-center inline-block max-xl:text-xl text-2xl">
      {" "}
      12
    </span>
    <span className="font-normal text-center text-grey-100"> Hrs</span>
  </div>
 
</div>

</div>
<div className="inline-flex flex-col items-center mr-6 text-white">

<div className="bg-[#A5F33C] max-lg:rounded-xl rounded-[14px]  opacity-[0.9] p-[1px] inline-block   ">
  <div className="inline-flex flex-col bg-[#000000be] max-lg:rounded-xl max-lg:p-2 max-xl:p-3 px-4 py-4 text-base font-normal text-white rounded-[14px] text-center max-lg:min-w-[52px] max-xl:min-w-[62px] min-w-[78px]">
    <span className="min-w-[36px] font-bold max-xl:text-xl max-lg:text-base text-center inline-block text-2xl">
      {" "}
      24
    </span>
    <span className="font-normal text-grey-100"> Min</span>
  </div>
</div>

</div>
<div className="inline-flex flex-col items-center text-white ">

<div className="bg-[#A5F33C] max-lg:rounded-xl rounded-[14px] opacity-[0.9]  p-[1px] inline-block   ">
  <div className="inline-flex flex-col bg-[#000000be] max-lg:rounded-xl max-lg:p-2 max-xl:p-3 px-4 py-4 text-base font-normal text-white text-center rounded-[14px] max-lg:min-w-[52px] max-xl:min-w-[62px] min-w-[78px] ">
    <span className="min-w-[36px] font-bold max-xl:text-xl max-lg:text-base  text-center inline-block text-2xl">
      56
    </span>
    <span className="font-normal text-grey-100"> Sec </span>
  </div>
</div>

</div>
</>
  )
}

export default PreviewCountDownTimer