import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { CategoryService } from "../../services/category.service";
import { CategorySchema } from "../../utils/ValidationSchemas/AddCategorySchema";
import Spinner from "../ui-components/loader-components/Spinner";
import CloseIconView from "../ui-components/views-component/CloseIconView";
import AlertModalContent from "./AlertModalContent";

const AddCategoryModal = (props) => {
  const {
    setOpenAddCategoryModal,
    categoryDetail,
    setCategoriesData,
    setCategoryDetail,
  } = props;
  const [loader, setLoader] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    title: "",
    description: "",
    sublabel: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(CategorySchema),
    mode: "onChange",
    defaultValues: {
      categoryName: categoryDetail?.categoryName || "",
    },
  });

  const HandleAddCategory = (values) => {
    setLoader(true);
    const categoryName = values.categoryName;
    CategoryService.AddCategory(categoryName)
      .then((response) => {
        setLoader(false);
        if (response?.data?.statusCodeValue === 200) {
          setCategoriesData(response?.data?.payload?.reverse());
          reset();
          handleSuccess("Category added successfully");
        }
      })
      .catch((error) => {
        setLoader(false);
        reset();
        handleError(error);
      });
  };
  const updateCategory = (values) => {
    setLoader(true);

    const categoryName = values.categoryName;
    CategoryService.UpdateCategory(categoryDetail?.id, categoryName)
      .then((response) => {
        setLoader(false);
        if (response?.data?.statusCodeValue === 200) {
          setCategoriesData(response?.data?.payload?.reverse());
          handleSuccess("Category updated successfully");
        }
      })
      .catch((error) => {
        setLoader(false);
        handleError(error);
      });
  };

  const handleSuccess = (message) => {
    let interval;
    setAlertMessage({
      title: "Success",
      description: message,
      sublabel: "Auto closing in few seconds...",
    });
    interval = setTimeout(() => {
      resetState();
      clearTimeout(interval);
    }, 5000);
  };

  const handleError = (error) => {
    let interval;
    setAlertMessage({
      title: "Error",
      description: error?.response?.data?.message ?? "Something went wrong",
      sublabel: "Auto closing in few seconds...",
    });
    interval = setTimeout(() => {
      resetState();
      clearTimeout(interval);
    }, 5000);
  };

  const resetState = () => {
    setOpenAddCategoryModal(false);
    setAlertMessage({});
    setCategoryDetail({});
  };

  return (
    <>
      <div className="relative">
        <button
          onClick={() => {
            resetState();
          }}
          className="absolute -top-10 -right-10 text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          data-modal-toggle="authentication-modal"
        >
          <CloseIconView />
        </button>
      </div>
      {alertMessage?.title === "" ? (
        <>
          <div>
            <h4 className="mb-4 text-2xl font-bold text-white">
              {categoryDetail?.id ? "Edit" : "Add"} Category
            </h4>
            <form
              onSubmit={
                categoryDetail?.id
                  ? handleSubmit(updateCategory)
                  : handleSubmit(HandleAddCategory)
              }
            >
              <div className="relative z-10 mt-6 mb-8">
                <input
                  type="text"
                  className={` peer outline-none border-t-0 border-r-0 border-l-0 border-b-1 border-[#A4B1A7] rounded-none pl-0 pr-7 text-white text-md   focus:ring-0 focus:border-b-[#A4B1A7] block w-full p-2.5 bg-transparent ${
                    errors?.categoryName?.message && "focus:border-b-red-600"
                  } + " autofill:!bg-yellow-200"`}
                  placeholder=" "
                  {...register("categoryName")}
                />
                <label
                  className={`text-xs text-grey-100  z-[-1]  absolute  -top-4 peer-autofill:text-grey-100 peer-autofill:text-xs   transition-all    duration peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3  peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-focus:-top-4   peer-focus:text-grey-100 peer-focus:text-xs`}
                >
                  Category Name
                </label>
                <p className="mt-2 text-xs text-red-600">
                  {errors?.categoryName?.message}
                </p>
              </div>
              <button
                disabled={!isDirty || !isValid || loader}
                type="submit"
                className="btn-primary disabled:cursor-not-allowed"
              >
                {loader && <Spinner />}
                {categoryDetail?.id ? "Update" : "Submit"}
              </button>
            </form>
          </div>
        </>
      ) : (
        <>
          <div className="relative">
            <AlertModalContent message={alertMessage} />
            <button
              type="button"
              onClick={() => {
                setOpenAddCategoryModal(false);
              }}
              className="btn-secondary"
              data-modal-toggle="authentication-modal"
            >
              close
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default AddCategoryModal;
