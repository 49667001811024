import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import BasicTable from "../../../components/BasicTable";
import { COLUMNS } from "../../../components/ClientCategory/CategoryColumn";
import SearchFilter from "../../../components/SearchFilter";
import { images } from "../../../constants";
import { Menu, MenuHandler, MenuList } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import AddCategoryModal from "../../../components/Modals/AddCategoryModal";
import Modal from "../../../common/ModalLayout";
import DeleteCategoryModal from "../../../components/Modals/DeleteCategoryModal";

const CategoryListing = (props) => {
  const { categoriesData, setCategoriesData } = props;
  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);
  const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false);
  const [categoryDetail, setCategoryDetail] = useState({});
  // React table suggests to memoize the columns and data for avoiding the re creation of data on every render
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => categoriesData, [categoriesData]);

  const handleDeleteCategory = () => {
    setOpenDeleteCategoryModal(true);
  };
  const handleEditCategory = () => {
    setOpenAddCategoryModal(true);
  };

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
              flexGrow: 1,
            }}
          >
            {" "}
            Action
          </div>
        ),
        accessor: "Action",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="flex justify-center">
            <Menu placement="left-start" >
              <MenuHandler>
                <img
                  className="mr-1 text-center cursor-pointer hover:opacity-90 "
                  src={images.more_icon}
                  alt="icon"
                />
              </MenuHandler>
              <MenuList className="bg-[#060C07] flex flex-col min-w-[115px] py-4 px-0  z-20 border rounded-lg border-[#A4B1A7]/25">
                <Link
                  onClick={() => {
                    setCategoryDetail(row.values);
                    handleEditCategory();
                  }}
                  className="inline-block  font-normal py-1 px-4 mb-4 text-base text-white  focus-visible:bg-[#12221A]/80 font-manrope active:bg-[#12221A]/80 active:text-white hover:bg-[#12221A]/80 hover:text-[#FFFFFF] outline-0"
                >
                  Edit
                </Link>
                <Link
                  onClick={() => {
                    setCategoryDetail(row.values);
                    handleDeleteCategory();
                  }}
                  className="inline-block  font-normal py-1 px-4 mb-4 text-base text-white  focus-visible:bg-[#12221A]/80 font-manrope active:bg-[#12221A]/80 active:text-white hover:bg-[#12221A]/80 hover:text-[#FFFFFF] outline-0"
                >
                  Delete
                </Link>
             
              </MenuList>
            </Menu>
          </div>
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    gotoPage,
    pageCount,
    state, // For filtering
    setGlobalFilter, // For filtering
  } = useTable(
    {
      // ES6 shorthand notation having same key value name
      columns,
      data,
      initialState: {
        hiddenColumns: ["id"],
      },
    },
    tableHooks,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex } = state;

  const createAssetHandler = () => {
    setOpenAddCategoryModal(true);
  };
  return (
    <>
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-2xl font-bold text-white">Categories </h2>

        <button
          onClick={createAssetHandler}
          type="button"
          data-modal-toggle="Add_Modal"
          className="max-w-[202px] px-8 py-3 btn-primary rounded-full "
        >
          Add Category
        </button>
      </div>
      <div>
        <div className="w-full mb-8 ">
          <SearchFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
      </div>
      <div>
        <BasicTable
          columns={columns}
          data={data}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          page={page}
          nextPage={nextPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          prepareRow={prepareRow}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          pageCount={pageCount}
          rows={rows}
        />
      </div>
      {openAddCategoryModal && (
        <Modal
          modalVisible={openAddCategoryModal}
          closeModal={setOpenAddCategoryModal}
          children={
            <AddCategoryModal
              setCategoriesData={setCategoriesData}
              categoryDetail={categoryDetail}
              setCategoryDetail={setCategoryDetail}
              setOpenAddCategoryModal={setOpenAddCategoryModal}
            />
          }
          
        />
      )}
      {openDeleteCategoryModal && (
        <Modal
          modalVisible={openDeleteCategoryModal}
          closeModal={setOpenDeleteCategoryModal}
          children={
            <DeleteCategoryModal
              setCategoryDetail={setCategoryDetail}
              setOpenDeleteCategoryModal={setOpenDeleteCategoryModal}
              categoryDetail={categoryDetail}
              setCategoriesData={setCategoriesData}
            />
          }
         
        />
      )}
    </>
  );
};

export default CategoryListing;
