import React from "react";
import BrandPreviewHome from "../components/BrandPreview/BrandPreviewHome/BrandPreviewHome";

const StorefrontDemo1 = () => {
  return (
    <div className="w-full">
      <BrandPreviewHome />
    </div>
  );
};

export default StorefrontDemo1;
